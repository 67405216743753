import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { WineStockDatatableModule } from "./wine-stock-datatable/wine-stock-datatable.module";
import { RouterModule } from "@angular/router";
import { WineStockCounterModule } from "./wine-stock-counter/wine-stock-counter.module";
import { WineStockComponent } from "./wine-stock.component";

@NgModule({
  imports: [
    CommonModule,
    WineStockDatatableModule,
    WineStockCounterModule,
    RouterModule.forChild([
       {
         path: "",
         component: WineStockComponent,
         data: {
           title: "CT_MENU.pendingShipmentsWines",
           urls: []
         }
       }
    ])
  ],
  declarations: [
    WineStockComponent
  ]
})
export class WineStockModule {
}
