<div class="profile-form-cntr">
  <h2>Completa profilo</h2>
  <mat-radio-group *ngIf="stateCreate" aria-label="Select an option" (change)="onChange($event)" [value]="1">
    <mat-radio-button [value]="1" >Business</mat-radio-button>
    <mat-radio-button [value]="2">Private</mat-radio-button>
  </mat-radio-group>
  <ct-form [configuration]="formConfiguration"></ct-form>
  <h2>Completa indirizzo</h2>
  <app-address-data-form></app-address-data-form>

  <div mat-dialog-actions fxLayout="row" fxLayoutAlign="space-between center">

    <ct-button [configuration]="cancelButton"></ct-button>
  
    <ct-button [configuration]="submitButton"></ct-button>
  
  </div>

</div>