import {
  CtControlConfiguration,
  CtControlTypes,
  CtFormConfiguration,
  Dictionary
} from "@ctsolution/ct-framework";

const ShipmentAddressContactData: CtControlConfiguration[] = [
  <CtControlConfiguration>{
    name: "nome",
    label: "CT_DATATABLE.CLIENTRECORDS.FullName",
    type: CtControlTypes.TEXT,
    flexSpacePercentage: 100,
    flexSpaceBreakpoints: <Dictionary<number>>{
      xs: 100,
      sm: 100
    },
    validators: [
      { name: "required" }
    ]
  },
  <CtControlConfiguration>{
    name: "telefono",
    label: "CT_GENERAL.phone_number",
    type: CtControlTypes.TEXT,
    flexSpacePercentage: 50,
    flexSpaceBreakpoints: <Dictionary<number>>{
      xs: 100,
      sm: 100
    },
    validators: [
      { name: "required" },
      { name: "min", value: 0 },
      { name: "maxlength", value: 40 }

    ]
  },
  <CtControlConfiguration>{
    name: "email",
    label: "Email",
    type: CtControlTypes.TEXT,
    flexSpacePercentage: 50,
    flexSpaceBreakpoints: <Dictionary<number>>{
      xs: 100,
      sm: 100
    },
    validators: [
      { name: "required" },
      { name: "email" },
      { name: "min", value: 0 },
      { name: "maxlength", value: 128 }
    ]
  }
];
export const RecipientContactDataFormConfiguration: CtFormConfiguration = <CtFormConfiguration>{
  controls: ShipmentAddressContactData
};

