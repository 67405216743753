import { ChangeDetectorRef, Component } from "@angular/core";
import { CtControlConfiguration, CtRangeControlOptions } from "@ctsolution/ct-framework";
import { GRADAZIONE_ALCOLICA } from "./gradazione-alcolica.control";

@Component({
  selector: "mw-gradazione-alcolica-control",
  template: `
    <ct-control *ngIf="control" [configuration]="control"></ct-control>`
})
export class GradazioneAlcolicaControlComponent {

  control: CtControlConfiguration = CtControlConfiguration.create(GRADAZIONE_ALCOLICA);

  constructor(private cdr: ChangeDetectorRef) {
  }

  ngAfterViewInit() {

    this.cdr.detectChanges();

    (<CtRangeControlOptions>this.control
      .options)
      .setStep(0.1)
      .setMin(5)
      .setMax(18)

  }

}
