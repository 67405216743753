<div class="deposit-create-step4-container">

  <form [formGroup]="form">

    <app-deposit-collection-packaging
      #depositCollectionPackagingComponent></app-deposit-collection-packaging>

    <app-deposit-collection-form
      #depositCollectionForm
      [configuration]="depositCollectionFormComponentConfiguration"></app-deposit-collection-form>

  </form>

  <hr>

  <div class="buttons-stepper m-t-40">

    <button class="general-button" mat-button matStepperPrevious>{{ 'CT_GENERAL.BACK' | translate }}</button>

    <button
      mat-button
      type="button"
      class="general-button"
      (click)="onSubmit.emit(null)">{{ closingButtonLabel | translate }}
    </button>

  </div>

</div>
