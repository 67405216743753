import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  Output,
  EventEmitter,
  Input, AfterViewInit
} from "@angular/core";
import { MediaMatcher } from "@angular/cdk/layout";
import { MenuItems } from "../menu/menu-items";
import { Menu } from "../menu/_classes/menu-item.interface";
import { BehaviorSubject, Observable } from "rxjs";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { MostWineContactsComponent } from "./most-wine-contacts/most-wine-contacts.component";
import { Router } from "@angular/router";

@Component({
  selector: "app-vertical-sidebar",
  templateUrl: "./vertical-sidebar.component.html",
  styleUrls: []
})
export class VerticalAppSidebarComponent implements OnDestroy, AfterViewInit {

  mobileQuery: MediaQueryList;

  @Input() showClass: boolean = false;
  @Output() notify: EventEmitter<boolean> = new EventEmitter<boolean>();

  private _mobileQueryListener: () => void;
  status = true;
  showMenu = "";
  itemSelect: number[] = [];
  parentIndex = 0;
  childIndex = 0;

  // @ts-ignore
  menuItems$: BehaviorSubject<Menu[]> = new BehaviorSubject([]);

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private _dialog: MatDialog,
    private menuItems: MenuItems,
    private router: Router
  ) {

    this.mobileQuery = media.matchMedia("(min-width: 768px)");
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    // tslint:disable-next-line: deprecation
    this.mobileQuery.addListener(this._mobileQueryListener);

  }

  ngAfterViewInit() {

    this.menuItems
      .getMenuitem()
      .then((result: Menu[]) => {

        this.menuItems$
          .next(result);

      });

  }

  addExpandClass(element: any) {

    if (element === this.showMenu) {

      this.showMenu = "0";

    } else {

      this.showMenu = element;

    }

  }

  subclickEvent(): void {

    this.status = true;

  }

  getUrlFromMenu(menuitem?: Menu): string {

    if (!menuitem) return "/";

    let states: string[] = [""];

    if (menuitem.separator?.length) {

      states = states.concat(menuitem.separator.map(elm => elm.name));

    }

    states.push(menuitem.state);

    return states.join("/");

  }

  isCurrentUrlEqualToStates(menuitem?: Menu): boolean {

    const targetUrl = this.getUrlFromMenu(menuitem);
    return this.router.url === targetUrl;

  }

  handleNotify(menuitem?: Menu) {

    if (menuitem?.state.toLowerCase().includes("contatti")) {

      this.openContact();
      return;

    }

    const targetUrl = this.getUrlFromMenu(menuitem);

    if (menuitem) {

      this.router.navigateByUrl(targetUrl);

    }

    if (window.innerWidth < 1024) {

      this.notify.emit(!this.showClass);

    }

  }

  openContact() {

    this._dialog.open(MostWineContactsComponent, { panelClass: "dialog-contact-container" });

  }

  ngOnDestroy(): void {

    // tslint:disable-next-line: deprecation
    this.mobileQuery.removeListener(this._mobileQueryListener);

  }

}
