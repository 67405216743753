import {Injectable} from '@angular/core';
import {NavigationExtras, Router} from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class GeneralService {

  constructor(
    private router: Router,
  ) {
  }

  navigateTo = (url: string, extras?: NavigationExtras) => this.router.navigate([url], extras);

}