import { Component, ElementRef, Input, QueryList, ViewChildren } from "@angular/core";
import { DepositoModel } from "../../../../core/classes/deposito/deposito";
import { HtmlService } from "../../../../core/lib/html.service";
import { StateService } from "../state.service";

@Component({
  selector: "app-deposit-state-change-note-history",
  templateUrl: "./deposit-state-change-note-history.component.html",
  styleUrls: ["./deposit-state-change-note-history.component.scss"]
})
export class DepositStateChangeNoteHistoryComponent {

  @Input() model: DepositoModel | null = null;
  @ViewChildren("timelinePanel") scrollReferenceElements: QueryList<ElementRef> | null = null;

  constructor(private htmlService: HtmlService, public stateService: StateService) {
  }

  ngAfterViewInit() {

    setTimeout(() => {

      let lastElement = this.scrollReferenceElements?.last;
      this.htmlService.scrollToElement(lastElement);

    }, 0);

  }

}
