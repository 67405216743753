import { CtControlConfiguration, CtControlTypes, CtThemeTypes } from "@ctsolution/ct-framework";

export const COMUNE_CONTROL: CtControlConfiguration = <CtControlConfiguration>{
  name: "comune",
  label: "CT_DATATABLE.CLIENTRECORDS.CreationMunicipality",
  type: CtControlTypes.TEXT,
  validators: [
    { name: "required" },
    { name: "minlength", value: 1 }
  ],
  theme: CtThemeTypes.MATERIAL
};
