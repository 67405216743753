import { Routes } from "@angular/router";

import { FullComponent } from "./layouts/full/full.component";
import { PagesModule } from "./pages/pages.module";
import { AuthenticationGuard } from "./core/lib/authentication.guard";

export const AppRoutes: Routes = [
  {
    path: "",
    component: FullComponent,
    canActivate: [AuthenticationGuard],
    children: [
      {
        path: "",
        loadChildren: () => PagesModule
      }
    ]
  },
  {
    path: '**',
    redirectTo: '/dashboard',
  },
];
