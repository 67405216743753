import { ChangeDetectorRef, Component, Optional, TemplateRef, ViewChild } from "@angular/core";
import { MwDatatableConfiguration } from "../../../../components/mw-datatable/mw-datatable.configuration";
import { BaseController } from "../../../../core/controllers/base.controller";
import { GeneralService } from "../../../../core/lib/general.service";
import {
  destinazioneHeader, contenutoHeader,
  depositoCodiceHeader, quantitaHeader, quantitaInLavorazioneHeader
} from "../../deposit-stock-datatable.headers";
import {
  CTDatatableConfiguration
} from "@ctsolution/ct-framework";
import { QueriesService } from "../../../../core/lib/queries.service";
import { RoleService } from "../../../../core/lib/role.service";
import { ApolloVariableClass } from "../../../../core/classes/apollo/apollo.variable";
import { GiacenzaPalletModel } from "../../../../core/classes/deposito/giacenza";
import { BaseApolloQueryClass } from "../../../../core/classes/apollo/apollo.base.query";
import {
  MatLegacyDialogRef as MatDialogRef
} from "@angular/material/legacy-dialog";
import { GiacenzaPalletsApolloResult } from "src/app/core/interfaces/apollo/giacenza-pallets.apollo.result";
import {
  SectionDetailDataConfiguration
} from "../../../../components/model-detail/section-detail-data/section-detail-data.configuration";
import { ModelDetailService } from "../../../../components/model-detail/model-detail.service";
import { UnpackPalletDialogService } from "../unpack-pallet-dialog/unpack-pallet-dialog.service";
import { MwDatatableComponent } from "../../../../components/mw-datatable/mw-datatable.component";
import { TranslateService } from "@ngx-translate/core";
import { Title } from "@angular/platform-browser";

@Component({
  selector: "pallet-stock-datatable",
  templateUrl: "./pallet-stock-datatable.component.html",
  styles: [`
    ::ng-deep {
      .pallet-datatable-container {
        .p-frozen-column {
          text-align: right !important;
        }
      }

      .pallet-stock-actions {
        button {
          width: 140px !important;
          margin-bottom: 5px;
          padding: 5px 10px;
          height: 35px !important;
          display: block;
        }
      }
    }
  `]
})
export class PalletStockDatatableComponent {

  @ViewChild("tracking") trackingButtonTemplate: TemplateRef<any> | null = null;
  @ViewChild("table") table: MwDatatableComponent<GiacenzaPalletsApolloResult | null> | null = null;

  listConfiguration: MwDatatableConfiguration<GiacenzaPalletsApolloResult> | null = MwDatatableConfiguration
    .create<GiacenzaPalletsApolloResult>()
    .setFetchDataCaller(async (variables: ApolloVariableClass) => {

      let query = await this._queries.entities.giacenzaPallets();

      if (!query) return null;

      const parameter = BaseApolloQueryClass
        .create()
        .setVariables(variables)
        .setQuery(query);

      return this.baseController.list<GiacenzaPalletsApolloResult>(parameter);

    })
    .setFetchResponseDataMapper((value: GiacenzaPalletsApolloResult) => value.giacenzaPallets)
    .setCTDatatableConfiguration(
      CTDatatableConfiguration
        .create<GiacenzaPalletsApolloResult>()
        .setEmptyMessage(this.translateService.instant("CT_DATATABLE.EmptyShipment"))
        .setLoadingBody(this.translateService.instant("CT_DATATABLE.LoadingShipment"))
        .setSearchInputPlaceholder(this.translateService.instant("CT_GENERAL.Search"))
        .enableRowExpansion(true)
        .setDataKey("id")
    );

  constructor(
    private baseController: BaseController,
    private generalService: GeneralService,
    private cdr: ChangeDetectorRef,
    private _queries: QueriesService,
    public _role: RoleService,
    public modelDetailService: ModelDetailService,
    @Optional() public dialogRef: MatDialogRef<any>,
    private unpackPalletDialogService: UnpackPalletDialogService,
    private translateService: TranslateService,
    private titleService: Title
  ) {
  }

  async ngOnInit(){
    this.translateService.get('CT_MENU.pendingShipmentsPallet').subscribe((translation: string) => {
      this.titleService.setTitle(translation);
    });
  }

  ngAfterViewInit() {

    this.listConfiguration
      ?.CTDatatableConfiguration
      ?.setCustomActionTemplate(this.trackingButtonTemplate);

    const headers = [
      depositoCodiceHeader(this.translateService),
      contenutoHeader(this.translateService),
      quantitaHeader(this.translateService),
      quantitaInLavorazioneHeader(this.translateService)
    ];

    if (this._role.isAdmin()) {

      headers
        .unshift(destinazioneHeader(this.translateService));

    }

    this.listConfiguration
      ?.CTDatatableConfiguration
      ?.setHeaders(headers);

    this.cdr.detectChanges();

  }

  onTracking(event: GiacenzaPalletModel) {

    if (this.dialogRef) {

      const sections: SectionDetailDataConfiguration[] = this.modelDetailService.getGiacenzaPalletSectionsFromGiacenzaPalletModel(event);

      this.dialogRef
        .close(sections);

    } else {

      this.generalService
        .navigateTo("/spedizioni/creazione", { queryParams: { giacenzaPalletId: event?.id } });

    }

  }

  unpack(event: GiacenzaPalletModel) {

    this.unpackPalletDialogService
      .open(event)
      .afterClosed()
      .subscribe((updated?: boolean) => {

        if (updated) {

          this.table
            ?.reinit();

        }

      });

  }

}
