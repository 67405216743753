import { Component, Inject, Input, Optional } from "@angular/core";
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from "@angular/material/legacy-dialog";
import { ModelDetailConfiguration } from "./model-detail.configuration";

@Component({
  selector: "app-model-detail",
  template:
    `
      <div class="detail-data">

        <div
          mat-dialog-title
          *ngIf="configuration?.title"
          fxLayout="row" fxLayoutAlign="space-between center">

          {{ configuration.title | translate }}

          <button
            *ngIf="dialogRef"
            mat-icon-button color="primary" (click)="closeDialog()">

            <mat-icon>close</mat-icon>

          </button>

        </div>

        <div mat-dialog-content>

          <app-section-detail-data
            *ngFor="let section of configuration?.sections ?? []"
            [configuration]="section"></app-section-detail-data>

        </div>

      </div>
    `
})
export class ModelDetailComponent {

  @Input() configuration: ModelDetailConfiguration | null = null;

  constructor(
    @Optional() public dialogRef: MatLegacyDialogRef<ModelDetailComponent>,
    @Optional() @Inject(MAT_LEGACY_DIALOG_DATA) public dialogConfiguration: ModelDetailConfiguration) {

    if (dialogConfiguration) {

      this.configuration = dialogConfiguration;

    }

  }

  closeDialog() {

    this.dialogRef.close();

  }

}
