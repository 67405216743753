import { DepositFormDTO } from "../../deposit-create-wizard.interfaces";
import { DepositType } from "../../../../../core/enum/deposit-type";
import { ModalitaStoccaggio } from "../../../../../core/enum/modalita-stoccaggio.enum";

export class DepositCollectionPackagingConfiguration {

  depositFormValue: DepositFormDTO | null = null;
  needsImballoRitiro: boolean = false;
  packageTypeIcon: string | null = null;
  formDescription: string | null = null;
  needsTipologiaImballo: boolean = false;

  private constructor() {
  }

  public static create = (): DepositCollectionPackagingConfiguration => new DepositCollectionPackagingConfiguration();

  setDepositFormValue(value: DepositFormDTO | null) {

    this.depositFormValue = value;

    if (this.depositFormValue) {

      this
        .setNeedsImballoRitiro(value?.step3?.depositType === DepositType.most);

      if (value?.step1.depositPackagingType === ModalitaStoccaggio.Pallet) {

        this.setFormDescription("Completa i dati relativi al pallet:");

      } else {

        this
          .setFormDescription(null)
          .setNeedsTipologiaImballo(true);

      }

    }

    return this;

  }

  setNeedsImballoRitiro(value: boolean) {

    this.needsImballoRitiro = value;
    return this;

  }

  setNeedsTipologiaImballo(value: boolean) {

    this.needsTipologiaImballo = value;
    return this;

  }

  setPackageTypeIcon(value: string | null) {

    this.packageTypeIcon = value;
    return this;

  }

  setFormDescription(value: string | null) {

    this.formDescription = value;
    return this;

  }

}
