import { Component, ViewChild } from "@angular/core";
import {
  MwLookupControlConfiguration
} from "../mw-lookup-control/mw-lookup-control.configuration";
import { QueriesService } from "../../core/lib/queries.service";
import { GiacenzaViniApolloResult } from "../../core/interfaces/apollo/giacenza-vini.apollo-result";
import { GiacenzaVinoModel } from "../../core/interfaces/giacenza-vino.model";
import { CtControlTypes } from "@ctsolution/ct-framework";
import { MwLookupControlComponent } from "../mw-lookup-control/mw-lookup-control.component";
import { ProductStockLookupControlService } from "./product-stock-lookup-control.service";

@Component({
  selector: "app-product-stock-lookup-control",
  template: `
    <mw-lookup-control #lookupControl [configuration]="configuration"></mw-lookup-control>`
})
export class ProductStockLookupControlComponent {

  @ViewChild("lookupControl") lookupControl: MwLookupControlComponent<GiacenzaVinoModel, GiacenzaViniApolloResult> | null = null;

  configuration: MwLookupControlConfiguration<GiacenzaVinoModel, GiacenzaViniApolloResult> = MwLookupControlConfiguration
    .create<GiacenzaVinoModel, GiacenzaViniApolloResult>()
    .setResponseMapper(value => value.giacenzaVini.nodes)
    .setQuery(this._queries.entities.giacenzaVini)
    .setOptionDescriptionFn(value => `${value?.vino?.descrizione ?? ""}`)
    .setOrder([{ vino: { descrizione: "ASC" } }])
    .configureControl(control => {

      control
        ?.setLabel("CT_GENERAL.item-code")
        ?.setType(CtControlTypes.LOOKUP);

    });

  constructor(private _queries: QueriesService, private productStockLookupControlService: ProductStockLookupControlService) {
  }

  ngAfterViewInit() {

    const control = this.configuration?.CTControl.control;

    if (!control) return;

    control
      ?.valueChanges
      .subscribe(value => {

        if (value) {

          this.productStockLookupControlService.addValue(value.value);

        }

      });

  }

}
