<div mat-dialog-title fxLayout="row" fxLayoutAlign="space-between center">

  {{ 'CT_SHIPMENT_DEPOSITS.addBoxToShipment' | translate }}

  <button mat-icon-button color="primary" (click)="close()">

    <mat-icon>close</mat-icon>

  </button>

</div>

<div mat-dialog-content>

  <app-wine-stock-datatable [configuration]="datatableConfiguration"></app-wine-stock-datatable>

</div>
