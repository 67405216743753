import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BoxComposerComponent } from "./box-composer.component";
import { TranslateModule } from "@ngx-translate/core";
import { MatLegacyButtonModule } from "@angular/material/legacy-button";
import {
  ProductLookupControlModule
} from "../../../_components/product-lookup-control/product-lookup-control.module";
import {
  QtaControlModule
} from "../../../../../components/qta-control/qta-control.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FlexLayoutModule, FlexModule } from "@angular/flex-layout";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatTooltipModule } from "@angular/material/tooltip";

@NgModule({
  declarations: [
    BoxComposerComponent
  ],
  exports: [
    BoxComposerComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    FlexLayoutModule,
    MatLegacyButtonModule,
    ProductLookupControlModule,
    MatFormFieldModule,
    QtaControlModule,
    FormsModule,
    ReactiveFormsModule,
    FlexModule,
    MatIconModule,
    MatTooltipModule
  ]
})
export class BoxComposerModule {
}
