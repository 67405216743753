import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { WineCreationFormComponent } from "./wine-creation-form.component";
import { FlexModule } from "@angular/flex-layout";
import { MatIconModule } from "@angular/material/icon";
import { MatLegacyButtonModule } from "@angular/material/legacy-button";
import { MatLegacyDialogModule } from "@angular/material/legacy-dialog";
import { ReactiveFormsModule } from "@angular/forms";
import { CtButtonModule, CtControlModule } from "@ctsolution/ct-framework";
import { RegioneControlComponent } from "./_controls/regione-control/regione-control.component";
import {
  NomeCommercialeControlComponent
} from "./_controls/nome-commerciale-control/nome-commerciale-control.component";
import { DenominazioneControlComponent } from "./_controls/denominazione-control/denominazione-control.component";
import { TipoVinoControlComponent } from "./_controls/tipo-vino-control/tipo-vino-control.component";
import { AnnoControlComponent } from "./_controls/anno-control/anno-control.component";
import { MenzioneControlComponent } from "./_controls/menzione-control/menzione-control.component";
import {
  FormatoBottigliaControlComponent
} from "./_controls/formato-bottiglia-control/formato-bottiglia-control.component";
import { ProduttoreControlComponent } from "./_controls/produttore-control/produttore-control.component";
import {
  GradazioneAlcolicaControlComponent
} from "./_controls/gradazione-alcolica-control/gradazione-alcolica-control.component";
import { MetodologiaControlComponent } from "./_controls/metodologia-control/metodologia-control.component";
import { TipologiaControlComponent } from "./_controls/tipologia-control/tipologia-control.component";
import { PaeseControlModule } from "../../../components/paese-control/paese-control.module";
import { SpumanteMetodoComponent } from "./_controls/spumante-metodo/spumante-metodo.component";
import { SpumanteDosaggioComponent } from "./_controls/spumante-dosaggio/spumante-dosaggio.component";
import { SpumanteAnnataComponent } from "./_controls/spumante-annata/spumante-annata.component";
import { MwLookupControlModule } from "../../../components/mw-lookup-control/mw-lookup-control.module";
import { MenzioneEsteroControlComponent } from "./_controls/menzione-estero-control/menzione-estero-control.component";
import { TranslateModule } from "@ngx-translate/core";

@NgModule({
  declarations: [
    WineCreationFormComponent,
    RegioneControlComponent,
    NomeCommercialeControlComponent,
    DenominazioneControlComponent,
    TipoVinoControlComponent,
    AnnoControlComponent,
    MenzioneControlComponent,
    MenzioneEsteroControlComponent,
    FormatoBottigliaControlComponent,
    ProduttoreControlComponent,
    GradazioneAlcolicaControlComponent,
    MetodologiaControlComponent,
    TipologiaControlComponent,
    SpumanteMetodoComponent,
    SpumanteDosaggioComponent,
    SpumanteAnnataComponent
  ],
  imports: [
    CommonModule,
    FlexModule,
    MatIconModule,
    MatLegacyButtonModule,
    MatLegacyDialogModule,
    ReactiveFormsModule,
    CtButtonModule,
    CtControlModule,
    PaeseControlModule,
    MwLookupControlModule,
    TranslateModule.forChild()
  ]
})
export class WineCreationFormModule {
}
