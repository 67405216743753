<mw-datatable
  *ngIf="listConfiguration"
  class="deposit-datatable-container"
  #table
  [configuration]="listConfiguration"
  (onSave)="addDeposit()"
  (onDetail)="detail($event)">

  <ng-template #legendTemplate>

    <div class="legend-container">

      <app-legend [stati]="depositService.statiDeposito"></app-legend>

    </div>

  </ng-template>

  <ng-template #body let-value="value">

    <td>

      <app-bullet-state
        *ngIf="value?.stato"
        [class.admin]="roleService.isAdmin()"
        [state]="value?.stato"
        (onClick)="manageState(value)"></app-bullet-state>

    </td>

    <td>
      {{ value?.codice ?? '' }}
    </td>

    <td>

      <div style="display: flex">

        <img lazy
             height="30"
             style="margin-top: auto; margin-bottom: auto; margin-right: 5px;"
             [src]="getDepositIcon(value?.colliStoccaggio?.[0]?.modalitaStoccaggio ?? '')"> <span
        style="margin-top: auto; margin-bottom: auto"> {{ value?.descrizione ?? '' }} </span>

      </div>

    </td>

    <td>

      <!-- Il campo "Ritiro" viene valorizzato se e solo se la tipologia è Ritiro a cura di most. In caso contrario si tratta di Spedizione autonoma-->
      <div style="display: flex">

        <img *ngIf="value"
             lazy
             height="30"
             style="margin-top: auto; margin-bottom: auto; margin-right: 5px;"
             [src]="iconsBasePath + ( value?.ritiro?.dataPickup ? 'shipping-most.svg' : 'personal-shipping.svg')">

        <span style="margin-top: auto; margin-bottom: auto">
                  {{ (value?.ritiro?.dataPickup ? value?.ritiro?.dataPickup : value?.dataPresuntaRicevimentoMerce) ?? '' }}
        </span>

      </div>

    </td>

  </ng-template>

</mw-datatable>

<ng-template #stateCustomFilter let-value="value">

  <app-deposit-state-select-filter
    [value]="value"
    (onChange)="stateFilter($event)"
  ></app-deposit-state-select-filter>

</ng-template>

<ng-template #packagingCollectionTemplate let-value="value">

  <button
    *ngIf="value?.ritiro?.dataPickup"
    pButton
    pRipple
    icon="pi pi-book"
    class="p-button-rounded"
    (click)="showPackagingCollection(value)">
  </button>

</ng-template>
