import { Component, TemplateRef, ViewChild } from '@angular/core';
import { BaseApolloQueryClass } from "../../../../core/classes/apollo/apollo.base.query";
import { BaseController } from 'src/app/core/controllers/base.controller';
import { QueriesService } from 'src/app/core/lib/queries.service';
import { TotaliGiacenzaViniApolloResult } from "../../../../core/interfaces/apollo/totali-giacenza-vini.apollo-result";

@Component({
  selector: 'app-wine-stock-counter',
  templateUrl: './wine-stock-counter.component.html',
  styleUrls: ['./wine-stock-counter.component.scss']
})

export class WineStockCounterComponent {
  @ViewChild('totaliTemplate') totaliTemplate: TemplateRef<any> | null = null;

  totaliGiacenza: TotaliGiacenzaViniApolloResult | null = null;

  constructor(
    private baseController: BaseController,
    private _queries: QueriesService
  ) {}

  ngOnInit() {
    this.getTotaliBottiglie();
  }

  async getTotaliBottiglie() {
    try {

      const totaliBottiglie_query = await this._queries.entities.totaliGiacenzaVini();
      
      if (!totaliBottiglie_query) return;

      const parameter = BaseApolloQueryClass.create().setQuery(totaliBottiglie_query);
      const caller = await this.baseController.list<TotaliGiacenzaViniApolloResult | null>(parameter);

      const result = await new Promise<TotaliGiacenzaViniApolloResult | null>((resolve, reject) => {
        caller?.subscribe({ next: resolve, error: reject });
      });
      if (result?.totaliGiacenzaVini?.length) {
        this.totaliGiacenza = result;
      } else {
        this.totaliGiacenza = null;
      }

    } catch (error) {
      console.error("Errore durante la richiesta dei dati:", error);
    }
  }
}