import { ColloStoccaggio } from "./collo-stoccaggio";
import { ImballoRitiro } from "../../interfaces/imballo-ritiro";


export class ColloRitiro {

  id: string | null = null;
  tipo: string | null = null;
  quantita: number | null = null;
  pesoKg: number | null = null;
  lunghezza: number | null = null;
  larghezza: number | null = null;
  altezza: number | null = null;

  constructor(ritiro: ImballoRitiro, collo: ColloStoccaggio) {

    this.pesoKg = ritiro.pesoKg;
    this.lunghezza = ritiro.lunghezza;
    this.larghezza = ritiro.larghezza;
    this.altezza = ritiro.altezza;

    if (collo.modalitaStoccaggio) {

      this.tipo = collo.modalitaStoccaggio ?? null;

    }

    //@dam avevo messo questa regola perchè la creazione deposito vuole il tipo in stringa che corrisponde alla modalitaStoccaggio,
    // Con i vecchi enum dovevo essere certa che venisse convertito in Pallet o Scatola e che non fosse un number.
    // Inoltre ColloRitiro non poteva avere Bottiglia ma solo Scatola o Pallet, come concordato inizialmente, perchè avevamo detto che il Corriere si
    // aspetterà sempre come minimo delle Scatole.
    // Ora dovrebbe essere corretto, ho provato a creare diversi depositi e se inserisco le Bottiglie, ColloRitiro è un Array vuoto, altrimenti il tipo è lo stesso di ModalitaStoccaggio.


    this.quantita = ritiro.quantita ?? collo.quantita ?? 1;

  }

  setId(value: string | null) {

    this.id = value;
    return this;

  }

  setQuantita(value: number | null) {

    this.quantita = value;
    return this;

  }

}
