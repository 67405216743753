<div class="dashboard-cntr">

  <div fxLayout="row wrap">

    <div fxFlex.gt-md="30" fxFlex="100">

      <div>

        <ct-card [configuration]="DEPOSIT_CREATE"></ct-card>

      </div>

      <div>

        <ct-card [configuration]="SHIPMENT_CREATE"></ct-card>

      </div>

      <!--      <mat-divider></mat-divider>-->

      <app-notifications-widget></app-notifications-widget>

    </div>

    <div fxFlex.gt-md="45" fxFlex="100">

      <app-meteo-widget></app-meteo-widget>

      <div>

        <app-wine-types-widget></app-wine-types-widget>

      </div>

      <div fxLayout="row wrap" class="counters">

        <div fxFlex.gt-sm="33.33" fxFlex.gt-xs="100" fxFlex="100" class="dashboard-card">

          <ct-card [configuration]="DEPOSIT_COUNTER"></ct-card>

        </div>

        <div fxFlex.gt-sm="33.33" fxFlex.gt-xs="100" fxFlex="100" class="dashboard-card">

          <ct-card [configuration]="SHIPMENT_COUNTER"></ct-card>

        </div>

        <div fxFlex.gt-sm="33.33" fxFlex.gt-xs="100" fxFlex="100" class="dashboard-card">

          <ct-card [configuration]="PRODUCT_COUNTER"></ct-card>

        </div>

      </div>

    </div>

    <div fxFlex.gt-md="25" fxFlex="100">

      <app-stocks-counter-widget></app-stocks-counter-widget>

      <app-customers-counter-widget></app-customers-counter-widget>

    </div>

  </div>

</div>
