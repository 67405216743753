import { StateLegendComponent } from "./state-legend.component";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { BulletStateModule } from "../bullet-state/bullet-state.module";
import { FlexModule } from "@angular/flex-layout";

@NgModule({
  declarations: [StateLegendComponent],
  imports: [CommonModule, BulletStateModule, FlexModule],
  exports: [StateLegendComponent]
})
export class StateLegendModule {
}
