import { CtControlConfiguration, CtControlTypes, CtThemeTypes } from "@ctsolution/ct-framework";

export const CAP_CONTROL: CtControlConfiguration = <CtControlConfiguration>{
  name: "cap",
  label: "CT_GENERAL.cap",
  type: CtControlTypes.TEXT,
  validators: [
    { name: "required" },
    { name: "minlength", value: 5 },
    { name: "maxlength", value: 5 }
  ],
  theme: CtThemeTypes.MATERIAL
};
