import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ModelDetailComponent } from "./model-detail.component";
import { FlexLayoutModule, FlexModule } from "@angular/flex-layout";
import { MatIconModule } from "@angular/material/icon";
import { SectionDetailDataModule } from "./section-detail-data/section-detail-data.module";
import { MatLegacyDialogModule } from "@angular/material/legacy-dialog";
import { ModelDetailService } from "./model-detail.service";
import { MatLegacyButtonModule } from "@angular/material/legacy-button";
import { TranslateModule } from "@ngx-translate/core";


@NgModule({
  declarations: [
    ModelDetailComponent
  ],
  imports: [
    CommonModule,
    FlexModule,
    MatIconModule,
    SectionDetailDataModule,
    FlexLayoutModule,
    MatLegacyDialogModule,
    MatLegacyButtonModule,
    TranslateModule
  ],
  exports: [
    ModelDetailComponent
  ],
  providers: [ModelDetailService]
})
export class ModelDetailModule {
}
