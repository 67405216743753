<mw-datatable
  class="wine-stock-datatable"
  *ngIf="listConfiguration"
  [configuration]="listConfiguration">

  <ng-template #body let-value="value">

    <td>
      {{ value?.vino?.descrizione ?? ''}}
    </td>
    <td>
      {{ value?.quantita ?? 0}}
    </td>
    <td>
      {{ value?.quantitaScatole ?? 0}}
    </td>
    <td>
      {{ value?.quantitaBottiglieSfuse ?? 0}}
    </td>
    <td>
      {{ value?.vino?.produttore ?? ''}}
    </td>
    <td *ngIf="_role.isAdmin()">
      {{ value?.depositante?.nomeRiferimento ?? ''}}
    </td>

  </ng-template>

</mw-datatable>

<div>
  
</div>

<ng-template #tracking let-value="value">

  <button
    pButton
    pRipple
    style="width: auto;"
    class="p-button-rounded"
    (click)="onTracking(value)">{{"CT_DATATABLE.WINERECORDS.ShipButton" | translate}}
  </button>

</ng-template>
