import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CustomerAddressSelectControlComponent } from "./customer-address-select-control.component";
import { MwLookupControlModule } from "../../mw-lookup-control/mw-lookup-control.module";

@NgModule({
  declarations: [
    CustomerAddressSelectControlComponent
  ],
  exports: [
    CustomerAddressSelectControlComponent
  ],
  imports: [
    CommonModule,
    MwLookupControlModule
  ]
})
export class CustomerAddressSelectControlModule {
}
