import { Component, ViewChild } from "@angular/core";
import { ShipmentTypeSelectionComponent } from "./shipment-type-selection/shipment-type-selection.component";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { TipoSpedizioneEnum } from "../../../../core/enum/tipo-spedizione.enum";
import {
  ShippingPackageCreationComponent
} from "../../_components/shipping-package-creation/shipping-package-creation.component";
import {
  ShippingPalletSelectionComponent
} from "../../_components/shipping-pallet-selection/shipping-pallet-selection.component";
import {
  SectionDetailDataConfiguration
} from "../../../../components/model-detail/section-detail-data/section-detail-data.configuration";
import { ShipmentCollectionComponent } from "./shipment-collection/shipment-collection.component";
import { ShipmentCollectionConfiguration } from "./shipment-collection/shipment-collection.configuration";
import { FormBuilder, FormGroup } from "@angular/forms";
import {
  ShippingBoxSelectionComponent
} from "../../_components/shipping-box-selection/shipping-box-selection.component";
import { ShipmentCreateWizardService } from "../shipment-create-wizard.service";
import { GiacenzaVinoModel } from "../../../../core/interfaces/giacenza-vino.model";
import {
  defaultBoxBottleCount
} from "../../../deposit/deposit-create-wizard/deposit-create-step2/box-composer/box-composer.component";
import { SnackbarService } from "@ctsolution/ct-framework";

@Component({
  selector: "app-shipment-create-step1",
  templateUrl: "./shipment-create-step1.component.html",
  styleUrls: ["./shipment-create-step1.component.scss"]
})
export class ShipmentCreateStep1Component {

  @ViewChild("shipmentTypeSelectionComponent") shipmentTypeSelectionComponent: ShipmentTypeSelectionComponent | null = null;
  @ViewChild("shipmentCollection") shipmentCollectionComponent: ShipmentCollectionComponent | null = null;

  form: FormGroup;

  constructor(
    private dialog: MatDialog,
    private snackbar: SnackbarService,
    private formBuilder: FormBuilder,
    private shipmentCreateWizardService: ShipmentCreateWizardService) {

    this.form = this.formBuilder.group({});

  }

  ngAfterViewInit() {

    this.form
      .addControl("shipmentCollectionData", this.shipmentCollectionComponent?.form);

    this.shipmentTypeSelectionComponent
      ?.control
      .valueChanges
      .subscribe(value => {

        const collectionConfiguration = ShipmentCollectionConfiguration
          .create()
          .setTipoSpedizione(value);

        this.openPackageComposerDialog(collectionConfiguration);

      });

  }

  openPackageComposerDialog(configuration: ShipmentCollectionConfiguration | null = null) {

    if (!configuration) return;

    let component: typeof ShippingPackageCreationComponent | typeof ShippingPalletSelectionComponent | typeof ShippingBoxSelectionComponent | null = null;

    let panelClass = "";

    switch (configuration.tipoSpedizione) {

      case TipoSpedizioneEnum.GiacenzaPallet:
        component = ShippingPalletSelectionComponent;
        panelClass = "shipping-package-selection-container";
        break;

      case TipoSpedizioneEnum.GiacenzaScatola:
        component = ShippingBoxSelectionComponent;
        panelClass = "shipping-box-selection-container";
        break;

      case TipoSpedizioneEnum.ComposizioneBox:
      default:
        component = ShippingPackageCreationComponent;
        panelClass = "shipping-package-creation-container";
        break;

    }

    if (!component) return;

    const config = {
      disableClose: true,
      data: { configuration, collection: this.shipmentCollectionComponent?.collection ?? [] },
      minWidth: "80vw",
      panelClass
    };

    const reference = this.dialog.open(<any>component, config);

    reference
      .afterClosed()
      .subscribe({
        next: (result: SectionDetailDataConfiguration[] | null) => {

          if (!result) return;

          const masterSection = result[0];
          const quantitaTotaleBottiglie = masterSection.attributes?.quantitaTotaleBottiglie || 0;

          if (configuration.tipoSpedizione === TipoSpedizioneEnum.GiacenzaScatola && quantitaTotaleBottiglie) {

            const prodottoId = masterSection.attributes?.prodottoId;
            const productCountInsideCollection = this.shipmentCreateWizardService.productCountInsideCollection(<GiacenzaVinoModel>{ vino: { id: prodottoId } }, this.shipmentCollectionComponent?.collection?.value) || 0;

            if (productCountInsideCollection > quantitaTotaleBottiglie - defaultBoxBottleCount) {

              this.snackbar.open("Attenzione, il prodotto selezionato ha terminato le giacenze disponibili per questa spedizione!");
              return;

            }

          }

          configuration
            .setSections(result);

          this.updateCollection(configuration);

        }

      });

  }

  updateCollection(configuration: ShipmentCollectionConfiguration | null) {

    this.shipmentCollectionComponent
      ?.addToCollection(configuration);

  }

  editComposerBox(value: ShipmentCollectionConfiguration) {

    this.openPackageComposerDialog(value);

  }

}
