import { Injectable } from "@angular/core";
import { CtControlValidator } from "@ctsolution/ct-framework";
import { delay, distinctUntilChanged, Observable } from "rxjs";
import { AbstractControl, FormControl, FormGroup } from "@angular/forms";
import { MwLookupControlComponent } from "./mw-lookup-control.component";
import { GenericReadModel } from "../../core/classes/generic-read.model";
import { map } from "rxjs/operators";
import { MwLookupControlConfiguration } from "./mw-lookup-control.configuration";

export interface LookupComponentContainer<T, R> {
  lookupControl?: MwLookupControlComponent<T, R> | null;
  configuration?: MwLookupControlConfiguration<T, R> | null;
}

@Injectable()
export class MwLookupControlService {

  constructor() {
  }

  initializeControl<T, R>(form: FormGroup, component: LookupComponentContainer<T, R> | null = null, name: string, required: boolean = false) {

    const configuration = component
      ?.configuration
      ?.CTControl
      ?.setName(name)
      ?.setFormParent(form);

    if (required) {

      configuration
        ?.setValidators([CtControlValidator.create({ name: "required" } as CtControlValidator)]);

    }

    return configuration;

  }

  valueChangesSubscription<T, R>(component: LookupComponentContainer<T, R> | null) {

    return component
      ?.configuration
      ?.CTControl
      ?.control
      ?.valueChanges
      .pipe(
        distinctUntilChanged(),
        delay(100),
        map(value => !!value?.value ? value.value : value));

  }

  setDefaultValue<T, R>(component: LookupComponentContainer<T, R> | null, values: string[], force: boolean = false) {

    const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

    const checkOptions = async () => {

      const options = component?.configuration?.CTControl.valueOptions;

      if ((options ?? []).length > 0) {

        const selectedDefault = options
          ?.find(element => values.some(value => element.label && element.label.toLowerCase().includes(value.toLowerCase())));

        if (selectedDefault && (!component?.configuration?.CTControl.control?.value || force)) { // nel mentre potrei averlo gia valorizzato da qualche altra parte

          component
            ?.configuration
            ?.CTControl
            .setValue(selectedDefault.value);

        }

      } else {
        await delay(500);
        await checkOptions();
      }
    };

    // Start the initial check
    checkOptions();

  }

  async setupLookupValuesByCountryFilter<T, R>(component?: LookupComponentContainer<T, R> | null, value: GenericReadModel | null = null) {

    if (!component || !value?.id) return;

    await this.forceUpdate(component.configuration?.CTControl.control);

    component
      ?.lookupControl
      ?.setupFetcher({ filters: [{ stato: { id: { eq: value?.id } } }] });

  }

  /*
   * TODO: Questa funzione non mi convince, serve a forzare alcuni reload quando la query va in errore
   * */
  async forceUpdate(control: FormGroup<any> | FormControl<any> | null | undefined) {

    return new Promise((resolve, reject) => {

      control?.setValue("");

      setTimeout(() => {

        control?.setValue(null);
        resolve(null);

      }, 100);

    });


  }

}
