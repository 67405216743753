import { Injectable } from "@angular/core";
import { KeycloakService } from "keycloak-angular";
import jwtDecode from "jwt-decode";

@Injectable({
  providedIn: "root"
})
export class JwtService {

  constructor(private keycloakService: KeycloakService) {
  }

  async getUserEmail(): Promise<string | null> {

    const decodedToken = await this.getDecodedToken();

    return decodedToken?.email ?? null;

  }

  async getGivenName(): Promise<string> {

    const decodedToken = await this.getDecodedToken();

    const firstName = (decodedToken["given_name"] ?? "User").charAt(0).toUpperCase() + (decodedToken["given_name"] ?? "User").slice(1);

    return firstName;

  }

  async getDepositanti(): Promise<Array<string> | null> {

    const decodedToken = await this.getDecodedToken();

    return decodedToken["depositante-id"] ?? null;

  }


  /*
  *
  * Presente solo se le condizioni sono già state accettate, il valore è il timestamp dell'accettazione,
  * utile se si vuole verificare la necessità di riaccettare i termini
  *
  * */
  async getCommercialConditionsAccepted(): Promise<Array<string> | null> {

    const decodedToken = await this.getDecodedToken();

    return decodedToken["commercial_conditions_accepted"] ?? null;

  }

  private async getDecodedToken(): Promise<any | null> {

    try {

      const token = await this.keycloakService.getToken();
      return jwtDecode(token);

    } catch (error) {

      console.error("Error decoding token:", error);
      return null;
    }

  }

}
