import { ChangeDetectorRef, Component, Input, ViewChild } from "@angular/core";
import { MwDatatableConfiguration } from "../../../components/mw-datatable/mw-datatable.configuration";
import { BaseController } from "../../../core/controllers/base.controller";
import { CustomerDatatableConfiguration } from "./customer-datatable.configuration";
import {
  indirizzoPrincipaleHeader, indirizzoPrincipaleProvinciaHeader, indirizzoPrincipaleStatoHeader,
  nominativoHeader,
  ragioneSocialeHeader
} from "./customer-datatable.headers";
import { CustomerService } from "../customer.service";
import {
  CTDatatableButtonConfiguration,
  CTDatatableConfiguration,
  CTDatatableHeaderCellConfiguration, CTDatatablePermissions
} from "@ctsolution/ct-framework";
import { QueriesService } from "../../../core/lib/queries.service";
import { ApolloVariableClass } from "../../../core/classes/apollo/apollo.variable";
import { ClientiApolloResult } from "../../../core/interfaces/apollo/clienti.apollo-result";
import { DestinazioneModel } from "../../../core/classes/destinazione";
import { BaseApolloQueryClass } from "../../../core/classes/apollo/apollo.base.query";
import { MwDatatableComponent } from "../../../components/mw-datatable/mw-datatable.component";
import { IndirizzoModel } from "../../../core/classes/indirizzo";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-customer-datatable",
  templateUrl: "./customer-datatable.component.html"
})
export class CustomerDatatableComponent<T> {

  @Input() configuration: CustomerDatatableConfiguration | null = null;
  @ViewChild("table") table: MwDatatableComponent<DestinazioneModel | null> | null = null;

  listConfiguration: MwDatatableConfiguration<ClientiApolloResult> = MwDatatableConfiguration
    .create<ClientiApolloResult>()
    .setFetchDataCaller(async (variables: ApolloVariableClass) => {
      variables
        .setTipodestinazione(this.configuration?.customerType!);

      const query = await this._queries.entities.destinazioni();

      if (!query) return null;

      const parameter = BaseApolloQueryClass
        .create()
        .setVariables(variables)
        .setQuery(query);

      return this.controller.list<ClientiApolloResult>(parameter);
   
    })
    .setFetchResponseDataMapper((value: ClientiApolloResult) => value.destinazioni)
    .setCTDatatableConfiguration(
      CTDatatableConfiguration
        .create<ClientiApolloResult>()
        .setEmptyMessage(this.translate.instant("CT_DATATABLE.CLIENTRECORDS.NoClientFound"))
        .setLoadingBody("Caricamento dati clienti. Attendere prego.")
        .setSearchInputPlaceholder(this.translate.instant("CT_GENERAL.Search"))
        .setPermissions([CTDatatablePermissions.insert, CTDatatablePermissions.update, CTDatatablePermissions.read])
        .setCreateButtonConfiguration(
          CTDatatableButtonConfiguration
            .create()
            .setIcon("pi pi-user-plus")
            .setLabel(this.translate.instant('CT_DATATABLE.CLIENTRECORDS.AddButton'))
        )
    );

  constructor(
    private controller: BaseController,
    private customerService: CustomerService,
    private _queries: QueriesService,
    private translate: TranslateService,
    private cdr: ChangeDetectorRef) {
  }

  ngAfterViewInit() {

    const columns: CTDatatableHeaderCellConfiguration[] = [];

    const addressHeader = indirizzoPrincipaleHeader(this.translate);

    if (this.configuration?.isBusiness) {

      columns
        .push(ragioneSocialeHeader(this.translate));

      addressHeader
        .setLabel(this.translate.instant("CT_DATATABLE.CLIENTRECORDS.LegalAddress"));

    } else {

      columns.push(nominativoHeader(this.translate));

    }

    columns
      .push(addressHeader, indirizzoPrincipaleProvinciaHeader(this.translate), indirizzoPrincipaleStatoHeader(this.translate));

    this.listConfiguration
      .CTDatatableConfiguration
      ?.setHeaders(columns);

    this.cdr.detectChanges();

  }

  getFormattedIndirizzo(value: IndirizzoModel | null) {

    if (!value) return "";

    return [value.via, value.cap, value.comune].filter(elm => !!elm).join(", ");

  }

  async onSave(event: DestinazioneModel | null) {

    this.customerService
      .setupCustomer(this.configuration?.customerType!, event)
      .then(success => {

        if (success) {

          this.table
            ?.reinit();

        }

      });

  }

  detail(customer: DestinazioneModel) {

    if (!customer.id) return;

    this.customerService
      .getCustomerDialogDetail(customer.id);

  }

}
