import { ChangeDetectorRef, Component } from "@angular/core";
import { CtControlConfiguration } from "@ctsolution/ct-framework";
import { VIA_CONTROL } from "./address.control";

@Component({
  selector: "mw-address-control",
  template: `
    <ct-control *ngIf="control" [configuration]="control"></ct-control>`
})
export class AddressControlComponent {

  control: CtControlConfiguration = CtControlConfiguration.create(VIA_CONTROL);

  constructor(private cdr: ChangeDetectorRef) {
  }

  ngAfterViewInit() {

    this.cdr.detectChanges();

  }

}
