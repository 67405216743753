import { Component, ElementRef, Renderer2 } from "@angular/core";

@Component({
  selector: "app-mw-bottle",
  template: `
    <div class="bottle first">
      <div class="top-part">
        <div class="tilt"></div>
      </div>
      <div class="bottle-body">
        <div class="shine">
          <div class="round"></div>
          <div class="big"></div>
        </div>
        <div class="sticker"></div>
      </div>
    </div>`,
  styleUrls: ["./mw-bottle.component.scss"]
})
export class MwBottleComponent {

  constructor(private el: ElementRef, private renderer: Renderer2) {
  }

  ngOnInit() {
    this.setup();
  }

  setup() {
    // Ottieni l'elemento DOM con classe 'bottle'
    const bottleElement: HTMLElement =
      this.el.nativeElement.querySelector(".bottle");

    // Aggiungi dinamicamente la classe restituita dalla funzione getBottleType()
    const bottleType = this.getBottleType();
    this.renderer.addClass(bottleElement, bottleType);
  }

  getBottleType(): string {
    const randomNumber = Math.random();
    if (randomNumber < 0.33) {
      return "third";
    } else if (randomNumber < 0.66) {
      return "second";
    } else {
      return "first";
    }
  }

}
