import { Injectable } from "@angular/core";
import { MwDateRangePickerComponent } from "../../components/mw-date-range-picker/mw-date-range-picker.component";
import { MwDatatableComponent } from "../../components/mw-datatable/mw-datatable.component";
import { MwDatatableConfiguration } from "../../components/mw-datatable/mw-datatable.configuration";
import { ApolloVariableClass } from "../../core/classes/apollo/apollo.variable";
import { BaseApolloQueryClass } from "../../core/classes/apollo/apollo.base.query";
import { CTDatatableConfiguration, CTDatatableHeaderCellConfiguration } from "@ctsolution/ct-framework";
import { TypedDocumentNode } from "@apollo/client";
import { BaseController } from "../../core/controllers/base.controller";

@Injectable({
  providedIn: "root"
})
export class MovementsService {

  constructor(private baseController: BaseController) {
  }

  setupDateRangePickerDefaults(dateRangePickerComponent: MwDateRangePickerComponent | null, table: MwDatatableComponent<any> | null) {

    if (!dateRangePickerComponent || !table) return;

    dateRangePickerComponent
      ?.range
      .valueChanges
      .subscribe(() => {

        table
          ?.datatable
          ?.datatable
          ?.filterGlobal("", "contains"); // triggera il filtro di ricerca

      });

    const currentDate = new Date();

    const oneMonthAgo = new Date(currentDate);

    // Sottrai un mese alla data attuale
    oneMonthAgo.setMonth(currentDate.getMonth() - 1);

    dateRangePickerComponent
      ?.setupInitialRange(oneMonthAgo, currentDate);

  }

  getMovementTableConfiguration = <T>(
    apolloQuery: () => Promise<TypedDocumentNode | null>,
    dateRangePickerComponent: MwDateRangePickerComponent,
    mapper: (value: T) => any,
    headers: Array<CTDatatableHeaderCellConfiguration>
  ): MwDatatableConfiguration<T> => MwDatatableConfiguration
    .create<T>()
    .setFetchDataCaller(async (variables: ApolloVariableClass) => {

      const query = await apolloQuery();

      if (!query) return null;

      const parameter = BaseApolloQueryClass
        .create()
        .setVariables(variables)
        .setQuery(query);

      if (dateRangePickerComponent?.range.invalid) return null;

      const formValue = dateRangePickerComponent?.range.value;

      parameter
        .variables
        .setFromDateTime(formValue?.start ?? null)
        .setToDateTime(formValue?.end ?? null);

      return this.baseController.list<T>(parameter);

    })
    .setFetchResponseDataMapper((value: T) => mapper(value))
    .setCTDatatableConfiguration(
      CTDatatableConfiguration
        .create<T>()
        .setEmptyMessage("Nessuna movimentazione trovata.")
        .setLoadingBody("Caricamento movimentazioni. Attendere prego.")
        .setHeaders(headers)
    );

}
