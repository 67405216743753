import { TipodestinazioneEnum } from "../../enum/tipo-destinazione.enum";
import { ApolloOrderInfo } from "../../interfaces/apollo/apollo.order-info";
import { ApolloFilters } from "../../interfaces/apollo/apollo.filters";

export class ApolloVariableClass {

  id: string | null = null;
  depositanteId: string | null = null;
  tipodestinazione: TipodestinazioneEnum | null = null; // può essere: destinazionePrivate, destinazioneBusiness o null (torna tutto)
  vinoId: string | null = null; // serve per le query di giacenza dirette
  destinazioneId: string | null = null;
  isBusiness : boolean | null = null;
  fromDateTime: Date | null = null;
  toDateTime: Date | null = null;

  after: string | null = null;
  searchText: string = ""; // non è nullable
  first: number | null = 10;

  whereAndFilters: Array<ApolloFilters> | null = [];
  order: Array<ApolloOrderInfo> = [];

  constructor() {
  }

  public static create = (): ApolloVariableClass => new ApolloVariableClass();

  setId(value: string | null): ApolloVariableClass {

    this.id = value;
    return this;

  }

  setDepositanteId(value: string | null): ApolloVariableClass {

    this.depositanteId = value;
    return this;

  }

  setTipodestinazione(value: TipodestinazioneEnum | null): ApolloVariableClass {


    if(value == TipodestinazioneEnum.destinazioneBusiness)
      this.isBusiness = true;
    else
      this.isBusiness = false;

      this.tipodestinazione = value;
    return this;

  }

  setdestinazioneId(value: string | null): ApolloVariableClass {

    this.destinazioneId = value;
    return this;

  }

  setSearchText(value: string = ""): ApolloVariableClass {

    this.searchText = value;
    return this;

  }

  setFirst(value: number | null): ApolloVariableClass {

    this.first = value;
    return this;

  }

  setWhereAndFilters(value: Array<ApolloFilters> | null): ApolloVariableClass {

    this.whereAndFilters = value;
    return this;

  }

  setOrder(value: Array<ApolloOrderInfo> = []): ApolloVariableClass {

    this.order = value;
    return this;

  }

  setAfter(value: string | null): ApolloVariableClass {

    this.after = value;
    return this;

  }

  setVinoId(value: string | null): ApolloVariableClass {

    this.vinoId = value;
    return this;

  }

  setFromDateTime(value: Date | null): ApolloVariableClass {

    this.fromDateTime = value;
    return this;

  }

  setToDateTime(value: Date | null): ApolloVariableClass {

    this.toDateTime = value;
    return this;

  }

  setIsBusiness(value: boolean | null): ApolloVariableClass {

    this.isBusiness = value;
    return this;

  }

}
