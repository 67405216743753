<div class="most-wine-contacts-cntr">

  <div fxLayout="row" fxLayoutAlign="space-between center">

    <h3 [innerHTML]="'CT_CONTACTS.title' | translate"></h3>
    <mat-icon class="primary-color" (click)="close()">close</mat-icon>

  </div>

  <div fxLayout="row" fxLayoutAlign="space-start center">

    <div class="icon-text" (click)="mail()">

      <mat-icon class="primary-color">mail</mat-icon>
      {{ viewModel.ProjectConfigurations.email }}

    </div>

  </div>

  <div fxLayout="row" fxLayoutAlign="space-start center">

    <div class="icon-text" (click)="call()">

      <mat-icon class="primary-color">phone</mat-icon>
      {{ viewModel.ProjectConfigurations.tel }}

    </div>

  </div>
  <div fxLayout="row" fxLayoutAlign="space-start center">

    <div class="icon-text" style="text-decoration: none">

      <mat-icon class="primary-color">fax</mat-icon>
      {{ viewModel.ProjectConfigurations.fax }}

    </div>

  </div>
  <div fxLayout="row" fxLayoutAlign="space-start center">

    <div class="icon-text" (click)="googleMaps()">

      <mat-icon class="primary-color">pin_drop</mat-icon>
      {{ viewModel.ProjectConfigurations.address }}

    </div>

  </div>

</div>