import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MovementHistoryComponent } from "./movement-history.component";
import { RouterModule } from "@angular/router";
import { MwDatatableModule } from "../../../components/mw-datatable/mw-datatable.module";
import { MwDateRangePickerModule } from "../../../components/mw-date-range-picker/mw-date-range-picker.module";

@NgModule({
  declarations: [
    MovementHistoryComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild([
      {
        path: "",
        component: MovementHistoryComponent,
        data: {
          title: "Storico movimentazioni"
        }
      }
    ]),
    MwDatatableModule,
    MwDateRangePickerModule
  ]
})
export class MovementHistoryModule {
}
