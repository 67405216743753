import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { CtButtonConfiguration, CtFormConfiguration } from '@ctsolution/ct-framework';
import { DestinazioneModel } from 'src/app/core/classes/destinazione';
import { ProfileBusinessUpdateFormConfiguration, ProfilePrivateUpdateFormConfiguration } from './profille-update-configuration';
import { AddressDataFormComponent } from 'src/app/components/shipment-address-form/address-data-form/address-data-form.component';
import { MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { BaseController } from 'src/app/core/controllers/base.controller';
import { DepositantiControllerRequest } from 'src/app/core/controllers/controllers';
import { TipodepositanteEnum, TipodestinazioneEnum } from 'src/app/core/enum/tipo-destinazione.enum';
import { KeycloakAuthGuard, KeycloakService } from "keycloak-angular";
import { Router } from '@angular/router';

@Component({
  selector: 'app-profile-update-dialog',
  templateUrl: './profile-update-dialog.component.html',
  styleUrls: ['./profile-update-dialog.component.scss']
})
export class ProfileUpdateDialogComponent {

  formConfiguration: CtFormConfiguration
  type: TipodepositanteEnum = TipodepositanteEnum.depositanteBusiness;

  @ViewChild(AddressDataFormComponent) addressDataFormComponent: AddressDataFormComponent | null = null;

  submitButton: CtButtonConfiguration = CtButtonConfiguration
    .create()
    .setLabel("CONFIRM_DIALOG.save")
    .setClass("general-button accept-confirm")
    .setAction(() => this.submit());

  cancelButton: CtButtonConfiguration = CtButtonConfiguration
    .create()
    .setLabel("CONFIRM_DIALOG.cancel")
    .setClass("general-button cancel-confirm")
    .setAction(() => this.close());

  stateCreate = true;

  constructor(private router : Router,protected readonly keycloak: KeycloakService, private baseController : BaseController,private formBuilder: FormBuilder, @Inject(MAT_LEGACY_DIALOG_DATA) public model: DestinazioneModel | null, private dialogRef : MatDialogRef<ProfileUpdateDialogComponent>) {

    this.formConfiguration = CtFormConfiguration.create(this.formBuilder.group({}), ProfileBusinessUpdateFormConfiguration);

  }

  ngAfterViewInit(): void {

    if (this.model) {
      this.setup(this.model);
      this.addressDataFormComponent?.setupValues(this.model.indirizzo, false);
      this.stateCreate = false;
    }

  }



  submit() {

    this.formConfiguration.form?.markAllAsTouched();

    if(this.formConfiguration.form?.valid)
    {
      const model : DestinazioneModel = this.formConfiguration.form.getRawValue();
      model.tipo = this.type;
      model.indirizzo = this.addressDataFormComponent?.form.value;

      if(model.indirizzo)
      {

          (<any>model.indirizzo)["StatoId"] = this.addressDataFormComponent?.form.value.stato.value.id;
  
      }

      if(model.dataDiNascita){

        model.dataDiNascita = new Date(model.dataDiNascita).toISOString().split('T')[0];

      }

      const paramater = DepositantiControllerRequest();


      this.baseController.createDepistante(model,paramater).then(async () => 
        {
          await this.keycloak.updateToken();
          this.dialogRef.close(true);
          window.location.reload();
          this.router.navigate(['dashboard']);

    });
    }


  }

  close() { 
    this.dialogRef.close();
  }

  setup(model: DestinazioneModel) {

    if(model.partitaIva)
      this.formConfiguration.form?.patchValue(model);
    else{
      this.formConfiguration = CtFormConfiguration.create(this.formBuilder.group({}), ProfilePrivateUpdateFormConfiguration);
      this.formConfiguration.form?.patchValue(model);

    }
  

  }

  onChange(event: any) {

    switch (event.value) {

      case 1:
      this.formConfiguration = CtFormConfiguration.create(this.formBuilder.group({}), ProfileBusinessUpdateFormConfiguration);
      this.type = TipodepositanteEnum.depositanteBusiness;
      break;

      case 2:
      this.formConfiguration = CtFormConfiguration.create(this.formBuilder.group({}), ProfilePrivateUpdateFormConfiguration);
      this.type = TipodepositanteEnum.depositantePrivate;

      break;

    }
  }

}
