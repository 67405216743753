import { CTBase } from "@ctsolution/ct-base";
import { TipoProdottoEnum } from "../enum/tipo-prodotto.enum";
import { GenericReadModel } from "./generic-read.model";

export class ProdottoModel extends CTBase<ProdottoModel> {

  tipoVino: GenericReadModel | null = null;
  tipoVinoId: string | null = null;
  formato: GenericReadModel | null = null;
  formatoId: string | null = null;
  produttore: string | null = null;
  regione: { id: string | null; nome: string | null; } | null = null;
  regioneId: string | null = null;
  stato: { id: string | null; nome: string | null; } | null = null;
  statoId: string | null = null;
  nomeCommerciale: string | null = null;
  anno: string | null = null;
  gradazione: number | null = null;
  flagAlcol: boolean = true;
  vitigno: string | null = null;
  tipo: TipoProdottoEnum = TipoProdottoEnum.Vino;
  descrizione: string | null = null;
  descrizioneBreve: string | null = null;
  unitaMisuraMinima: string | null = null;
  unitaMisuraDeposito: string | null = null;
  codice: string | null = null;
  barcode: string | null = null;
  denominazione: GenericReadModel | null = null;
  denominazioneId: string | null = null;
  menzione: GenericReadModel | null = null;
  menzioneId: string | null = null;
  menzioneEstero: GenericReadModel | null = null;
  menzioneEsteroId: string | null = null;
  tipologia: GenericReadModel | null = null;
  tipologiaId: string | null = null;
  metodologia: GenericReadModel | null = null;
  metodologiaId: string | null = null;
  id: string | null = null;
  depositante: GenericReadModel | null = null;
  dosaggioSpumante: GenericReadModel | null = null;
  dosaggioSpumanteId: string | null = null;
  annoSpumante: GenericReadModel | null = null;
  annoSpumanteId: string | null = null;
  metodoSpumante: GenericReadModel | null = null;
  metodoSpumanteId: string | null = null;

  private constructor();
  private constructor(model?: ProdottoModel);
  private constructor(model?: ProdottoModel) {

    super();

    if (model) {

      this.getClass(model);

      if (this.anno && (typeof this.anno === "number" && !isNaN(this.anno))) {

        this.anno = (<number>this.anno).toString();

      }

      if (this.tipoVino) {

        if ((<any>this.tipoVino).value) (<any>this.tipoVino) = (<any>this.tipoVino).value;

        this.tipoVinoId = this.tipoVino.id;

      }

      if (this.formato) this.formatoId = this.formato.id;

      if (this.denominazione) this.denominazioneId = this.denominazione.id;

      if (this.menzione) this.menzioneId = this.menzione.id;

      if (this.menzioneEstero) this.menzioneEsteroId = this.menzioneEstero.id;

      if (this.tipologia) this.tipologiaId = this.tipologia.id;

      if (this.stato) this.statoId = this.stato?.id;

      if (this.regione) {

        if ((<any>this.regione).value) (<any>this.regione) = (<any>this.regione).value;

        this.regioneId = this.regione.id;

      }

      if (this.metodologia) this.metodologiaId = this.metodologia.id;

      if (this.dosaggioSpumante) this.dosaggioSpumanteId = this.dosaggioSpumante.id;

      if (this.annoSpumante) this.annoSpumanteId = this.annoSpumante.id;

      if (this.metodoSpumante) this.metodoSpumanteId = this.metodoSpumante.id;

      this.flagAlcol = (this.gradazione ?? 0) > 0;

    }

  }

  public static create = (model?: ProdottoModel) => new ProdottoModel(model);

  setId(value: string | null): ProdottoModel {

    this.id = value;
    return this;

  }

}



