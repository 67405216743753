import { Injectable } from "@angular/core";
import { HttpBackend, HttpClient } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { environment } from "../../../../environments/environment";

@Injectable()
export class MeteoWidgetService {

  private apiKey = environment.openWeatherMap.api_key;
  private apiUrl = "https://api.openweathermap.org/data/2.5/weather";
  private localStorageKey = "weatherData";

  constructor(private http: HttpClient, handler: HttpBackend) {

    this.http = new HttpClient(handler);

  }

  getWeatherData(location: string = "Pocapaglia,Cuneo"): Observable<WeatherResponse> {

    const storedData = localStorage.getItem(this.localStorageKey);

    if (storedData) {

      const parsedData = JSON.parse(storedData);
      const lastUpdate = new Date(parsedData.lastUpdate);
      const today = new Date();

      // Verifica se i dati sono stati memorizzati oggi
      if (this.isSameDay(lastUpdate, today)) {

        return of(parsedData.data);

      }

    }

    // Se i dati non sono stati memorizzati oggi o non sono presenti in locale, effettua una nuova richiesta API
    const params = {
      q: location,
      appid: this.apiKey,
      units: "metric" // Specifica l'unità di misura della temperatura,
    };

    return this.http
      .get(this.apiUrl, { params })
      .pipe(
        map((data) => {
          const today = new Date();
          const newData = { data, lastUpdate: today };
          localStorage.setItem(this.localStorageKey, JSON.stringify(newData));
          return data as WeatherResponse;
        }),
        catchError(this.handleError<any>("getWeatherData"))
      );
  }

  private handleError<T>(operation = "operation", result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      // Gestisci l'errore in base alle tue esigenze
      return of(result as T);
    };
  }

  private isSameDay(date1: Date, date2: Date): boolean {
    return (
      date1.getDate() === date2.getDate() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getFullYear() === date2.getFullYear()
    );
  }

}

interface WeatherResponse {
  weather: Weather[];
  main: Main;
}

interface Weather {
  id: number;
  main: string;
  description: string;
  icon: string;
}

interface Main {
  temp: number;
  feels_like: number;
  humidity: number;
}
