<div mat-dialog-title fxLayout="row" fxLayoutAlign="space-between center">

  {{ 'CT_MODALFORM.WineCreation' | translate }}

  <button
    mat-icon-button color="primary" (click)="close()">

    <mat-icon>close</mat-icon>

  </button>

</div>

<div mat-dialog-content>

  <form [formGroup]="form">

    <section>

      <div fxLayout="row wrap">

        <div fxFlex.gt-md="33.33" fxFlex="100">

          <mw-paese-control></mw-paese-control>

        </div>

        <div fxFlex.gt-md="33.33" fxFlex="100">

          <mw-regione-control></mw-regione-control>

        </div>

        <div fxFlex.gt-md="33.33" fxFlex="100">

          <mw-denominazione-control></mw-denominazione-control>

        </div>

        <div fxFlex.gt-md="33.33" fxFlex="100">

          <mw-tipo-vino-control></mw-tipo-vino-control>

        </div>

        <div fxFlex.gt-md="33.33" fxFlex="100">

          <mw-menzione-control></mw-menzione-control>

        </div>

        <div fxFlex.gt-md="33.33" fxFlex="100" [hidden]="isItalia || (!form.value.paese && !isItalia)">

          <mw-menzione-estero-control></mw-menzione-estero-control>

        </div>

        <div fxFlex.gt-md="33.33" fxFlex="100">

          <mw-nome-commerciale-control></mw-nome-commerciale-control>

        </div>

        <div fxFlex.gt-md="33.33" fxFlex="100">

          <mw-anno-control></mw-anno-control>

        </div>

        <div fxFlex.gt-md="66.66" fxFlex="100">

          <mw-tipologia-control></mw-tipologia-control>

        </div>

      </div>

    </section>

    <section [hidden]="!isSpumante">

      <div fxLayout="row wrap">

        <div fxFlex.gt-md="33.33" fxFlex="100">

          <mw-spumante-metodo></mw-spumante-metodo>

        </div>

        <div fxFlex.gt-md="33.33" fxFlex="100">

          <mw-spumante-dosaggio></mw-spumante-dosaggio>

        </div>

        <div fxFlex.gt-md="33.33" fxFlex="100">

          <mw-spumante-annata></mw-spumante-annata>

        </div>

      </div>

    </section>

    <section>

      <div fxLayout="row wrap">

        <div fxFlex.gt-md="33.33" fxFlex="100">

          <mw-formato-bottiglia-control></mw-formato-bottiglia-control>

        </div>

        <div fxFlex.gt-md="33.33" fxFlex="100">

          <mw-produttore-control></mw-produttore-control>

        </div>

        <div fxFlex.gt-md="33.33" fxFlex="100">

          <mw-gradazione-alcolica-control></mw-gradazione-alcolica-control>

        </div>

        <div fxFlex.gt-md="33.33" fxFlex="100">

          <mw-metodologia-control></mw-metodologia-control>

        </div>


      </div>

    </section>

  </form>

</div>

<div mat-dialog-actions align="end">

  <ct-button [configuration]="submitButton"></ct-button>

</div>
