import {ModalitaStoccaggio} from "../../../../../core/enum/modalita-stoccaggio.enum";
import { BoxComposerConfiguration } from "../box-composer/box-composer.configuration";

export class PackageComposerConfiguration {

  depositPackagingType: ModalitaStoccaggio | null = null;
  boxComposerConfiguration: BoxComposerConfiguration = BoxComposerConfiguration
    .create();

  private constructor() {
  }

  public static create = (): PackageComposerConfiguration => new PackageComposerConfiguration();

  setDepositPackagingType(value: ModalitaStoccaggio): PackageComposerConfiguration {

    this.depositPackagingType = value;

    this.boxComposerConfiguration
      .setDepositPackagingType(this.depositPackagingType);

    return this;

  }

}
