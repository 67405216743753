import { Injectable } from "@angular/core";
import { DataRequest } from "@ctsolution/ct-webapi";
import { JwtService } from "./jwt.service";
import { BaseRequestClass } from "../classes/apollo/apollo.base.request";

@Injectable({
  providedIn: "root"
})
export class DepositorService {

  private depositorValue: string | null = null;

  constructor(private _jwt: JwtService) {
  }

  async getDepositor() {

    if (!this.depositorValue) {

      const depositors: Array<string> | null = await this._jwt.getDepositanti();

      if (depositors?.length) this.depositorValue = depositors[0];

    }

    return this.depositorValue;

  }


  async generateDepositorRequestController(parameter: BaseRequestClass) {

    const depositor = parameter.depositanteId ?? await this.getDepositor();

    if (!depositor) return null;

    return DataRequest
      .create()
      .setController([...parameter.controller ?? "", ...[depositor]]);

  }

}
