import { DestinazioneModel } from "../destinazione";
import { GiacenzaVinoModel } from "../../interfaces/giacenza-vino.model";

export class GiacenzaPalletModel {

  id: string | null = null;
  depositante: DestinazioneModel | null = null;
  contenuti: Array<GiacenzaVinoModel> | null = null;
  deposito: { id: string | null; codice: string | null; } | null = null;
  quantita: number | null = null;

}
