import { Injectable } from "@angular/core";
import { MatLegacyDialog } from "@angular/material/legacy-dialog";
import { AddressUpdateDialogComponent } from "./address-update-dialog.component";
import { DestinazioneModel } from "../../../../core/classes/destinazione";

@Injectable()
export class AddressUpdateDialogService {

  constructor(private dialog: MatLegacyDialog) {
  }

  open(model: DestinazioneModel | null) {

    this.dialog
      .open(AddressUpdateDialogComponent, { data: model });

  }

}
