import { ModalitaStoccaggio } from "../../../../../core/enum/modalita-stoccaggio.enum";

export class BoxComposerConfiguration {

  depositPackagingType: ModalitaStoccaggio = ModalitaStoccaggio.Pallet;

  private constructor() {
  }

  public static create = (): BoxComposerConfiguration => new BoxComposerConfiguration();

  setDepositPackagingType(value: ModalitaStoccaggio): BoxComposerConfiguration {

    this.depositPackagingType = value;
    return this;

  }

}
