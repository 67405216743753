import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { PalletStockDatatableComponent } from "./pallet-stock-datatable.component";
import { MwDatatableModule } from "../../../../components/mw-datatable/mw-datatable.module";
import { RippleModule } from "primeng/ripple";
import { ButtonModule } from "primeng/button";
import {
  SectionDetailDataModule
} from "../../../../components/model-detail/section-detail-data/section-detail-data.module";
import { UnpackPalletDialogModule } from "../unpack-pallet-dialog/unpack-pallet-dialog.module";

@NgModule({
  declarations: [PalletStockDatatableComponent],
  imports: [
    CommonModule,
    MwDatatableModule,
    RippleModule,
    ButtonModule,
    SectionDetailDataModule,
    UnpackPalletDialogModule
  ],
  exports: [PalletStockDatatableComponent]
})
export class PalletStockDatatableModule {
}
