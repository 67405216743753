// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  projectName: "MostWine",
  dev: {
    REST_API_SERVER: (<any>window)["env"]["REST_API_SERVER"] || "https://api.test.most.wine/v1",
    GRAPHQL_SERVER: (<any>window)["env"]["GRAPHQL_SERVER"] || "https://api.test.most.wine/graphql/"
  },
  keycloak: {
    config: {
      url: (<any>window)["env"]["keycloak"]["config"]["url"] || "https://auth.test.most.wine/",
      clientId: (<any>window)["env"]["keycloak"]["config"]["clientId"] || "mostwine-webapp",
      realm: (<any>window)["env"]["keycloak"]["config"]["realm"] || "mostwine"
    }
  },
  openWeatherMap: {
    api_key: "aee5f80e42a56869f0f6312a937dc9ff"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
