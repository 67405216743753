import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { PackageSelectControlComponent } from "./package-select-control.component";
import { MwLookupControlModule } from "../../../../../components/mw-lookup-control/mw-lookup-control.module";

@NgModule({
  declarations: [
    PackageSelectControlComponent
  ],
  imports: [
    CommonModule,
    MwLookupControlModule
  ],
  exports: [
    PackageSelectControlComponent
  ]
})
export class PackageSelectControlModule {
}
