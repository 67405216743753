import { Component, Input } from "@angular/core";
import { StateService } from "../../pages/deposit/deposit-state-change-dialog/state.service";

@Component({
  selector: "app-legend",
  template: `
    <div fxLayout="row wrap">
      <div class="bullet-panel" *ngFor="let stato of stati">
        <app-bullet-state [state]="stato"></app-bullet-state>
        {{ stateService.getStateLabel(stato) }}
      </div>
    </div>`,
  styles: [`::ng-deep .bullet-panel {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: initial !important;
    margin-right: 10px;

    app-bullet-state {
      margin: 5px;

      .bullet-state-component-container {
        height: 15px;
        width: 15px;
        min-height: 15px;
      }
    }
  }`]
})
export class StateLegendComponent {

  @Input() stati: any[] = [];

  constructor(public stateService: StateService) {
  }

}
