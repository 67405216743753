<div class="deposit-create-step3-container">

  <div class="text-center m-t-20">

    <h2 [innerHTML]="'CT_SHIPMENT_DEPOSITS.shipment_title' | translate"></h2>
    <p class="m-t-0" [innerHTML]="'CT_SHIPMENT_DEPOSITS.shipment_subtitle' | translate"></p>

  </div>

  <form [formGroup]="form">

    <app-shipping-method-selector #shippingMethodSelector></app-shipping-method-selector>

  </form>

  <hr>

  <div class="buttons-stepper m-t-40">

    <button class="general-button" mat-button matStepperPrevious>{{'CT_GENERAL.BACK' | translate}}</button>

    <button class="general-button" mat-button matStepperNext>{{'CT_GENERAL.NEXT' | translate}}</button>

  </div>

</div>
