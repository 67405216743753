import { IndirizzoModel } from "../indirizzo";
import { ColloRitiro } from "./collo-ritiro";

export class RitiroDeposito {

  indirizzo: IndirizzoModel | null = null;
  colli: ColloRitiro[] = [];

  private constructor(
    public nomeMittente: string,
    public telefonoMittente: string,
    public emailMittente: string
  ) {
  }

  public static create = (nomeMittente: string, telefonoMittente: string, emailMittente: string) => new RitiroDeposito(nomeMittente, telefonoMittente, emailMittente);

  setIndirizzo(value: IndirizzoModel | null = null) {

    this.indirizzo = value;

    if (value) {

      if ((<any>value?.stato)?.value) {

        value.stato = (<any>value?.stato)?.value;

      }

      if (value?.stato?.id) {

        (<any>this.indirizzo)["StatoId"] = value?.stato?.id ?? null;

      }

    }

    return this;

  }

  setColli(value: ColloRitiro[] = []) {

    this.colli = value;
    return this;

  }

}
