import { TipodestinazioneEnum } from "../../../core/enum/tipo-destinazione.enum";

export class CustomerDatatableConfiguration {

  get customerType(): TipodestinazioneEnum | null {
    return this._customerType;
  }

  private set customerType(value: TipodestinazioneEnum | null) {
    this._customerType = value;
  }

  private _customerType: TipodestinazioneEnum | null = null;

  public static create = (): CustomerDatatableConfiguration => new CustomerDatatableConfiguration();

  setCustomerType(value: TipodestinazioneEnum | null): CustomerDatatableConfiguration {

    this.customerType = value;
    return this;

  }

  get isBusiness(): boolean {

    return this.customerType === TipodestinazioneEnum.destinazioneBusiness;

  }

}
