import { RitiroModel } from "./ritiro";
import { DepositType } from "../../enum/deposit-type";
import { ModalitaStoccaggio } from "../../enum/modalita-stoccaggio.enum";
import { v4 as uuidv4 } from "uuid";
import { StatoDepositoEnum } from "../../enum/stato-deposito.enum";
import { MWDateFormatter } from "../../lib/date-format.service";
import { ColloStoccaggio } from "./collo-stoccaggio";
import { ColloRitiro } from "./collo-ritiro";
import {
  DepositFormDTO, Package
} from "../../../pages/deposit/deposit-create-wizard/deposit-create-wizard.interfaces";
import { ImballoRitiro } from "../../interfaces/imballo-ritiro";
import { CambiStatoModel } from "../../interfaces/cambi-stato.model";

export class DepositoModel {

  id: string | null = null;
  note: string | null = null;
  dataPresuntaRicevimentoMerce: string | null = null;
  colliStoccaggio: Array<ColloStoccaggio> = new Array<ColloStoccaggio>();
  colliRitiro: Array<ColloRitiro> = new Array<ColloRitiro>();
  ritiro: RitiroModel | null = null;
  stato: StatoDepositoEnum | null = null;
  depositante: { id: string | null } | null = null;
  descrizione: string | null = null;
  codice: string | null = null;
  cambiStato: CambiStatoModel[] | null = null;

  private constructor() {
  }

  public static create = (): DepositoModel => new DepositoModel();

  setId(value: string | null) {

    this.id = value;
    return this;

  }

  setupByForm(value: DepositFormDTO) {

    this
      .setId(uuidv4())
      .setNote(value.step4.datiAggiuntivi.note)
      .setupColliStoccaggioFromStep2Value(value.step2?.colliStoccaggio?.packages, value.step1?.depositPackagingType);

    //#region SETUP COLLI RITIRO
    /*
    * È corretto che, se la spedizione è autonoma, l'imballo per il ritiro sia nullo (poiché, essendo una spedizione autonoma, Most non ha bisogno di sapere come saranno confezionate le bottiglie; ciò che arriva andrà direttamente in deposito).
    * Uno degli aspetti sollevati è la questione dello spazio fisico occupato da questi depositi e della prenotazione dello stesso. Attualmente, hanno deciso di gestirla così, prendendo per buono che lo spazio sia sempre disponibile.
    * Inoltre, lo stesso discorso si applica alle bottiglie sfuse. Dal confronto avuto nel corso del tempo con il destinazione e con la parte sviluppo Most, è emerso che se le bottiglie sono sfuse, vengono considerate come bottiglie sfuse.
    */

    let spedizioneBottiglieSfuse = value.step1?.depositPackagingType === ModalitaStoccaggio.Bottiglia;
    let spedizioneAutonoma = value.step3?.depositType === DepositType.autonoma;

    if (!(spedizioneAutonoma || spedizioneBottiglieSfuse)) {

      const collection = value.step4?.colliRitiro.collection;

      this
        .setupColliRitiro(collection);

    }

    //#endregion SETUP COLLI RITIRO

    let depositoACuraDiMost = value.step3.depositType == DepositType.most;

    if (depositoACuraDiMost) {

      this.ritiro = RitiroModel
        .create()
        .setDataPickUp(value.step4?.datiAggiuntivi.dataRitiro);

    } else {

      this.setDataPresuntaRicevimentoMerce(value.step4?.datiAggiuntivi.dataRitiro);

    }

    return this;

  }

  private setupColliStoccaggioFromStep2Value(value: Package[] = [], modalitaStoccaggio: ModalitaStoccaggio) {

    this.colliStoccaggio = new Array<ColloStoccaggio>();

    value
      .forEach((pallet, index) => {

        const stoccaggio = pallet.modalitaStoccaggio ?? modalitaStoccaggio;

        const collo = ColloStoccaggio
          .create()
          .setModalitaStoccaggio(stoccaggio)
          .setupPackageProducts(pallet, stoccaggio);

        collo
          .setId(uuidv4())
          .setProductsIds();

        this.colliStoccaggio
          .push(collo);

      });

  }

  private setupColliRitiro(value: ImballoRitiro[]) {

    this.colliRitiro = new Array<ColloRitiro>();

    value
      .forEach((imballo, idx) => {

        const collo: ColloStoccaggio | null = (this.colliStoccaggio ?? [])[idx];

        if (!collo) return;

        const colloRitiro = new ColloRitiro(imballo, collo)
          .setId(uuidv4());

        let spedizionePallet = collo.modalitaStoccaggio === ModalitaStoccaggio.Pallet;

        // la quantita variabile viene utilizzata solo per i pallet, per tutti gli altri deve rimanere 1 e la tipologia di colloritiro deve essere scatola
        if (!spedizionePallet) colloRitiro.tipo = ModalitaStoccaggio.Scatola;

        this.colliRitiro?.push(colloRitiro);

      });

  }

  setDataPresuntaRicevimentoMerce(value: string | Date | null) {

    if (value instanceof Date) {

      value = MWDateFormatter
        .create()
        .getDateISOString(value);

    }

    this.dataPresuntaRicevimentoMerce = value;
    return this;

  }

  setNote(value: string | null) {

    this.note = value;
    return this;

  }

}
