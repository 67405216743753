import {
  SectionDetailDataConfiguration
} from "../../../../../components/model-detail/section-detail-data/section-detail-data.configuration";
import { TipoSpedizioneEnum } from "../../../../../core/enum/tipo-spedizione.enum";

export class ShipmentCollectionConfiguration {

  sections: SectionDetailDataConfiguration[] = [];
  tipoSpedizione: TipoSpedizioneEnum | null = null;
  attributes: { [key: string]: any } = {}; // Nuovo campo attributes

  private constructor() {
  }

  public static create = (): ShipmentCollectionConfiguration => new ShipmentCollectionConfiguration();

  setSections(value: SectionDetailDataConfiguration[]) {

    this.sections = value;

    if (this.sections.length) {

      const masterSection = this.sections[0];

      if (masterSection.attributes) {

        this.attributes = masterSection.attributes;

      }

    }

    return this;

  }

  setTipoSpedizione(value: TipoSpedizioneEnum | null) {

    this.tipoSpedizione = value;
    return this;

  }

  addAttribute(key: string, value: any) {
    this.attributes[key] = value;
    return this;
  }

}
