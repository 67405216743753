import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MostWineContactsComponent } from './most-wine-contacts.component';
import { MatIconModule } from '@angular/material/icon';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CtFrameworkModule } from '@ctsolution/ct-framework';


@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    FlexLayoutModule,
    CtFrameworkModule
  ],
  declarations: [MostWineContactsComponent],
  exports: [MostWineContactsComponent]
})
export class MostWineContactsModule { }
