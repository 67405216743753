import { AbstractControl, FormControl } from "@angular/forms";
import { MwCardRadioOptionConfiguration } from "./mw-card-radio-option/mw-card-radio-option.configuration";

export class MwCardRadioGroupConfiguration<T> {
  get class(): string | null {
    return this._class;
  }

  private set class(value: string | null) {
    this._class = value;
  }

  get control(): any {
    return this._control;
  }

  private set control(value: any) {
    this._control = value;
  }

  private _control: FormControl | AbstractControl | any | null = null;
  options: Array<MwCardRadioOptionConfiguration<T>> | null = null;
  private _class: string | null = null;

  public static create = <T>(): MwCardRadioGroupConfiguration<T> => new MwCardRadioGroupConfiguration<T>();

  setControl(value: FormControl | AbstractControl | any | null) {

    this.control = value;
    return this;

  }

  setOptions(value: Array<MwCardRadioOptionConfiguration<T>> | null) {

    this.options = value;
    return this;

  }

  setClass(value: string | null) {

    this.class = value;
    return this;

  }

  enableHorizontalView(value: boolean | null) {

    if (value) {

      this.setClass("horizontal");

    }

    return this;

  }

}
