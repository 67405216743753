<div mat-dialog-content>

  <app-model-detail
    *ngIf="depositModelDetail"
    [configuration]="depositModelDetail">
  </app-model-detail>

  <hr style="margin-top: 40px; margin-bottom: 5px;">

  <p> Di seguito sono riportati i dettagli del deposito che sta per subire un cambio di stato. </p>

  <div fxLayout="row wrap">

    <div fxFlex.gt-md="35" fxFlex="100">

      <mat-card>

        <mat-card-content>

          <app-deposit-state-change-note-history [model]="data"></app-deposit-state-change-note-history>

        </mat-card-content>

      </mat-card>

    </div>

    <div fxFlex.gt-md="65" fxFlex="100">

      <app-deposit-state-change-form #form></app-deposit-state-change-form>

    </div>

  </div>

</div>

<div mat-dialog-actions fxLayout="row" fxLayoutAlign="space-between center">

  <button
    class="general-button"
    mat-button
    mat-dialog-close> Annulla
  </button>

  <button
    class="general-button"
    mat-button
    (click)="confirm()"> Conferma
  </button>

</div>

