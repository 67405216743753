<div fxLayout="row wrap" fxFlex="100" fxDisplay="inline">
    <div class = "wine-stock-qty">
        {{"CT_SHIPMENT_DEPOSITS.no_packaging" | translate}}: <span class = "highlight-number">{{totaliGiacenza?.totaliGiacenzaVini[0].quantitaBottiglieTotale ?? 0}}</span>
    </div>
    <div class = "wine-stock-qty">
        {{"CT_GENERAL.boxes" | translate}}: <span class = "highlight-number">{{totaliGiacenza?.totaliGiacenzaVini[0].quantitaScatole ?? 0}}</span>
    </div>
    <div class = "wine-stock-qty">
        {{"CT_DATATABLE.SHIPMENT.BulkQuantity" | translate}}: <span class = "highlight-number">{{totaliGiacenza?.totaliGiacenzaVini[0].quantitaBottiglieSfuse ?? 0}}</span>
    </div>
</div>