import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ShippingPalletSelectionComponent } from "./shipping-pallet-selection.component";
import { MatIconModule } from "@angular/material/icon";
import { TranslateModule } from "@ngx-translate/core";
import { CtButtonModule } from "@ctsolution/ct-framework";
import { MatLegacyDialogModule as MatDialogModule } from "@angular/material/legacy-dialog";
import { FlexModule } from "@angular/flex-layout";
import { MatLegacyButtonModule } from "@angular/material/legacy-button";
import {
  PalletStockDatatableModule
} from "../../../stock/pallet-stock/pallet-stock-datatable/pallet-stock-datatable.module";

@NgModule({
  declarations: [
    ShippingPalletSelectionComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    TranslateModule,
    CtButtonModule,
    MatDialogModule,
    FlexModule,
    MatLegacyButtonModule,
    PalletStockDatatableModule
  ],
  exports: [
    ShippingPalletSelectionComponent
  ]
})
export class ShippingPalletSelectionModule {
}
