export class SetStatoDeposito {

  note: string | null = null;

  private constructor() {
  }

  public static create = () => new SetStatoDeposito();

  setNote(value: string | null) {

    this.note = value;
    return this;

  }

}
