import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { QtaControlComponent } from "./qta-control.component";
import { MatLegacyFormFieldModule as MatFormFieldModule } from "@angular/material/legacy-form-field";
import { TranslateModule } from "@ngx-translate/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatLegacyInputModule as MatInputModule } from "@angular/material/legacy-input";
import { CtControlModule } from "@ctsolution/ct-framework";

@NgModule({
  declarations: [
    QtaControlComponent
  ],
  imports: [
    CommonModule,
    MatFormFieldModule,
    TranslateModule,
    ReactiveFormsModule,
    MatInputModule,
    CtControlModule
  ],
  exports: [
    QtaControlComponent
  ]
})
export class QtaControlModule {
}
