<div class="mw-datatable-cntr">

  <ct-datatable
    #datatable
    *ngIf="configuration?.CTDatatableConfiguration.fetchDataCaller"
    [configuration]="configuration?.CTDatatableConfiguration"
    (onSave)="onSave.emit($event)"
    (onDetail)="onDetail.emit($event)"
    (onResetPagination)="onResetPagination()">

    <ng-template #CtTableLegend>

      <ng-container *ngTemplateOutlet="legendTemplate"></ng-container>

    </ng-template>

    <ng-template #CtTableBody let-value="value">

      <ng-container *ngTemplateOutlet="bodyTemplate; context: { value }"></ng-container>

    </ng-template>

    <ng-template #CtTableRowExpansion let-value="value">

      <ng-container *ngTemplateOutlet="rowExpansionTemplate; context: { value }"></ng-container>

    </ng-template>

  </ct-datatable>

</div>
