import { Component } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";

@Component({
  selector: "mw-date-range-picker",
  templateUrl: "./mw-date-range-picker.component.html",
  styleUrls: ["./mw-date-range-picker.component.scss"]
})
export class MwDateRangePickerComponent {

  range = new FormGroup({
    start: new FormControl<Date | null>(null, Validators.required),
    end: new FormControl<Date | null>(null, Validators.required)
  });


  setupInitialRange(startDate: Date, endDate: Date) {
    
    this.range
      .setValue({
        start: startDate,
        end: endDate
      });

    this.range.updateValueAndValidity();

  }

}
