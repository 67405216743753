import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { WineMovementsComponent } from "./wine-movements.component";
import { RouterModule } from "@angular/router";
import { MwDatatableModule } from "../../../components/mw-datatable/mw-datatable.module";
import { MwDateRangePickerModule } from "../../../components/mw-date-range-picker/mw-date-range-picker.module";

@NgModule({
  declarations: [
    WineMovementsComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild([
      {
        path: "",
        component: WineMovementsComponent,
        data: {
          title: "Movimentazioni vini"
        }
      }
    ]),
    MwDatatableModule,
    MwDateRangePickerModule
  ]
})
export class WineMovementsModule {
}
