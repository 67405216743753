<mat-card class="deposit-state-change-note-history-container">
  <mat-card-content>
    <ul class="timeline" *ngIf="(model?.cambiStato ?? []).length; else empty">
      <li
        #timelinePanel
        *ngFor="let cambiostato of model?.cambiStato; let odd = odd;"
        [class.timeline-inverted]="odd">
        <div class="timeline-panel">
          <div class="timeline-heading">

            <h4 class="timeline-title">
              <app-bullet-state [state]="cambiostato.stato"></app-bullet-state>
              {{ stateService.getStateLabel(cambiostato.stato) }}
            </h4>
            <p>
              <small class="text-muted">
                <i class="fa fa-clock-o"></i> {{ cambiostato.dataCambiamento | date }}</small
              >
            </p>
          </div>
          <div class="timeline-body" *ngIf="cambiostato.note">
            <p> {{ cambiostato.note }}</p>
          </div>
        </div>
      </li>
    </ul>
    <ng-template #empty>
      <p>Questo deposito non ha ancora subito variazioni.</p>
    </ng-template>
  </mat-card-content>
</mat-card>
