export class BaseRequestClass {

  controller: string[] | null = null;
  action: string | null = null;
  id: string | null = null;
  dataSource: any | null = null;
  depositanteId: string | null = null;
  bypassIdSetting: boolean = false;
  forcePut: boolean = false;

  private constructor() {
  }

  public static create = (): BaseRequestClass => new BaseRequestClass();

  setController(value: string[] | null): BaseRequestClass {

    this.controller = value;
    return this;

  }

  setId(value: string | null): BaseRequestClass {

    this.id = value;
    return this;

  }

  setAction(value: string | null): BaseRequestClass {

    this.action = value;
    return this;

  }

  setDataSource(value: any | null): BaseRequestClass {

    this.dataSource = value;
    return this;

  }

  setDepositanteId(value: string | null): BaseRequestClass {

    this.depositanteId = value;
    return this;

  }

}
