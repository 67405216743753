import { Component, ElementRef, Input, QueryList, ViewChild, ViewChildren } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { PackageComposerConfiguration } from "./package-composer.configuration";
import { ModalitaStoccaggio } from "../../../../../core/enum/modalita-stoccaggio.enum";
import { ConfirmService } from "../../../../../components/confirm/confirm.service";
import { QtaControlService } from "../../../../../components/qta-control/qta-control.service";
import { PackageComposerService } from "./package-composer.service";
import { HtmlService } from "../../../../../core/lib/html.service";
import { PackageTypeSelectionComponent } from "./package-type-selection/package-type-selection.component";
import { BoxComposerComponent } from "../box-composer/box-composer.component";
import { pairwise, startWith } from "rxjs";

@Component({
  selector: "app-package-composer",
  templateUrl: "./package-composer.component.html",
  styles: [`
    .package-composer-container {
      hr {
        width: 90%;
      }
    }
  `]
})
export class PackageComposerComponent {

  @Input() configuration: PackageComposerConfiguration | null = null;
  @ViewChildren("scrollReferenceElement") scrollReferenceElements: QueryList<ElementRef> | null = null;
  @ViewChild("packageTypeSelectionComponent") packageTypeSelectionComponent: PackageTypeSelectionComponent | null = null;
  @ViewChild("boxComposer") boxComposerComponent: BoxComposerComponent | null = null;

  form: FormGroup;
  packages: FormArray<FormGroup>;

  packageTypeName(modalitaStoccaggio: ModalitaStoccaggio | null = null): string {

    return this.packageComposerService.getPackageTypeTitleByDepositValue(modalitaStoccaggio ?? this.configuration?.depositPackagingType ?? null);

  }

  packageTypeIcon(modalitaStoccaggio: ModalitaStoccaggio | null = null): string {

    return this.packageComposerService.getPackageTypeIconByDepositValue(modalitaStoccaggio ?? this.configuration?.depositPackagingType ?? null);

  }

  get moreThanOnePackage() {

    return this.packages.length > 1;

  }

  get isPalletComposer(): boolean {

    return this.configuration?.depositPackagingType === ModalitaStoccaggio.Pallet;

  }

  constructor(
    private formBuilder: FormBuilder,
    private confirmService: ConfirmService,
    public qtaControlService: QtaControlService,
    private packageComposerService: PackageComposerService,
    private htmlService: HtmlService) {

    this.form = this.formBuilder
      .group({
        packages: this.formBuilder.array([])
      });

    this.packages = this.form.get("packages") as FormArray<FormGroup>;

  }

  ngAfterViewInit() {

    this.packageTypeSelectionComponent
      ?.control
      .valueChanges
      ?.pipe(
        startWith(null), // fornisce un valore iniziale (null) per includere il primo valore
        pairwise())
      .subscribe(async ([previous, current]) => this.addPackage(current));

  }

  addPackage(modalitaStoccaggio: ModalitaStoccaggio | null = null) {

    if (!this.packages.valid) {

      if (this.isPalletComposer) return;

      const lastIndex = this.packages.length - 1;

      const lastPackage = this.packages.at(lastIndex);

      if (lastPackage.invalid) this.packages.removeAt(lastIndex);

    }

    const formGroup = this.formBuilder
      .group({
        quantita: new FormControl(1, [Validators.required]),
        modalitaStoccaggio: new FormControl(modalitaStoccaggio)
      });

    this.packages
      .push(formGroup);

    setTimeout(() => {

      let lastElement = this.scrollReferenceElements?.last;
      this.htmlService.scrollToElement(lastElement);

    }, 0);

  }

  removePackage(index: number) {

    this.confirmService.removePackage(this.packages, index);

  }

  resetPackages() {

    this.packages.clear({ emitEvent: false });

    if (this.isPalletComposer) this.addPackage();

  }

}
