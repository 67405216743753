<form
  class="collection-form-container"
  *ngIf="packageForm"
  [formGroup]="packageForm">

  <p *ngIf="configuration?.formDescription"> {{ configuration?.formDescription ?? '' }}</p>

  <div [hidden]="!configuration?.needsTipologiaImballo">

    <div fxLayout="row wrap">

      <div fxFlex="auto" *ngIf="packageForm.get('tipologiaImballo')">

        <app-tipologia-imballo-select [control]="packageForm.get('tipologiaImballo')"></app-tipologia-imballo-select>

      </div>

      <div fxFlex="auto">

        <app-qta-control
          *ngIf="viewModel.quantitaControlConfiguration"
          [configuration]="viewModel.quantitaControlConfiguration"></app-qta-control>

      </div>

    </div>

  </div>

  <div fxLayout="row wrap">

    <div fxFlex="auto">

      <app-qta-control
        *ngIf="viewModel.pesoKgControlConfiguration"
        [configuration]="viewModel.pesoKgControlConfiguration"></app-qta-control>

    </div>

    <div fxFlex="auto">

      <app-qta-control
        *ngIf="viewModel.lunghezzaControlConfiguration"
        [configuration]="viewModel.lunghezzaControlConfiguration"></app-qta-control>

    </div>

    <div fxFlex="auto">

      <app-qta-control
        *ngIf="viewModel.larghezzaControlConfiguration"
        [configuration]="viewModel.larghezzaControlConfiguration"></app-qta-control>

    </div>

    <div fxFlex="auto">

      <app-qta-control
        *ngIf="viewModel.altezzaControlConfiguration"
        [configuration]="viewModel.altezzaControlConfiguration"></app-qta-control>

    </div>

    <button
      fxFlex="auto"
      *ngIf="deleteEnabled"
      mat-icon-button
      class="mw-icon-button remove"
      (click)="onRemove.emit()">
      <img height="35" [src]="'/assets/images/icons/delete.svg'">
    </button>

  </div>

</form>
