<div class="shipment-create-cntr">

  <div>

    <mat-card>

      <mat-card-content>

        <app-shipment-address-form
          #shipmentAddressFormComponent
          [configuration]="shipmentAddressFormConfiguration"
          (onUpdate)="updatedAddress($event)"></app-shipment-address-form>

      </mat-card-content>

    </mat-card>

  </div>

</div>
