import { CTDatatableFilterConfiguration, CTDatatableHeaderCellConfiguration } from "@ctsolution/ct-framework";
import { TranslateService } from "@ngx-translate/core";

export const nomeDestinatarioSpedizioneHeader = (translate: TranslateService) : CTDatatableHeaderCellConfiguration => CTDatatableHeaderCellConfiguration
  .create()
  .setField("destinatario.nome")
  .setLabel(translate.instant("CT_DATATABLE.SHIPMENT.Recipient"))
  .setInlineStyle("min-width: 400px;")
  .setSortable(true)
  .setFilter(
    CTDatatableFilterConfiguration
      .create()
      .setPlaceHolder(translate.instant("CT_DATATABLE.SHIPMENT.RecipientSearch"))
      .setShowMenu(true));

export const emailDestinatarioSpedizioneHeader = (translate: TranslateService) : CTDatatableHeaderCellConfiguration => CTDatatableHeaderCellConfiguration
  .create()
  .setField("destinatario.email")
  .setLabel(translate.instant("CT_DATATABLE.SHIPMENT.RecipientEmail"))
  .setInlineStyle("min-width: 300px;")
  .setSortable(true)
  .setFilter(
    CTDatatableFilterConfiguration
      .create()
      .setPlaceHolder(translate.instant("CT_DATATABLE.SHIPMENT.RecipientEmailSearch"))
      .setShowMenu(true));

export const dataCreazioneSpedizioneHeader = (translate: TranslateService) : CTDatatableHeaderCellConfiguration => CTDatatableHeaderCellConfiguration
  .create()
  .setField("dataCreazione")
  .setLabel(translate.instant("CT_DATATABLE.SHIPMENT.CreationDate"))
  .setInlineStyle("min-width: 250px;")
  .setSortable(true)
  .setFilter(
    CTDatatableFilterConfiguration
      .create()
      .setPlaceHolder(translate.instant("CT_DATATABLE.SHIPMENT.CreationDateSearch"))
      .setType("date")
      .setShowMenu(true));


export const dataConsegnaSpedizioneHeader = (translate: TranslateService) : CTDatatableHeaderCellConfiguration => CTDatatableHeaderCellConfiguration
  .create()
  .setField("dataConsegna")
  .setLabel(translate.instant("CT_DATATABLE.SHIPMENT.DeliveryDate"))
  .setInlineStyle("min-width: 300px;")
  .setSortable(true)
  .setFilter(
    CTDatatableFilterConfiguration
      .create()
      .setPlaceHolder(translate.instant("CT_DATATABLE.SHIPMENT.DeliveryDateSearch"))
      .setType("date")
      .setShowMenu(true));

export const indirizzoSpedizioneHeader = (translate: TranslateService) : CTDatatableHeaderCellConfiguration => CTDatatableHeaderCellConfiguration
  .create()
  .setField("destinatario.indirizzo.via")
  .setLabel(translate.instant("CT_DATATABLE.SHIPMENT.RecipientAddress"))
  .setSortable(true)
  .setInlineStyle("min-width: 350px;")
  .setFilter(
    CTDatatableFilterConfiguration
      .create()
      .setPlaceHolder(translate.instant("CT_DATATABLE.SHIPMENT.RecipientAddressSearch"))
      .setShowMenu(true));

export const codiceSpedizioneHeader = (translate: TranslateService) : CTDatatableHeaderCellConfiguration => CTDatatableHeaderCellConfiguration
  .create()
  .setField("codice")
  .setLabel(translate.instant("CT_DATATABLE.SHIPMENT.Code"))
  .setInlineStyle("min-width: 150px;")
  .setSortable(true)
  .setFilter(
    CTDatatableFilterConfiguration
      .create()
      .setPlaceHolder(translate.instant("CT_DATATABLE.SHIPMENT.CodeSearch"))
      .setShowMenu(true));

export const contenutoSpedizioneHeader = (translate: TranslateService) : CTDatatableHeaderCellConfiguration => CTDatatableHeaderCellConfiguration
  .create()
  .setField("contenuto")
  .setLabel(translate.instant("CT_DATATABLE.SHIPMENT.Contents"))
  .setInlineStyle("min-width: 300px;");
