import { Component, ViewChild } from "@angular/core";
import { QueriesService } from "../../../../../core/lib/queries.service";
import { GenericReadModel } from "../../../../../core/classes/generic-read.model";
import { MwLookupControlComponent } from "../../../../../components/mw-lookup-control/mw-lookup-control.component";
import {
  MwLookupControlConfiguration
} from "../../../../../components/mw-lookup-control/mw-lookup-control.configuration";
import { MenzioniEsteroApolloResult } from "../../../../../core/interfaces/apollo/menzioni-estero.apollo-result";

@Component({
  selector: "mw-menzione-estero-control",
  template: `
    <mw-lookup-control
      #lookupControl
      [configuration]="configuration"></mw-lookup-control>`
})
export class MenzioneEsteroControlComponent {

  @ViewChild("lookupControl") lookupControl: MwLookupControlComponent<GenericReadModel, MenzioniEsteroApolloResult> | null = null;

  configuration: MwLookupControlConfiguration<GenericReadModel, MenzioniEsteroApolloResult> = MwLookupControlConfiguration
    .create<GenericReadModel, MenzioniEsteroApolloResult>()
    .setQuery(this._queries.entities.menzioniEstero)
    .setResponseMapper(value => value.menzioniEstero)
    .setOptionDescriptionFn(value => value?.nome ?? "Unknown")
    .setAutoInitializeValueOptions(false)
    .setOrder([{ nome: "ASC" }]);

  constructor(private _queries: QueriesService) {
  }

  setupLabelByCountry(value: GenericReadModel | null = null) {

    let label = "Menzione estero";

    const mappings: { [key: string]: string } = {
      "francia": "Menzione vigna",
      "spagna": "Menzione uva"
    };

    if (value?.nome) {

      const nomeLowerCase = value.nome.toLowerCase();

      for (const keyword in mappings) {

        if (nomeLowerCase.includes(keyword)) {

          label = mappings[keyword];
          break;

        }

      }

    }

    this.configuration
      .configureControl(control => {

        control
          ?.setLabel(label);

      });

    return this;

  }

}
