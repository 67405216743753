import { Component, Input, ViewChild } from "@angular/core";
import { MwDatatableComponent } from "../../../components/mw-datatable/mw-datatable.component";
import { IndirizzoSpedizioneModel } from "../../../core/classes/spedizione/indirizzo";
import { MwDatatableConfiguration } from "../../../components/mw-datatable/mw-datatable.configuration";
import { IndirizziSpedizioneApolloResult } from "../../../core/interfaces/apollo/indirizzi-spedizione.apollo-result";
import { ApolloVariableClass } from "../../../core/classes/apollo/apollo.variable";
import { BaseApolloQueryClass } from "../../../core/classes/apollo/apollo.base.query";
import {
  CTDatatableButtonConfiguration,
  CTDatatableConfiguration,
  CTDatatablePermissions
} from "@ctsolution/ct-framework";
import { QueriesService } from "../../../core/lib/queries.service";
import { BaseController } from "../../../core/controllers/base.controller";
import { ShipmentAddressFormService } from "../../../components/shipment-address-form/shipment-address-form.service";
import { destinazioneHeader, indirizzoHeader, nomeDestinatarioHeader, statoHeader } from "./addresses-datatable.headers";
import { AddressesDatatableConfiguration } from "./addresses-datatable.configuration";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-addresses-datatable",
  templateUrl: "./addresses-datatable.component.html",
  styles: [`::ng-deep .addresses-datatable-container {
    .mw-datatable-cntr {
      min-width: 745px
    }

    @media screen and (max-width: 883px) {
      .mw-datatable-cntr {
        min-width: auto
      }
    }
  }`
  ]
})
export class AddressesDatatableComponent {

  @ViewChild("table") table: MwDatatableComponent<IndirizzoSpedizioneModel | null> | null = null;
  @Input() configuration: AddressesDatatableConfiguration | null = null;

  listConfiguration: MwDatatableConfiguration<IndirizziSpedizioneApolloResult> | null = MwDatatableConfiguration
    .create<IndirizziSpedizioneApolloResult>()
    .setFetchDataCaller(async (variables: ApolloVariableClass) => {

      let query = await this._queries.entities.spedizione();

      if (!query) return null;

      const parameter = BaseApolloQueryClass
        .create()
        .setVariables(variables)
        .setQuery(query);

      if (this.configuration?.destinazione) {

        const indexdestinazione = variables.whereAndFilters?.findIndex(item => item.destinazione);

        if (indexdestinazione) {

          const destinazioneFilter = {
            destinazione: {
              id: {
                eq: this.configuration.destinazione.id
              }
            }
          };

          if (indexdestinazione !== -1) {
            // Modifica il campo desiderato se l'oggetto "destinazione" è presente
            (variables.whereAndFilters ?? [])[indexdestinazione] = destinazioneFilter;

          } else {

            // Aggiungi un nuovo oggetto "destinazione" all'array se non presente
            (variables.whereAndFilters ?? []).push(destinazioneFilter);

          }

        }

      }

      return this.baseController.list<IndirizziSpedizioneApolloResult>(parameter);

    })
    .setFetchResponseDataMapper((value: IndirizziSpedizioneApolloResult) => value.indirizziSpedizione)
    .setCTDatatableConfiguration(
      CTDatatableConfiguration
        .create<IndirizziSpedizioneApolloResult>()
        .setEmptyMessage("Nessun indirizzo disponibile")
        .setLoadingBody("Caricamento indirizzi. Attendere prego.")
        .setSearchInputPlaceholder(this.translate.instant("CT_GENERAL.Search"))
        .setPermissions([CTDatatablePermissions.insert, CTDatatablePermissions.update])
        .setCreateButtonConfiguration(
          CTDatatableButtonConfiguration
            .create()
            .setLabel(this.translate.instant("CT_DATATABLE.CLIENTRECORDS.NewAddress")))
        .setSortField("destinazione.nomeRiferimento")
        .setSortOrder(-1)
    );

  constructor(
    private _queries: QueriesService,
    private baseController: BaseController,
    private shipmentAddressFormService: ShipmentAddressFormService,
    private translate: TranslateService
  ) {
  }

  get isdestinazioneDetail() {

    return !!this.configuration?.destinazione;

  }

  ngAfterViewInit() {
    

    const columns = [
      nomeDestinatarioHeader(this.translate),
      indirizzoHeader(this.translate),
      statoHeader(this.translate)
    ];

    if (!this.isdestinazioneDetail) {

      columns
        .unshift(destinazioneHeader);

    }

    this.listConfiguration
      ?.CTDatatableConfiguration
      ?.setHeaders(columns);

  }

  async onSave(event: IndirizzoSpedizioneModel | null) {

    if (this.configuration?.destinazione && !event?.destinazione) {

      if (!event) event = {} as IndirizzoSpedizioneModel;

      event["destinazione"] = this.configuration.destinazione;

    }

    this.shipmentAddressFormService
      .setupShipmentAddress(event)
      .then(success => {

        if (success) {

          this.table
            ?.reinit();

        }

      });

  }

}
