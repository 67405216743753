import { Component, ViewChild } from "@angular/core";
import { ChartComponent } from "ng-apexcharts";
import { BaseController } from "../../../core/controllers/base.controller";
import { QueriesService } from "../../../core/lib/queries.service";
import { TranslateService } from "@ngx-translate/core";
import { CtCardConfiguration, CtCardTemplate } from "@ctsolution/ct-framework";
import { ChartOptions } from "../../../core/interfaces/chart-options";
import { GenericReadModel } from "../../../core/classes/generic-read.model";
import { ApolloVariableClass } from "../../../core/classes/apollo/apollo.variable";
import { ViniApolloResult } from "../../../core/interfaces/apollo/vini.apollo-result";
import { BaseApolloQueryClass } from "../../../core/classes/apollo/apollo.base.query";
import { TipologieApolloResult } from "../../../core/interfaces/apollo/tipologie.apollo-result";

@Component({
  selector: "app-wine-types-widget",
  templateUrl: "./wine-types-widget.component.html"
})
export class WineTypesWidgetComponent {

  @ViewChild("winesChart") chart2: ChartComponent = Object.create(null);
  public WineChartOptions: Partial<ChartOptions>;

  PRODUCT_COUNTER: CtCardConfiguration = CtCardConfiguration.create()
    .setTitle("CT_PAGES.WINES")
    .setTemplate(CtCardTemplate.COUNTER)
    .setClass("bg-dashboard-tertiary text-white");

  constructor(
    private baseController: BaseController,
    private _queries: QueriesService,
    private translate: TranslateService
  ) {

    this.WineChartOptions = {
      series: [],
      chart: {
        width: 320,
        type: "donut"
      },
      dataLabels: {
        enabled: false
      },
      fill: {
        type: "gradient"
      },
      tooltip: {
        fillSeriesColor: false
      },
      stroke: {
        width: 0
      },
      legend: {
        show: false
      },
      labels: [],
      colors: ["#B2AA8E", "#3973D0", "#C05DAE", "#03DDCF", "#DBFE87", "#BC4E4E"],
      responsive: [
        {
          breakpoint: 767,
          options: {
            chart: {
              width: 200
            }
          }
        }
      ]
    };
  }

  ngOnInit() {

    this.setupPieChart(); // ora la regione è obbligatoria

  }

  async setupPieChart() {

    const query = await this._queries.entities.tipologie();

    if (!query) return null;

    const parameter = BaseApolloQueryClass
      .create()
      .setQuery(query);

    const caller = await this.baseController.list<TipologieApolloResult>(parameter);

    if (!caller) return null;

    caller
      ?.subscribe(result => {

        const tipiVino = result.tipologie ?? [];

        this.WineChartOptions
          .labels = [];

        tipiVino
          .forEach((elm: GenericReadModel, index: number) => this.setupPieChartValueByType(elm, index === (tipiVino.length - 1)));

      });

  }

  async setupPieChartValueByType(value: GenericReadModel, last: boolean = false) {

    const variables: ApolloVariableClass = ApolloVariableClass
      .create();

    if (value.nome) {

      variables.setSearchText(value.nome);

    }

    const query = await this._queries.entities.vini();

    if (!query) return;

    const parameter = BaseApolloQueryClass
      .create()
      .setVariables(variables)
      .setQuery(query);

    const caller = await this.baseController
      .list<ViniApolloResult>(parameter);

    if (!caller) return;

    caller
      .subscribe(result => {

        this.WineChartOptions
          .labels.push(value.nome);

        this.WineChartOptions
          .series?.push(result.vini?.totalCount ?? 0);

        if (last) {

          const total: number = (this.WineChartOptions.series ?? []).reduce((partialSum, a) => partialSum + a, 0);
          const remaining: number = +(this.PRODUCT_COUNTER.description ?? 0) - total;

          this.translate
            .get("DASHBOARD.other")
            .subscribe(k => {

              this.WineChartOptions
                .series
                ?.push(remaining);

              this.WineChartOptions
                .labels
                .push(k);

              this.chart2.updateSeries(this.WineChartOptions.series ?? []);

            });

        }

      });

  }

}
