import { ChangeDetectorRef, Component, Input, ViewChild } from "@angular/core";
import { DepositCollectionFormComponentConfiguration } from "./deposit-collection-form-component.configuration";
import { DepositType } from "../../../../../core/enum/deposit-type";
import {
  CtControlConfiguration,
  CtControlTypes,
  CtControlValidator,
  CtDateControlOptions,
  CtThemeTypes
} from "@ctsolution/ct-framework";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import {
  ShipmentAddressFormComponent
} from "../../../../../components/shipment-address-form/shipment-address-form.component";
import {
  ShipmentAddressFormConfiguration
} from "../../../../../components/shipment-address-form/shipment-address-form.configuration";
import { NoteControlComponent } from "../../../../../components/note-control/note-control.component";
import { DepositorService } from "../../../../../core/lib/depositor.service";
import { DepositanteApolloResult } from "../../../../../core/interfaces/apollo/depositante.apollo-result";
import { BaseController } from "../../../../../core/controllers/base.controller";
import { QueriesService } from "../../../../../core/lib/queries.service";

@Component({
  selector: "app-deposit-collection-form",
  templateUrl: "./deposit-collection-form.component.html"
})
export class DepositCollectionFormComponent {

  @Input() configuration: DepositCollectionFormComponentConfiguration | null = null;
  @ViewChild("shipmentAddressFormComponent") shipmentAddressFormComponent: ShipmentAddressFormComponent | null = null;
  @ViewChild("depositNoteComponent") depositNoteComponent: NoteControlComponent | null = null;

  form: FormGroup;

  dataRitiroControl: CtControlConfiguration = CtControlConfiguration
    .create()
    .setName("dataRitiro")
    .setControl(new FormControl(null))
    .setTheme(CtThemeTypes.MATERIAL)
    .setType(CtControlTypes.DATE)
    .setValidators([CtControlValidator.create({ name: "required" } as CtControlValidator)]);

  shipmentAddressFormConfiguration: ShipmentAddressFormConfiguration = ShipmentAddressFormConfiguration
    .create()
    .enableCustomerSelection(false)
    .enableSavingAction(false)
    .setEnabledDisabled(false);

  constructor(
    private cdr: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private baseController: BaseController,
    private _queries: QueriesService,
    private depositorService: DepositorService) {

    this.form = this.formBuilder.group({});

  }

  get isRitiro() {

    return this.configuration?.depositType === DepositType.most;

  }

  ngAfterViewInit() {

    const minDate = new Date();

    minDate
      .setDate(minDate.getDate() + 1);

    (<CtDateControlOptions>this.dataRitiroControl
      .options)
      .setMin(minDate);

    this.dataRitiroControl
      .setFormParent(this.form)
      .setValue(minDate);

    this.form
      .addControl("note", this.depositNoteComponent?.control);

    this.shipmentAddressFormConfiguration
      .setTitle("CT_SHIPMENT_DEPOSITS.pickupAddress");

    this.cdr.detectChanges();

  }


  async getDepositorData() {

    const depositanteId = await this.depositorService.getDepositor();

    const query = await this._queries.entities.depositante();

    if (!query) return null;

    return await this.baseController.get<DepositanteApolloResult>(depositanteId, query);

  }

  async setup() {

    this.setDataRitiroLabel();

    if (this.isRitiro) {

      this.form
        .addControl("shipmentAddressData", this.shipmentAddressFormComponent?.form);

      const fetcher = await this.getDepositorData();

      fetcher
        ?.subscribe((response: any) => {

          const depositante = response.depositante;

          this.shipmentAddressFormComponent
            ?.setupValues({
              nomeDestinatario: depositante.nomeRiferimento,
              telefonoDestinatario: depositante.telefono,
              emailDestinatario: depositante.email,
              ...depositante
            });

        });

    } else {

      this.form
        .removeControl("shipmentAddressData");

    }

    this.cdr.detectChanges();

  }

  setDataRitiroLabel() {

    let label;

    switch (this.configuration?.depositType) {

      case DepositType.most:
        label = "CT_SHIPMENT_DEPOSITS.pickupDate";
        break;
      case DepositType.autonoma:
      default:
        label = "CT_SHIPMENT_DEPOSITS.estimatedDeliveryDate";
        break;

    }

    this.dataRitiroControl.setLabel(label);

  }

}
