import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { WineStockDatatableComponent } from "./wine-stock-datatable.component";
import { MwDatatableModule } from "../../../../components/mw-datatable/mw-datatable.module";
import { RippleModule } from "primeng/ripple";
import { ButtonModule } from "primeng/button";
import {
  SectionDetailDataModule
} from "../../../../components/model-detail/section-detail-data/section-detail-data.module";
import { WineStockShipmentTypeComponent } from "./wine-stock-shipment-type/wine-stock-shipment-type.component";
import { MwCardRadioGroupModule } from "../../../../components/mw-card-radio-group/mw-card-radio-group.module";
import {
  WineStockShipmentTypeDialogComponent
} from "./wine-stock-shipment-type/wine-stock-shipment-type-dialog.component";
import { CtButtonModule } from "@ctsolution/ct-framework";
import { FlexModule } from "@angular/flex-layout";
import { MatIconModule } from "@angular/material/icon";
import { MatLegacyButtonModule } from "@angular/material/legacy-button";
import { MatLegacyDialogModule } from "@angular/material/legacy-dialog";
import { TranslateModule } from "@ngx-translate/core";


@NgModule({
  declarations: [
    WineStockDatatableComponent,
    WineStockShipmentTypeComponent,
    WineStockShipmentTypeDialogComponent
  ],
  exports: [
    WineStockDatatableComponent
  ],
  imports: [
    CommonModule,
    MwDatatableModule,
    RippleModule,
    ButtonModule,
    SectionDetailDataModule,
    MwCardRadioGroupModule,
    CtButtonModule,
    FlexModule,
    MatIconModule,
    MatLegacyButtonModule,
    MatLegacyDialogModule,
    TranslateModule
  ]
})
export class WineStockDatatableModule {
}