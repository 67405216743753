<div class="wine-types-widget-cntr">
    <div class="d-flex flex-column dashboard-card p-t-30">
        <apx-chart #winesChart
                   [series]="WineChartOptions.series"
                   [chart]="WineChartOptions.chart"
                   [labels]="WineChartOptions.labels"
                   [responsive]="WineChartOptions.responsive"
                   [tooltip]="WineChartOptions.tooltip"
                   [legend]="WineChartOptions.legend"
                   [colors]="WineChartOptions.colors"
                   [dataLabels]="WineChartOptions.dataLabels"
                   [plotOptions]="WineChartOptions.plotOptions"
                   [stroke]="WineChartOptions.stroke">
        </apx-chart>

    </div>

    <ul class="list-inline wine-legend text-center m-t-30 m-b-30">

        <li *ngFor="let wineOption of WineChartOptions.labels; let i = index;">
            <h6 class="text m-0" [class]="'text-wine-' + (i + 1)">
                <i class="fa fa-circle font-10 m-r-10"></i> {{ wineOption }}
            </h6>
        </li>

    </ul>
</div>


