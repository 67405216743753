import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DepositCreateStep3Component } from "./deposit-create-step3.component";
import { ReactiveFormsModule } from "@angular/forms";
import { MatStepperModule } from "@angular/material/stepper";
import { MatLegacyButtonModule } from "@angular/material/legacy-button";
import { TranslateModule } from "@ngx-translate/core";
import { ShippingMethodSelectorModule } from "./shipping-methods-selector/shipping-method-selector.module";

@NgModule({
  declarations: [
    DepositCreateStep3Component
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatStepperModule,
    MatLegacyButtonModule,
    TranslateModule,
    ShippingMethodSelectorModule
  ],
  exports: [
    DepositCreateStep3Component
  ]
})
export class DepositCreateStep3Module {
}
