import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {WineTypesWidgetComponent} from "./wine-types-widget.component";
import {NgApexchartsModule} from "ng-apexcharts";
import {TranslateModule} from "@ngx-translate/core";




@NgModule({
  declarations: [
    WineTypesWidgetComponent
  ],
  imports: [
    CommonModule,
    NgApexchartsModule,
      TranslateModule
  ],
  exports: [WineTypesWidgetComponent]
})
export class WineTypesWidgetModule { }
