import { Component, Input } from "@angular/core";
import { SectionDetailDataConfiguration } from "./section-detail-data.configuration";

@Component({
  selector: "app-section-detail-data",
  template: `
    <div class="section-detail-cntr">

      <h3
        class="section-detail-title"
        *ngIf="configuration?.title"
        [innerHTML]="configuration?.title | translate"></h3>

      <div class="section-detail-content" fxLayout="row wrap">

        <div
          *ngFor="let detailData of (configuration?.dataList ?? [])"
          [fxFlex]="detailData.fxFlex"
          [fxFlex.gt-sm]="detailData.fxLayout?.sm"
          [fxFlex.gt-md]="detailData.fxLayout?.md"
          [fxFlex.gt-lg]="detailData.fxLayout?.lg">

          <ct-card
            *ngIf="detailData?.cardConfiguration"
            [configuration]="detailData?.cardConfiguration"></ct-card>

        </div>

      </div>

    </div>
    <hr *ngIf="configuration?.endSectionDividerEnabled" />
  `,
  styles: [
    `::ng-deep .section-detail-cntr {
      max-height: 300px;
      overflow: auto;

      h3.section-detail-title {
        margin: 10px 0px !important;
        font-size: 20px;
      }

      h3:not(.section-detail-title) {
        font-size: 18px;
      }

      .mat-card {
        box-shadow: none !important;
        margin: 0px !important;
        margin-bottom: 5px !important;

        .mat-fab .mat-button-wrapper {

          padding: 0px !important;
        }

        .mat-card-content {
          padding-bottom: 0px;
        }

        h3, .mat-icon {
          color: var(--bordeaux)
        }

        h6 {
          font-weight: 300
        }
      }
    }`
  ]
})
export class SectionDetailDataComponent {

  @Input() configuration: SectionDetailDataConfiguration | null = null;

}


