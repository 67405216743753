import { ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { ChartComponent } from "ng-apexcharts";
import { ChartOptions } from "chart.js";
import { BaseController } from "../../../core/controllers/base.controller";
import { DepositorService } from "../../../core/lib/depositor.service";
import { QueriesService } from "../../../core/lib/queries.service";
import { ApolloVariableClass } from "../../../core/classes/apollo/apollo.variable";
import { ClientiApolloResult } from "../../../core/interfaces/apollo/clienti.apollo-result";
import { BaseApolloQueryClass } from "../../../core/classes/apollo/apollo.base.query";
import { TranslateService } from '@ngx-translate/core';
import { TipodestinazioneEnum } from "src/app/core/enum/tipo-destinazione.enum";

@Component({
  selector: "app-customers-counter-widget",
  templateUrl: "./customers-counter-widget.component.html"
})
export class CustomersCounterWidgetComponent {

  @ViewChild("chart") chart: ChartComponent = Object.create(null);
  public chartOptions: Partial<ChartOptions> | any | null = null;

  constructor(
    private depositorService: DepositorService,
    private baseController: BaseController,
    private _queries: QueriesService,
    private cdr: ChangeDetectorRef,
    private translate: TranslateService
  ) {
  }

  ngAfterViewInit() {

    this.setup();

  }

  async setup() {

    const depositanteId = await this.depositorService.getDepositor();
    const clienti_query = await this._queries.entities.destinazioni();

    const chartOptions: any = {
      series: [
        { name: "CardChart_Business", data: [] },
        { name: "CardChart_Private", data: [] }
      ],
      chart: {
        type: "bar",
        fontFamily: "Poppins,sans-serif",
        height: 347
      },
      xaxis: {
        categories: []
      }
    };

    if (clienti_query) {
      this.translate.get('CardChart_MOST_Clients').subscribe(translation => {
        chartOptions.xaxis.categories = [translation]; 
        this.cdr.detectChanges();
        this.translate.get(['CardChart_Business', 'CardChart_Private']).subscribe(translations => {
          chartOptions.series[0].name = translations['CardChart_Business'];
          chartOptions.series[1].name = translations['CardChart_Private'];
          this.cdr.detectChanges(); 
        });
      });

      const variables = ApolloVariableClass.create().setDepositanteId(depositanteId);
      const clientiParameter = BaseApolloQueryClass.create().setVariables(variables).setQuery(clienti_query);

      const tipodestinazioneArray = [TipodestinazioneEnum.destinazioneBusiness, TipodestinazioneEnum.destinazionePrivate];

      for (let i = 0; i < tipodestinazioneArray.length; i++) {

        clientiParameter.variables.setTipodestinazione(tipodestinazioneArray[i]);
        const clientiCaller = await this.baseController.list<ClientiApolloResult>(clientiParameter);

        if (clientiCaller) {

          clientiCaller
            .subscribe(response => {

              chartOptions.series[i].data.push(response.destinazioni.totalCount);
              this.cdr.detectChanges(); // Forza la change detection

              if (i === tipodestinazioneArray.length - 1) {

                this.chartOptions = chartOptions;
                // this.chart.render();
              }
            });
        }
      }
    }
  }
}
