import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CustomerFormComponent } from "./customer-form.component";
import { MatLegacyDialogModule } from "@angular/material/legacy-dialog";
import { FlexModule } from "@angular/flex-layout";
import { MatIconModule } from "@angular/material/icon";
import { MatLegacyButtonModule } from "@angular/material/legacy-button";
import {
  AddressDataFormModule
} from "../../../components/shipment-address-form/address-data-form/address-data-form.module";
import { CtButtonModule, CtFormModule } from "@ctsolution/ct-framework";
import {
  CustomerAddressSelectControlModule
} from "../../../components/shipment-address-form/customer-address-select-control/customer-address-select-control.module";
import {
  CustomerLookupControlModule
} from "../../../components/shipment-address-form/customer-lookup-control/customer-lookup-control.module";
import { ReactiveFormsModule } from "@angular/forms";
import { PaeseControlModule } from "../../../components/paese-control/paese-control.module";
import { CustomerDataFormComponent } from "./customer-data-form/customer-data-form.component";
import { SharedModule } from "primeng/api";
import { TabViewModule } from "primeng/tabview";
import { AddressesDatatableModule } from "../../addresses/addresses-datatable/addresses-datatable.module";
import { TranslateModule } from "@ngx-translate/core";

@NgModule({
  declarations: [
    CustomerFormComponent,
    CustomerDataFormComponent
  ],
  imports: [
    CommonModule,
    MatLegacyDialogModule,
    FlexModule,
    MatIconModule,
    MatLegacyButtonModule,
    AddressDataFormModule,
    CtButtonModule,
    CustomerAddressSelectControlModule,
    CustomerLookupControlModule,
    ReactiveFormsModule,
    PaeseControlModule,
    CtFormModule,
    SharedModule,
    TabViewModule,
    AddressesDatatableModule,
    TranslateModule.forChild()
  ]
})
export class CustomerFormModule {
}
