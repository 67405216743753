import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DepositCreateStep1Component } from "./deposit-create-step1.component";
import { ReactiveFormsModule } from "@angular/forms";
import { MatStepperModule } from "@angular/material/stepper";
import { MatLegacyButtonModule } from "@angular/material/legacy-button";
import { TranslateModule } from "@ngx-translate/core";
import { DepositPackagingSelectorModule } from "./deposit-packaging-selector/deposit-packaging-selector.module";

@NgModule({
  declarations: [
    DepositCreateStep1Component
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatStepperModule,
    MatLegacyButtonModule,
    TranslateModule,
    DepositPackagingSelectorModule
  ],
  exports: [
    DepositCreateStep1Component
  ]
})
export class DepositCreateStep1Module {
}
