import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormArray, FormGroup } from "@angular/forms";
import { DepositCollectionPackagingConfiguration } from "../deposit-collection-packaging.configuration";
import { DepositCollectionPackagingService } from "../deposit-collection-packaging.service";

@Component({
  selector: "app-collection-default-packaging",
  templateUrl: "./collection-default-packaging.component.html"
})
export class CollectionDefaultPackagingComponent {

  @Input() collection: FormArray<FormGroup> | null = null;
  @Input() configuration: DepositCollectionPackagingConfiguration | null = null;
  @Output() onAdd: EventEmitter<any> = new EventEmitter<any>();
  @Output() onRemove: EventEmitter<number> = new EventEmitter<number>();

  constructor(public depositCollectionPackagingService: DepositCollectionPackagingService) {
  }

  addCollection() {

    this.onAdd.emit();

  }

}
