import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FlexModule } from "@angular/flex-layout";
import { TranslateModule } from "@ngx-translate/core";
import { PalletStockCounterComponent } from "./pallet-stock-counter.component";

@NgModule({
    declarations:[PalletStockCounterComponent],
    
    imports: [
        CommonModule,
        FlexModule,
        TranslateModule
],
exports:[PalletStockCounterComponent]
})
export class PalletStockCounterModule{}