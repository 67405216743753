import { CtCardConfiguration, CtCardIconConfiguration } from "@ctsolution/ct-framework";

const standardFxLayout = {
  sm: 100,
  md: 50,
  lg: 33.33
};

export class DetailDataConfiguration {

  cardConfiguration: CtCardConfiguration | null = null;
  fxFlex: number | null = 100;
  fxLayout: {
    sm: number | null,
    md: number | null,
    lg: number | null
  } | null = standardFxLayout;

  private constructor() {
  }

  public static create = (): DetailDataConfiguration => new DetailDataConfiguration();

  setCTCardConfiguration(value: CtCardConfiguration | null) {

    this.cardConfiguration = value;
    return this;

  }

  setCTCardConfigurationFromDetailDataModel(title: string, description: any, icon: string | null = null) {

    const cardConfiguration = CtCardConfiguration
      .create()
      .setTitle(title)
      .setDescription(description)

    if (icon) {

      cardConfiguration
        ?.setIconSetting(
          CtCardIconConfiguration
            .create()
            .setIcon(icon));

    }

    return this.setCTCardConfiguration(cardConfiguration);

  }

  setFxFlex(value: number | null) {

    this.fxFlex = value;
    return this;

  }

  setFxLayout(value: {
    sm: number | null,
    md: number | null,
    lg: number | null
  } | null) {

    this.fxLayout = value;
    return this;

  }

  setFxLayoutSm(value: number | null) {

    if (!this.fxLayout) {

      this.resetFxLayout();

    }

    this.fxLayout!.sm = value;
    return this;

  }

  resetFxLayout() {

    this.setFxLayout(standardFxLayout);
  }

}

