import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ConfirmComponent } from "./confirm.component";
import { ConfirmService } from "./confirm.service";
import { MatLegacyDialogModule as MatDialogModule } from "@angular/material/legacy-dialog";
import { TranslateModule } from "@ngx-translate/core";
import { CtButtonModule } from "@ctsolution/ct-framework";
import { FlexLayoutModule, FlexModule } from "@angular/flex-layout";
import { MatIconModule } from "@angular/material/icon";
import { MatLegacyButtonModule } from "@angular/material/legacy-button";

@NgModule({
  declarations: [
    ConfirmComponent
  ],
  imports: [
    CommonModule,
    MatDialogModule,
    TranslateModule,
    CtButtonModule,
    FlexModule,
    FlexLayoutModule,
    MatIconModule,
    MatLegacyButtonModule
  ],
  exports: [ConfirmComponent],
  providers: [ConfirmService]
})
export class ConfirmModule {
}
