import { TypedDocumentNode } from "@apollo/client";
import { ApolloFilters } from "../../core/interfaces/apollo/apollo.filters";
import {
  CtControlConfiguration,
  CtControlTypes,
  CtThemeTypes
} from "@ctsolution/ct-framework";
import { ApolloOrderInfo } from "../../core/interfaces/apollo/apollo.order-info";

export class MwLookupControlConfiguration<T, R> {

  get queryDisabled(): boolean {
    return this._queryDisabled;
  }

  private set queryDisabled(value: boolean) {
    this._queryDisabled = value;
  }

  get order(): Array<ApolloOrderInfo> {
    return this._order;
  }

  private set order(value: Array<ApolloOrderInfo>) {
    this._order = value;
  }

  get mwResponseMapper(): ((value: R) => T[]) | null {
    return this._mwResponseMapper;
  }

  private set mwResponseMapper(value: ((value: R) => T[]) | null) {
    this._mwResponseMapper = value;
  }

  get whereAndFilters(): Array<ApolloFilters> | null {
    return this._whereAndFilters;
  }

  set whereAndFilters(value: Array<ApolloFilters> | null) {
    this._whereAndFilters = value;
  }

  get optionDescriptionFn(): (value: T) => string {
    return this._optionDescriptionFn;
  }

  private set optionDescriptionFn(value: (value: T) => string) {
    this._optionDescriptionFn = value;
  }

  get query(): () => Promise<TypedDocumentNode | null> {
    return this._query;
  }

  private set query(value: () => Promise<TypedDocumentNode | null>) {
    this._query = value;
  }

  get autoInitializeValueOptions(): boolean {
    return this._autoInitializeValueOptions;
  }

  private set autoInitializeValueOptions(value: boolean) {
    this._autoInitializeValueOptions = value;
  }

  private _query: () => Promise<TypedDocumentNode | null> = () => Promise.resolve(null);
  private _optionDescriptionFn: (value: T) => string = (value: T) => (<any>value).descrizione;
  private _whereAndFilters: Array<ApolloFilters> | null = [];
  private _order: Array<ApolloOrderInfo> = [];
  private _autoInitializeValueOptions: boolean = true;
  private _mwResponseMapper: ((value: R) => T[]) | null = null;
  private _queryDisabled: boolean = false;

  CTControl = CtControlConfiguration
    .create()
    .setTheme(CtThemeTypes.MATERIAL)
    .setType(CtControlTypes.ENUMERABLE);

  constructor() {
  }

  public static create = <T, R>(): MwLookupControlConfiguration<T, R> => new MwLookupControlConfiguration<T, R>();

  setWhereAndFilters(value: Array<ApolloFilters> | null) {

    this.whereAndFilters = value;
    return this;

  }

  setResponseMapper(value: (value: R) => T[]) {

    this.mwResponseMapper = value;
    return this;

  }

  setQuery(value: () => Promise<TypedDocumentNode | null>) {

    this.query = value;
    return this;

  }

  setAutoInitializeValueOptions(value: boolean) {

    this.autoInitializeValueOptions = value;
    return this;

  }

  setQueryDisabled(value: boolean) {

    this.queryDisabled = value;
    return this;

  }

  setOptionDescriptionFn(value: (value: T) => string) {

    this.optionDescriptionFn = value;
    return this;

  }

  setOrder(value: Array<ApolloOrderInfo> = []) {

    this.order = value;
    return this;

  }

  configureControl(configure: (control: CtControlConfiguration | null) => void): MwLookupControlConfiguration<T, R> {

    configure(this.CTControl);
    return this;

  }

}
