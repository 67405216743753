import { Component, Input } from "@angular/core";
import { DepositCollectionPackagingConfiguration } from "./deposit-collection-packaging.configuration";
import { PackageComposerService } from "../../deposit-create-step2/package-composer/package-composer.service";
import { FormArray, FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { ModalitaStoccaggio } from "../../../../../core/enum/modalita-stoccaggio.enum";
import { DepositFormDTO, Package } from "../../deposit-create-wizard.interfaces";

@Component({
  selector: "app-deposit-collection-packaging",
  templateUrl: "./deposit-collection-packaging.component.html"
})
export class DepositCollectionPackagingComponent {

  @Input() configuration: DepositCollectionPackagingConfiguration = DepositCollectionPackagingConfiguration.create();
  form: FormGroup;

  get collection() {

    return this.form.get("collection") as FormArray<FormGroup>;

  }

  constructor(
    private formBuilder: FormBuilder,
    private packageComposerService: PackageComposerService) {

    this.form = this.formBuilder.group({
      collection: this.formBuilder.array([])
    });

  }

  setup(value: DepositFormDTO) {

    this.collection.clear();

    this.configuration
      ?.setDepositFormValue(value)
      ?.setPackageTypeIcon(this.packageComposerService.getPackageTypeIconByDepositValue(value?.step1.depositPackagingType ?? null));

    // WARNING: il form lo compiliamo lo stesso per visualizzare il riepilogo anche se sono bottiglie sfuse, lato html nascondiamo i box

    if (value.step1.depositPackagingType === ModalitaStoccaggio.Pallet) {

      const packages = value?.step2?.colliStoccaggio?.packages;

      packages
        .forEach((pkg) => this.addImballoRitiro(pkg));

    } else if (this.configuration.needsImballoRitiro) {

      this.addImballoRitiro();

    }

  }

  addImballoRitiro(pkg: Package | null = null) {

    if (!this.form.valid) return;

    this.collection
      .push(this.getImballoRitiroFormGroup(pkg));

  }

  private getImballoRitiroFormGroup(pkg: Package | null = null) {

    return this.formBuilder
      .group({
        pesoKg: new FormControl(null),
        lunghezza: new FormControl(null),
        larghezza: new FormControl(null),
        altezza: new FormControl(null),
        quantita: new FormControl(pkg?.quantita ?? 1),
        package: new FormControl(pkg) // NECESSARIO!! mi serve per i pallet
      });

  }

  removeImballoRitiro(index: number) {

    this.collection
      .removeAt(index);

  }

  protected readonly ModalitaStoccaggio = ModalitaStoccaggio;
}

