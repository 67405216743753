import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DepositPackagingSelectorComponent } from "./deposit-packaging-selector.component";
import { MwCardRadioGroupModule } from "../../../../../components/mw-card-radio-group/mw-card-radio-group.module";

@NgModule({
  declarations: [
    DepositPackagingSelectorComponent
  ],
  exports: [
    DepositPackagingSelectorComponent
  ],
  imports: [
    CommonModule,
    MwCardRadioGroupModule
  ]
})
export class DepositPackagingSelectorModule {
}
