import { Component, ViewChild } from "@angular/core";
import { CtButtonConfiguration } from "@ctsolution/ct-framework";
import { MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { WineStockShipmentTypeComponent } from "./wine-stock-shipment-type.component";
import { TipoSpedizioneEnum } from "../../../../../core/enum/tipo-spedizione.enum";

@Component({
  selector: "app-wine-stock-shipment-type-dialog",
  template: `
    <div mat-dialog-title fxLayout="row" fxLayoutAlign="space-between center">

      {{ 'Seleziona la tipologia di spedizione' | translate }}

      <button mat-icon-button color="primary" (click)="close()">

        <mat-icon>close</mat-icon>

      </button>

    </div>

    <div mat-dialog-content>

      <app-wine-stock-shipment-type #wineStockShipmentTypeComponent></app-wine-stock-shipment-type>

    </div>`
})
export class WineStockShipmentTypeDialogComponent {

  @ViewChild("wineStockShipmentTypeComponent") wineStockShipmentTypeComponent: WineStockShipmentTypeComponent | null = null;

  constructor(private dialogRef: MatDialogRef<WineStockShipmentTypeDialogComponent>) {
  }

  ngAfterViewInit() {

    this.wineStockShipmentTypeComponent
      ?.control
      .setValue(TipoSpedizioneEnum.GiacenzaScatola);

    this.wineStockShipmentTypeComponent
      ?.control
      ?.valueChanges
      .subscribe(value => {

        if (this.wineStockShipmentTypeComponent?.control.valid) {

          this.dialogRef
            .close(this.wineStockShipmentTypeComponent.control.value);

        }

      });

  }

  close() {

    this.dialogRef.close();

  }

}
