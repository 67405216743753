import { CTDateAdapter } from "@ctsolution/ct-framework";

export class MWDateFormatter {

  public static create = (): MWDateFormatter => new MWDateFormatter();

  formatDateToString(date: string | null | undefined): string {

    if (!date) return "";

    const formattedDate = new Date(date);
    const day = formattedDate.getDate();
    const month = formattedDate.getMonth() + 1;
    const year = formattedDate.getFullYear();
    return `${day}/${month}/${year}`;

  }

  getDateISOString(date: Date | string) {

    if (typeof date === "string") date = new Date(date);

    return CTDateAdapter
      .create()
      .transformDateToCurrentTimezone(date)
      .toISOString()
      .split("T")[0];

  }

}
