import { CTBase } from "@ctsolution/ct-base";
import { Wine } from "../../pages/deposit/deposit-create-wizard/deposit-create-wizard.interfaces";
import { ProdottoModel } from "./prodotto";

export class ProdottiModel extends CTBase<ProdottiModel> {

  id: string | null = null;
  prodottoId: string | null = null;
  prodotto: ProdottoModel | null = null; // mi arriva solo in get
  quantitaScatole: number | null = null;
  quantitaBottiglie: number | null = null;

  constructor();
  constructor(wine?: Wine);
  constructor(wine?: Wine) {

    super();

    if (wine) {

      this.prodottoId = wine.prodotto.value.id;
      this.quantitaScatole = wine.quantitaScatole;
      this.quantitaBottiglie = wine.quantitaBottiglie;

    }

  };

  setId(value: string | null = null): ProdottiModel {

    this.id = value;
    return this;

  }

}
