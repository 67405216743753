import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MwCardRadioGroupComponent } from "./mw-card-radio-group.component";
import { MwCardRadioOptionComponent } from "./mw-card-radio-option/mw-card-radio-option.component";
import { MatCardModule } from "@angular/material/card";
import { MatRadioModule } from "@angular/material/radio";
import { TranslateModule } from "@ngx-translate/core";
import { FlexModule } from "@angular/flex-layout";
import { ReactiveFormsModule } from "@angular/forms";

@NgModule({
  declarations: [
    MwCardRadioGroupComponent,
    MwCardRadioOptionComponent
  ],
  imports: [
    CommonModule,
    MatCardModule,
    MatRadioModule,
    TranslateModule,
    FlexModule,
    ReactiveFormsModule
  ],
  exports: [
    MwCardRadioGroupComponent
  ]
})
export class MwCardRadioGroupModule {
}
