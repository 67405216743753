import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ShipmentCreateWizardComponent } from "./shipment-create-wizard.component";
import { ShipmentCreateStep2Module } from "./shipment-create-step2/shipment-create-step2.module";
import { ShipmentCreateStep1Module } from "./shipment-create-step1/shipment-create-step1.module";
import { FlexModule } from "@angular/flex-layout";
import { MatLegacyButtonModule } from "@angular/material/legacy-button";
import { TranslateModule } from "@ngx-translate/core";

@NgModule({
  declarations: [
    ShipmentCreateWizardComponent
  ],
  imports: [
    CommonModule,
    ShipmentCreateStep1Module,
    ShipmentCreateStep2Module,
    FlexModule,
    MatLegacyButtonModule,
    TranslateModule
  ]
})
export class ShipmentCreateWizardModule {
}
