import { IndirizzoSpedizioneModel } from "../../core/classes/spedizione/indirizzo";
import { CtButtonConfiguration } from "@ctsolution/ct-framework";

export class ShipmentAddressFormConfiguration {

  title: string = "CT_GENERAL.shipment_address";
  values: IndirizzoSpedizioneModel | null = null;
  submitButton: CtButtonConfiguration = CtButtonConfiguration
    .create()
    .setLabel("CTPAGE.CTFORM.Save")
    .setClass("general-button save");
  customerSelectionEnabled: boolean = true;
  customerAddressSuggestionsEnabled: boolean = false;
  customerContactInformationEnabled: boolean = true;
  savingActionEnabled: boolean = true;
  enabledDisabled: boolean = true;


  private constructor() {
  }

  public static create = (): ShipmentAddressFormConfiguration => new ShipmentAddressFormConfiguration();

  setValues(value: IndirizzoSpedizioneModel | null) {

    this.values = value;
    return this;

  }

  /*
  * default: true
  * */
  enableCustomerSelection(value: boolean) {

    this.customerSelectionEnabled = value;
    return this;

  }

  /*
* default: true
* */
  enableSavingAction(value: boolean) {

    this.savingActionEnabled = value;
    return this;

  }

  /*
 * default: true
 * */
  enableCustomerContactInformation(value: boolean) {

    this.customerContactInformationEnabled = value;
    return this;

  }

  /*
 * default: false
 * */
  enableCustomerAddressSuggestions(value: boolean) {

    this.customerAddressSuggestionsEnabled = value;
    return this;

  }

  setTitle(value: string) {

    this.title = value;
    return this;

  }

  setEnabledDisabled(value: boolean) {

    this.enabledDisabled = value;
    return this;

  }

}
