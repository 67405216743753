import {
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  ViewChild,
  ViewContainerRef
} from "@angular/core";
import { MwBottleComponent } from "./mw-bottle/mw-bottle.component";

@Component({
  selector: "mw-wine-box-infographic",
  template: `
    <div class="bottles-center">
      <div class="bottles-container" #container></div>
      <div class="plate">
        <img src="/assets/images/logo/logo.png" />
      </div>
    </div>
  `,
  styleUrls: [`./wine-box-infographic.component.scss`]
})
export class WineBoxInfographicComponent {

  @ViewChild("container", { read: ViewContainerRef }) container: ViewContainerRef | undefined;
  componentRefs: ComponentRef<MwBottleComponent>[] = [];

  constructor(private componentFactoryResolver: ComponentFactoryResolver, private cdr: ChangeDetectorRef) {
  }


  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  add() {

    const componentFactory =
      this.componentFactoryResolver.resolveComponentFactory(MwBottleComponent);

    // Crea un'istanza del componente
    const componentRef = this.container?.createComponent(componentFactory);

    // Aggiungi la classe di animazione al componente
    componentRef?.location.nativeElement.classList.add("slide-in-top");

    // Ottieni l'elemento del container e aggiungi il componente come suo figlio
    const containerElement = this.container?.element?.nativeElement;

    if (componentRef) {

      containerElement?.appendChild(componentRef.location.nativeElement);

      this.componentRefs.push(componentRef);

      componentRef.location.nativeElement.scrollIntoView({ behavior: "smooth", block: "end" });

    }

  }

  remove() {

    return new Promise(resolve => {

      const lastIndex = this.componentRefs.length - 1;

      if (lastIndex < 0) {
        resolve(true);
        return;
      }

      const lastComponentRef = this.componentRefs[lastIndex];

      lastComponentRef.location.nativeElement.classList.remove("slide-in-top");
      lastComponentRef.location.nativeElement.classList.add("slide-out-top");

      setTimeout(() => {
        lastComponentRef.destroy();
        this.componentRefs.pop();
        resolve(true);
      }, 500);

    });

  }

}
