<mat-card>
  <mat-card-content>

    <mat-card-title><strong>{{'Notifications_LatestComm' | translate}}</strong></mat-card-title>
    <mat-card-subtitle class="m-b-30">{{'Notifications_StayTuned' | translate}}
    </mat-card-subtitle>

    <mat-card-content style="max-height: 400px; overflow: scroll;">
      <div class="d-flex no-block" *ngFor="let notification of notifications">
        <div class="m-b-5 w-100">
          <h4 class="m-0">
            {{ notification.from }}
            <small class="text-muted">{{ notification.time | date }}</small>
          </h4>
          <div class="text-muted m-t-5">{{ notification.content }}</div>
          <!--          <button mat-raised-button color="{{ notification.buttons }}" *ngIf="notification.buttons">-->
          <!--            Visualizza-->
          <!--          </button>-->
          <hr class="m-t-5" />
        </div>
      </div>
    </mat-card-content>

  </mat-card-content>
</mat-card>
