<div class="deposit-create-step1-container">

  <div class="text-center m-t-20">

    <h2 [innerHTML]="'CT_SHIPMENT_DEPOSITS.title' | translate"></h2>
    <p class="m-t-0" [innerHTML]="'CT_SHIPMENT_DEPOSITS.specifications' | translate"></p>

  </div>

  <form [formGroup]="form">

    <h2 class="text-center" [innerHTML]="'CT_SHIPMENT_DEPOSITS.deposit_type' | translate"></h2>

    <app-deposit-packaging-selector #depositPackagingSelector></app-deposit-packaging-selector>

  </form>

  <div class="m-t-40" align="end">

    <button class="general-button" mat-button matStepperNext>{{'CT_GENERAL.NEXT' | translate}}</button>

  </div>

</div>

