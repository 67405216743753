<h1 mat-dialog-title>
  {{ "CT_PROFILE.T&C" | translate }}
</h1>

<div mat-dialog-content>
  {{ "CT_GENERAL.contractualConditions" | translate}}
</div>

<div mat-dialog-actions fxLayout="row" fxLayoutAlign="space-between center">

  <button class="general-button" mat-button mat-dialog-close> Chiudi</button>

  <button
    *ngIf="!viewModel.condizioniAccettate"
    class="general-button"
    mat-button
    (click)="accept()"> Accetta le condizioni
  </button>

</div>

