import { ChangeDetectorRef, Component, Input } from "@angular/core";
import { MwLookupControlConfiguration } from "./mw-lookup-control.configuration";
import { BaseController } from "../../core/controllers/base.controller";
import { ApolloVariableClass } from "../../core/classes/apollo/apollo.variable";
import { BaseApolloQueryClass } from "../../core/classes/apollo/apollo.base.query";
import {
  CtControlTypes,
  CtControlValidator,
  CtSelectControlOptions,
  CtSelectControlValue
} from "@ctsolution/ct-framework";
import { ApolloFilters } from "../../core/interfaces/apollo/apollo.filters";
import { MwLookupControlService } from "./mw-lookup-control.service";

@Component({
  selector: "mw-lookup-control",
  template: `
    <ct-control *ngIf="configuration?.CTControl" [configuration]="configuration?.CTControl"></ct-control>`,
  providers: [MwLookupControlService]
})
export class MwLookupControlComponent<T, R> {

  @Input() protected configuration: MwLookupControlConfiguration<T, R> | null = null;

  get hasValueOptions(): boolean {

    return (this.configuration?.CTControl?.valueOptions?.length ?? 0) > 0;

  }

  constructor(
    private baseController: BaseController,
    private mwLookupControlHelper: MwLookupControlService,
    private cdr: ChangeDetectorRef) {
  }

  ngAfterViewInit() {

    if (this.configuration?.autoInitializeValueOptions) {

      this.setupFetcher()
        .then();

    }

    this.cdr.detectChanges();

  }

  private getMappedItemsFromResponse(value: R): T[] {

    let values: T[] = new Array<T>();

    if (this.configuration?.mwResponseMapper) values = this.configuration.mwResponseMapper(value);

    return values;

  }

  private getCtSelectControlOptionsFromResponse(value: R) {

    const values = this.getMappedItemsFromResponse(value);
    return this._CTMapper(values);

  }

  private _CTMapper(values: T[]) {

    return values
      .map(item => CtSelectControlValue
        .create()
        .setValue(item)
        .setLabel(this.configuration?.optionDescriptionFn ? this.configuration?.optionDescriptionFn(item) : "Unknown"));

  }

  async setupFetcher(
    param?: {
      values?: T[] | null;
      filters?: Array<ApolloFilters> | null;
      validators?: CtControlValidator[] | null;
      queryDisabled?: boolean;
    }) {

    if (param?.validators) {

      this.configuration
        ?.CTControl
        .setValidators(param.validators);

    }

    if (param?.values) {

      const mappedValues = this._CTMapper(param.values);

      this.configuration
        ?.CTControl
        .setValueOptions(mappedValues);

      const pendingValue = this.configuration?.CTControl.control?.value;

      /**
       * Serve a forzare il refresh value quando la valueOptions vengono passate in modo asincrono
       * */
      if (pendingValue) {

        const valueOptions = this.configuration?.CTControl.valueOptions ?? [];

        const match = valueOptions.find(elm => elm.value.id === pendingValue.id);

        if (match) {

          this.configuration?.CTControl.setValue(match.value);

        }

      }

      this.cdr.detectChanges();

      return;

    }

    if (param?.filters) {

      this.configuration
        ?.setWhereAndFilters(param.filters);

    }

    this.configuration
      ?.setQueryDisabled(param?.queryDisabled ?? false);

    this.configuration
      ?.CTControl
      .configureOptions<CtSelectControlOptions>(async options => {

        if (!options) options = CtSelectControlOptions.create();

        switch (this.configuration?.CTControl.type) {

          case CtControlTypes.LOOKUP:

            options
              .setCustomSwitchMapHandler((value: any) => this.executeQuery!(value))
              .setLookupResponseMapper((value: any) => this.getCtSelectControlOptionsFromResponse(value as R) ?? []);

            break;

          case CtControlTypes.ENUMERABLE:
          default:

            options
              .setObjectComparisonFunction((option, value) => {

                if (!option && !value) return true; // entrambi sono null o undefined

                if (!option || !value) return false; // uno dei due è null o undefined mentre l'altro no

                const optionId = option.id || "";
                const valueId = value.id || "";

                const optionNome = option.nome || "";
                const valueNome = value.nome || "";

                return optionId === valueId || optionNome === valueNome;

              });

            try {

              const response = await this.executeQuery();

              if (response) {

                const values = this.getMappedItemsFromResponse(response) ?? [];
                const mappedValues = this._CTMapper(values);

                this.configuration
                  ?.CTControl
                  .setValueOptions(mappedValues);

                this.cdr.detectChanges();

              }

            } catch (error) {

              console.error("Errore durante la richiesta dei dati:", error);

            }

            break;

        }

        this.cdr.detectChanges();

      });

    this.cdr.detectChanges();

  }

  private executeQuery(searchText: string | null = null): Promise<any> {

    const variables: ApolloVariableClass = ApolloVariableClass.create();

    if (searchText) variables.setSearchText(searchText);

    if (this.configuration?.whereAndFilters?.length) {

      variables.setWhereAndFilters(this.configuration.whereAndFilters);

    }

    if (this.configuration?.order.length) {

      variables
        .setOrder(this.configuration.order);

    }

    return new Promise<any>(async (resolve, reject) => {

      try {

        const query = await this.configuration?.query();

        if (!query || this.configuration?.queryDisabled) {

          reject(new Error("Query is not defined or invalid filters."));
          await this.mwLookupControlHelper.forceUpdate(this.configuration?.CTControl.control);
          return;

        }

        const parameter = BaseApolloQueryClass
          .create()
          .setVariables(variables)
          .setQuery(query);

        const observable = await this.baseController.list<R>(parameter);

        if (!observable) return;

        const subscription = observable
          ?.subscribe(
            result => {

              resolve(result);
              subscription?.unsubscribe();

            },
            error => reject(error),
            () => {
              // Opzionale: gestisci la logica quando l'Observable è completo (non necessario se unsubscribe già chiamato)
            }
          );

      } catch (error) {

        reject(error);

      }

    });
  }

}
