import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { WineBoxInfographicComponent } from "./wine-box-infographic.component";
import { FlexModule } from "@angular/flex-layout";
import { MwBottleComponent } from './mw-bottle/mw-bottle.component';


@NgModule({
  declarations: [
    WineBoxInfographicComponent,
    MwBottleComponent
  ],
  exports: [
    WineBoxInfographicComponent
  ],
  imports: [
    CommonModule,
    FlexModule
  ]
})
export class WineBoxInfographicModule {
}
