import { CTDatatableFilterConfiguration, CTDatatableHeaderCellConfiguration } from "@ctsolution/ct-framework";
import { TranslateService } from "@ngx-translate/core";

export const descrizioneHeader = (translate: TranslateService) => CTDatatableHeaderCellConfiguration
  .create()
  .setField("descrizione")
  .setLabel(translate.instant("CT_DATATABLE.WINERECORDS.Desc"))
  .setInlineStyle("min-width: 450px")
  .setSortable(true)
  .setFilter(
    CTDatatableFilterConfiguration
      .create()
      .setPlaceHolder(translate.instant("CT_DATATABLE.WINERECORDS.DescriptionSearch"))
      .setShowMenu(true));

export const tipoVinoHeader = (translate: TranslateService) => CTDatatableHeaderCellConfiguration
  .create()
  .setField("tipoVino.nome")
  .setLabel(translate.instant("CT_DATATABLE.WINERECORDS.WineZone"))
  .setInlineStyle("min-width: 300px")
  .setSortable(true)
  .setFilter(
    CTDatatableFilterConfiguration
      .create()
      .setPlaceHolder(translate.instant("CT_DATATABLE.WINERECORDS.ZoneSearch"))
      .setShowMenu(true));

export const denominazioneHeader = (translate: TranslateService) => CTDatatableHeaderCellConfiguration
  .create()
  .setField("denominazione.nome")
  .setLabel(translate.instant("CT_DATATABLE.WINERECORDS.Denomination"))
  .setInlineStyle("min-width: 230px;")
  .setSortable(true)
  .setFilter(
    CTDatatableFilterConfiguration
      .create()
      .setPlaceHolder(translate.instant("CT_DATATABLE.WINERECORDS.DenominationSearch"))
      .setShowMenu(true));

export const annoHeader = (translate: TranslateService) => CTDatatableHeaderCellConfiguration
  .create()
  .setField("anno")
  .setLabel(translate.instant("CT_DATATABLE.WINERECORDS.Year"))
  .setInlineStyle("min-width: 140px;")
  .setSortable(true)
  .setFilter(
    CTDatatableFilterConfiguration
      .create()
      .setPlaceHolder(translate.instant("CT_DATATABLE.WINERECORDS.YearSearch"))
      .setShowMenu(true));

export const menzioneHeader = (translate: TranslateService) => CTDatatableHeaderCellConfiguration
  .create()
  .setField("menzione.nome")
  .setLabel(translate.instant("CT_DATATABLE.WINERECORDS.Mentions"))
  .setInlineStyle("min-width: 180px;")
  .setFilter(CTDatatableFilterConfiguration
    .create()
    .setPlaceHolder(translate.instant("CT_DATATABLE.WINERECORDS.MentionSearch")));

export const produttoreHeader = (translate: TranslateService) => CTDatatableHeaderCellConfiguration
  .create()
  .setField("produttore")
  .setLabel(translate.instant("CT_DATATABLE.WINERECORDS.Maker"))
  .setInlineStyle("min-width: 180px")
  .setSortable(true)
  .setFilter(
    CTDatatableFilterConfiguration
      .create()
      .setPlaceHolder(translate.instant("CT_DATATABLE.WINERECORDS.MakerSearch"))
      .setShowMenu(true));

export const tipologiaHeader = (translate: TranslateService) => CTDatatableHeaderCellConfiguration
  .create()
  .setField("tipologia.nome")
  .setLabel(translate.instant("CT_DATATABLE.WINERECORDS.Type"))
  .setInlineStyle("min-width: 200px")
  .setSortable(true)
  .setFilter(
    CTDatatableFilterConfiguration
      .create()
      .setPlaceHolder(translate.instant("CT_DATATABLE.WINERECORDS.TypeSearch"))
      .setShowMenu(true));

export const paeseHeader = (translate: TranslateService) => CTDatatableHeaderCellConfiguration
  .create()
  .setField("regione.stato.nome")
  .setLabel(translate.instant("CT_DATATABLE.WINERECORDS.Country"))
  .setInlineStyle("min-width: 150px")
  .setSortable(true)
  .setFilter(
    CTDatatableFilterConfiguration
      .create()
      .setPlaceHolder(translate.instant("CT_DATATABLE.WINERECORDS.CountrySearch"))
      .setShowMenu(true));

export const gradazioneHeader = (translate: TranslateService) => CTDatatableHeaderCellConfiguration
  .create()
  .setField("gradazione")
  .setLabel(translate.instant("CT_DATATABLE.WINERECORDS.ABV"))
  .setInlineStyle("min-width: 200px;")
  .setSortable(true)
  .setFilter(
    CTDatatableFilterConfiguration
      .create()
      .setType("numeric")
      .setPlaceHolder(translate.instant("CT_DATATABLE.WINERECORDS.ABVSearch"))
      .setShowMenu(true));
