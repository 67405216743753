<mw-datatable
  #datatable
  *ngIf="listConfiguration"
  class="movement-history-container"
  [configuration]="listConfiguration">

  <ng-template #body let-value="value">

    <td>
      {{ value?.deposito?.codice ?? ''}}
    </td>

    <td>
      {{ value?.spedizione?.codice ?? ''}}
    </td>

    <td>
      {{ value?.vino?.descrizione ?? '' }}
    </td>

    <td>
      {{ value?.giacenzaPallet?.id ?? '' }}
    </td>

  </ng-template>

</mw-datatable>

<ng-template #headerActions>

  <mw-date-range-picker #dateRangePickerComponent></mw-date-range-picker>

</ng-template>
