<div class="shipment-create-cntr">

  <div fxLayout="row wrap">

    <div fxFlex="100" fxFlex.gt-md="70">

      <app-shipment-create-step1 #step1></app-shipment-create-step1>

    </div>

    <div fxFlex="100" fxFlex.gt-md="30">

      <app-shipment-create-step2 #step2 (onSubmit)="submit()"></app-shipment-create-step2>

      <div align="end" class="p-10">

        <button
          mat-button
          type="button"
          class="general-button"
          (click)="submit()">{{ 'CTPAGE.CTFORM.save-shipment' | translate }}
        </button>

      </div>

    </div>

  </div>

</div>
