import { ChangeDetectorRef, Component, Inject, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import {
  CtButtonConfiguration,
  CtControlTypes,
  CtControlValidator,
  CtSelectControlValue
} from "@ctsolution/ct-framework";
import { ConfirmService } from "../../../components/confirm/confirm.service";
import { BaseController } from "../../../core/controllers/base.controller";
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from "@angular/material/legacy-dialog";
import { WineCreationFormConfiguration } from "./wine-creation-form.configuration";
import { ProdottoModel } from "../../../core/classes/prodotto";
import { ProductControllerRequest } from "../../../core/controllers/controllers";
import { PaeseControlComponent } from "../../../components/paese-control/paese-control.component";
import { RegioneControlComponent } from "./_controls/regione-control/regione-control.component";
import {
  NomeCommercialeControlComponent
} from "./_controls/nome-commerciale-control/nome-commerciale-control.component";
import { DenominazioneControlComponent } from "./_controls/denominazione-control/denominazione-control.component";
import { TipoVinoControlComponent } from "./_controls/tipo-vino-control/tipo-vino-control.component";
import { AnnoControlComponent } from "./_controls/anno-control/anno-control.component";
import { MenzioneControlComponent } from "./_controls/menzione-control/menzione-control.component";
import {
  FormatoBottigliaControlComponent
} from "./_controls/formato-bottiglia-control/formato-bottiglia-control.component";
import { ProduttoreControlComponent } from "./_controls/produttore-control/produttore-control.component";
import {
  GradazioneAlcolicaControlComponent
} from "./_controls/gradazione-alcolica-control/gradazione-alcolica-control.component";
import { MetodologiaControlComponent } from "./_controls/metodologia-control/metodologia-control.component";
import { TipologiaControlComponent } from "./_controls/tipologia-control/tipologia-control.component";
import { SpumanteAnnataComponent } from "./_controls/spumante-annata/spumante-annata.component";
import { SpumanteDosaggioComponent } from "./_controls/spumante-dosaggio/spumante-dosaggio.component";
import { SpumanteMetodoComponent } from "./_controls/spumante-metodo/spumante-metodo.component";
import { TipologiaModel } from "../../../core/interfaces/apollo/tipologie.apollo-result";
import { MwLookupControlService } from "../../../components/mw-lookup-control/mw-lookup-control.service";
import { MenzioneEsteroControlComponent } from "./_controls/menzione-estero-control/menzione-estero-control.component";
import { pairwise, startWith } from "rxjs";
import { QueriesService } from "../../../core/lib/queries.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-wine-creation-form",
  templateUrl: "./wine-creation-form.component.html"
})
export class WineCreationFormComponent {

  @ViewChild(PaeseControlComponent) paeseControlComponent: PaeseControlComponent | null = null;
  @ViewChild(RegioneControlComponent) regioneControlComponent: RegioneControlComponent | null = null;
  @ViewChild(NomeCommercialeControlComponent) nomeCommercialeControlComponent: NomeCommercialeControlComponent | null = null;
  @ViewChild(DenominazioneControlComponent) denominazioneControlComponent: DenominazioneControlComponent | null = null;
  @ViewChild(TipoVinoControlComponent) tipoVinoControlComponent: TipoVinoControlComponent | null = null;
  @ViewChild(AnnoControlComponent) annoControlComponent: AnnoControlComponent | null = null;
  @ViewChild(MenzioneControlComponent) menzioneControlComponent: MenzioneControlComponent | null = null;
  @ViewChild(MenzioneEsteroControlComponent) menzioneEsteroControlComponent: MenzioneEsteroControlComponent | null = null;
  @ViewChild(FormatoBottigliaControlComponent) formatoBottigliaControlComponent: FormatoBottigliaControlComponent | null = null;
  @ViewChild(ProduttoreControlComponent) produttoreControlComponent: ProduttoreControlComponent | null = null;
  @ViewChild(GradazioneAlcolicaControlComponent) gradazioneAlcolicaControlComponent: GradazioneAlcolicaControlComponent | null = null;
  @ViewChild(MetodologiaControlComponent) metodologiaControlComponent: MetodologiaControlComponent | null = null;
  @ViewChild(TipologiaControlComponent) tipologiaControlComponent: TipologiaControlComponent | null = null;
  @ViewChild(SpumanteAnnataComponent) spumanteAnnataControlComponent: SpumanteAnnataComponent | null = null;
  @ViewChild(SpumanteDosaggioComponent) spumanteDosaggioComponent: SpumanteDosaggioComponent | null = null;
  @ViewChild(SpumanteMetodoComponent) spumanteMetodoComponent: SpumanteMetodoComponent | null = null;

  form: FormGroup;
  submitButton: CtButtonConfiguration = CtButtonConfiguration
    .create()
    .setLabel("CTPAGE.CTFORM.Save")
    .setClass("general-button save")
    .setAction(() => this.submit());

  constructor(
    private formBuilder: FormBuilder,
    private confirmService: ConfirmService,
    private baseController: BaseController,
    private _queries: QueriesService,
    private cdr: ChangeDetectorRef,
    public dialogRef: MatLegacyDialogRef<WineCreationFormComponent>,
    private mwLookupControlHelper: MwLookupControlService,
    @Inject(MAT_LEGACY_DIALOG_DATA) public configuration: WineCreationFormConfiguration,
    private translate: TranslateService
  ) {

    this.form = this.formBuilder.group({});

  }

  ngAfterViewInit() {

    this.setupForm();

  }

  get isSpumante() {

    return this.form.get("tipologia")?.value?.nome?.toLowerCase().includes("spumante"); // TODO: Lo gestiamo davvero in base al nome?

  }

  get isItalia() {

    return this.form.get("stato")?.value?.nome?.toLowerCase().includes("italia");

  }

  //#region CONTROLS SETUP

  setupForm() {

    this.setupPaeseControl();

    this.setupRegioneControl();

    this.setupDenominazioneControl();

    this.setupTipoVinoControl();

    this.setupMenzioneControl();

    this.setupMenzioneEsteroControl();

    this.setupFormatoBottigliaControl();

    this.setupMetodologiaControl();

    this.setupSpumanteAnnataControl();

    this.setupSpumanteDosaggioControl();

    this.setupSpumanteMetodoControl();

    this.setupTipologiaControl();

    this.nomeCommercialeControlComponent
      ?.control
      .setFormParent(this.form);

    this.annoControlComponent
      ?.control
      .setFormParent(this.form);

    this.produttoreControlComponent
      ?.control
      .setFormParent(this.form);

    this.gradazioneAlcolicaControlComponent
      ?.control
      .setFormParent(this.form);

    setTimeout(() => this.setupValues(), 300);

    this.cdr.detectChanges();

  }

  private setupPaeseControl() {

    this.paeseControlComponent
      ?.configuration
      ?.setQuery(this._queries.entities.statiPerVino)
      ?.CTControl
      .setType(CtControlTypes.ENUMERABLE);

    this.paeseControlComponent
      ?.lookupControl
      ?.setupFetcher();

    this.mwLookupControlHelper
      .initializeControl(this.form, this.paeseControlComponent!, "stato", true);

    this.mwLookupControlHelper
      .valueChangesSubscription(this.paeseControlComponent)
      ?.pipe(startWith(null), pairwise()) // fornisce un valore iniziale (null) per includere il primo valore
      .subscribe(async ([previousValue, currentValue]) => {

        await this.mwLookupControlHelper
          .setupLookupValuesByCountryFilter(this.regioneControlComponent, currentValue);

        await this.mwLookupControlHelper
          .setupLookupValuesByCountryFilter(this.menzioneControlComponent, currentValue);

        await this.mwLookupControlHelper
          .setupLookupValuesByCountryFilter(this.denominazioneControlComponent, currentValue);

        await this.mwLookupControlHelper
          .setupLookupValuesByCountryFilter(this.menzioneEsteroControlComponent, currentValue);

        this.menzioneEsteroControlComponent?.setupLabelByCountry(currentValue);

        const reinitRelatedLookup = currentValue?.nome !== previousValue?.nome && !!previousValue;

        if (reinitRelatedLookup) {

          this.regioneControlComponent?.configuration.CTControl?.setValue(null);
          this.menzioneControlComponent?.configuration.CTControl.setValue(null);
          this.denominazioneControlComponent?.configuration.CTControl.setValue(null);
          this.menzioneEsteroControlComponent?.configuration.CTControl.setValue(null);

        }

      });  // se paese definito, posso fare la get delle regioni disponibili

  }

  private setupRegioneControl() {

    this.mwLookupControlHelper.initializeControl(this.form, this.regioneControlComponent, "regione");

    this.mwLookupControlHelper
      .valueChangesSubscription(this.regioneControlComponent)
      ?.subscribe(value => {

        if (!value && this.denominazioneControlComponent) {

          this.mwLookupControlHelper?.setDefaultValue(this.denominazioneControlComponent, ["vdt", "da tavola"], !value);

        }

        this.tipoVinoControlComponent?.setValue(null).setup(this.form.value);

      });  // se regione e denominazione definiti, devo fare il setup dei tipi di vino disponibili

  }

  private setupDenominazioneControl() {

    this.mwLookupControlHelper.initializeControl(this.form, this.denominazioneControlComponent, "denominazione");

    this.mwLookupControlHelper
      .valueChangesSubscription(this.denominazioneControlComponent)
      ?.subscribe(async value => {

        const valueName = value?.nome ?? "";

        const lowerCasedValue = valueName.toLowerCase();
        const isVinoDaTavola = lowerCasedValue.includes("vdt") || lowerCasedValue.includes("da tavola"); // TODO: anche qui lo cabliamo a codice? non cambierà mai?

        //  i campi TIPO VINO e MENZIONE devono essere disabilitati e non compilabili, poiché non sono pertinenti per il vino da tavola.
        this.menzioneControlComponent
          ?.configuration
          .CTControl
          ?.setDisabled(isVinoDaTavola);

        this.tipoVinoControlComponent
          ?.configuration
          ?.CTControl
          ?.setDisabled(isVinoDaTavola);

        if (isVinoDaTavola) {

          this.menzioneControlComponent?.configuration.CTControl?.setValue(null);

        }

        await this.mwLookupControlHelper.forceUpdate(this.tipoVinoControlComponent?.configuration.CTControl.control);
        this.tipoVinoControlComponent?.setup(this.form.value);

      }); // se regione e denominazione definiti, devo fare il setup dei tipi di vino disponibili

  }

  private setupTipoVinoControl() {

    this.mwLookupControlHelper.initializeControl(this.form, this.tipoVinoControlComponent, "tipoVino");

  }

  private setupMenzioneControl() {

    this.mwLookupControlHelper.initializeControl(this.form, this.menzioneControlComponent, "menzione");

  }

  private setupMenzioneEsteroControl() {

    this.mwLookupControlHelper.initializeControl(this.form, this.menzioneEsteroControlComponent, "menzioneEstero");

  }

  private setupFormatoBottigliaControl() {

    this.mwLookupControlHelper.initializeControl(this.form, this.formatoBottigliaControlComponent, "formato", true);

    if (this.formatoBottigliaControlComponent && !this.configuration.values?.formatoId) {

      this.mwLookupControlHelper
        ?.setDefaultValue(this.formatoBottigliaControlComponent, ["standard"]);

    }

  }

  private setupMetodologiaControl() {

    this.mwLookupControlHelper.initializeControl(this.form, this.metodologiaControlComponent, "metodologia");

    if (this.metodologiaControlComponent && !this.configuration.values?.metodologiaId) {

      this.mwLookupControlHelper
        ?.setDefaultValue(this.metodologiaControlComponent, ["convenzionale"]);

    }

  }

  private setupSpumanteAnnataControl() {

    this.mwLookupControlHelper.initializeControl(this.form, this.spumanteAnnataControlComponent, "annoSpumante");

  }

  private setupSpumanteDosaggioControl() {

    this.mwLookupControlHelper.initializeControl(this.form, this.spumanteDosaggioComponent, "dosaggioSpumante");

  }

  private setupSpumanteMetodoControl() {

    this.mwLookupControlHelper.initializeControl(this.form, this.spumanteMetodoComponent, "metodoSpumante");

  }

  private setupTipologiaControl() {

    this.mwLookupControlHelper.initializeControl(this.form, this.tipologiaControlComponent, "tipologia");

    this.mwLookupControlHelper
      .valueChangesSubscription(this.tipologiaControlComponent)
      ?.subscribe((value: TipologiaModel) => {

        if (!value) return;

        // check sul campo di tipo "Spumante"
        this.spumanteAnnataControlComponent
          ?.setup(value); // annata non obbligatoria

        if (this.isSpumante) {

          /*
           * Casistica rara: quando è uno spumante, nel DTO devono arrivare anche i campi dosaggiSpumante e metodiSpumante
           * (anche se inizializzati ad array vuoti in caso non ci fossero corrispondenze).
           * L'unico caso in cui questi due campi non esistono è quando il FE forza l'inserimento del valore
           * (quindi nel dettaglio iniziale, momento in cui la lookup non ha ancora valorizzato le valueOptions).
           */

          if (!value.dosaggiSpumante || !value.metodiSpumante) {

            const valueOptions = this.tipologiaControlComponent?.configuration.CTControl.valueOptions ?? [];
            const match = valueOptions.find(elm => elm.value.id === value.id);

            if (match) {

              setTimeout(() => this.tipologiaControlComponent?.configuration.CTControl.setValue(match.value));
              return;

            }

          }

        }

        const spumanteValidators = this.isSpumante ? [CtControlValidator.create({ name: "required" } as CtControlValidator)] : null;

        this.spumanteDosaggioComponent
          ?.setup(value, spumanteValidators);

        this.spumanteMetodoComponent
          ?.setup(value, spumanteValidators);

      });

  }

  //#endregion CONTROLS SETUP

  setupValues() {

    if (this.configuration.values) {

      const values = this.configuration.values as any;

      this.form?.patchValue(values);

      const { stato, regione, denominazione, menzione, tipoVino } = values;

      if (stato) this.form.patchValue({ stato });

      setTimeout(() => {

        if (regione) this.regioneControlComponent?.setValue(CtSelectControlValue.create().setLabel(regione.nome).setValue(regione));

        setTimeout(() => {

          this.form.patchValue({ denominazione, menzione });

          setTimeout(() => {

            if (tipoVino) this.tipoVinoControlComponent?.setValue(CtSelectControlValue.create().setLabel(tipoVino.nome).setValue(tipoVino));

          }, 250);

        }, 750);

      }, 250);


    }

    this.cdr.detectChanges();

  }

  close() {

    if (this.form?.touched) {

      const dialogRef = this.confirmService.open();

      dialogRef
        .afterClosed()
        .subscribe((result?: boolean) => {

          if (result) this.dialogRef.close();

        });

      return;

    }

    this.dialogRef.close();

  }

  async submit() {

    this.form.markAllAsTouched();

    if (this.form.valid) {

      const value = this.form.value;

      const updatedResult = this.processProductResult(value);

      const parameter = ProductControllerRequest();

      parameter
        .setId(this.configuration?.values?.id ?? null);

      await this.baseController.createOrUpdate<ProdottoModel>(updatedResult, parameter);

      this.dialogRef?.close(true);

    }

  }

  private processProductResult(result: ProdottoModel): ProdottoModel {

    return ProdottoModel
      .create(result)
      .setId(this.configuration?.values?.id ?? null);

  }

}
