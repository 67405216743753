import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { PaeseControlComponent } from "./paese-control.component";
import { MwLookupControlModule } from "../mw-lookup-control/mw-lookup-control.module";

@NgModule({
  declarations: [PaeseControlComponent],
  imports: [
    CommonModule,
    MwLookupControlModule
  ],
  exports: [
    PaeseControlComponent
  ]
})
export class PaeseControlModule {
}
