<div mat-dialog-title fxLayout="row" fxLayoutAlign="space-between center" class="m-b-30">

  {{ (configuration?.title ?? '') | translate }}

  <button *ngIf="dialogRef" mat-icon-button color="primary" (click)="close()">
    <mat-icon>close</mat-icon>
  </button>

</div>

<div mat-dialog-content class="shipment-address-form-container">

  <form [formGroup]="form">

    <ng-container *ngIf="this.configuration?.customerSelectionEnabled">
    
      <div class="divider">Scegli una destinazione esistente</div>

      <div *ngIf="form?.get('customer')">

        <app-customer-lookup-control
          #customerLookupControl
          [control]="form?.get('customer')"></app-customer-lookup-control>

      </div>

      <div class="divider">Oppure creala nuova</div>

  </ng-container>

    <!-- <div [hidden]="!customerAddressSelectControl?.lookupControl?.hasValueOptions">

      <app-customer-address-select-control
        #customerAddressSelectControl
        [control]="form?.get('customerSuggestion')"></app-customer-address-select-control>

    </div> non più necessario -->

    <div *ngIf="configuration?.customerContactInformationEnabled">

      <app-recipient-contact-data-form [enabledDisabled]="configuration?.enabledDisabled"></app-recipient-contact-data-form>

    </div>

    <div>

      <app-address-data-form [enabledDisabled]="configuration?.enabledDisabled"></app-address-data-form>

    </div>

  </form>

</div>

<!-- <div *ngIf="saveAddressButtonEnabled" mat-dialog-actions>

  <ct-button [configuration]="configuration?.submitButton"></ct-button>

</div>

  non più necessario

-->



