<mat-card>
  <mat-card-content>
    <mat-card-title><strong>{{'Card_Clients' | translate }}</strong></mat-card-title>
    <mat-card-subtitle>{{ 'Card_Clients_VS' | translate }}</mat-card-subtitle>
    <div id="chart" class="d-flex flex-column">
      <apx-chart
        #chart
        *ngIf="chartOptions"
        [series]="chartOptions.series"
        [chart]="chartOptions.chart"
        [xaxis]="chartOptions.xaxis">
      </apx-chart>
    </div>
  </mat-card-content>
</mat-card>
