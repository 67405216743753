<mw-datatable
  #table
  class="customer-datatable-container"
  [configuration]="listConfiguration"
  (onSave)="onSave($event)"
  (onDetail)="detail($event)">

  <ng-template #body let-value="value">

    <td>
      {{ value?.nome ?? '' }}
    </td>

    <td>

      {{ getFormattedIndirizzo(value.indirizzo ?? '') }}

    </td>

    <td>

      {{ value?.indirizzo?.provincia ?? '' }}

    </td>

    <td>

      {{ value?.indirizzo?.stato?.nome ?? '' }}

    </td>

  </ng-template>

</mw-datatable>
