import { Injectable } from "@angular/core";
import { GiacenzaVinoModel } from "../../core/interfaces/giacenza-vino.model";

@Injectable({
  providedIn: "root"
})
export class ProductStockLookupControlService {

  private values: GiacenzaVinoModel[] = [];

  addValue(value: GiacenzaVinoModel | null) {

    if (!value) return;

    const existingValue = this.values.find(item => item?.vino?.id === value?.vino?.id);

    if (!existingValue) this.values.push(value);

  }

  getValues(): GiacenzaVinoModel[] {
    return this.values;
  }

}
