import { Injectable } from "@angular/core";
import { ModalitaStoccaggio } from "../../../../../core/enum/modalita-stoccaggio.enum";
import { iconsBasePath } from "../../../../../core/constants";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
  providedIn: "root"
})
export class PackageComposerService {

  constructor(
    private translate: TranslateService
  ) {
  }

  getPackageTypeTitleByDepositValue(value: ModalitaStoccaggio | null) {

    switch (value) {

      case ModalitaStoccaggio.Pallet:
        return this.translate.instant("CT_SHIPMENT_DEPOSITS.palletComposition");
      case ModalitaStoccaggio.Scatola:
        return this.translate.instant("CT_SHIPMENT_DEPOSITS.boxComposition");
      case ModalitaStoccaggio.Bottiglia:
        return this.translate.instant("CT_SHIPMENT_DEPOSITS.addBottles");
      default:
        return "";

    }

  }

  getPackageTypeIconByDepositValue(value: ModalitaStoccaggio | null) {

    switch (value) {

      case ModalitaStoccaggio.Pallet:
        return `${iconsBasePath}pallet.svg`;

      case ModalitaStoccaggio.Scatola:
        return `${iconsBasePath}box.svg`;

      case ModalitaStoccaggio.Bottiglia:
        return `${iconsBasePath}bottles.svg`;

      default:
        return "";

    }

  }

}
