import { Injectable } from "@angular/core";
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { ConfirmComponent } from "./confirm.component";
import { FormArray, FormGroup } from "@angular/forms";

@Injectable()
export class ConfirmService {

  constructor(private dialog: MatDialog) {
  }

  open(title: string = "CONFIRM_DIALOG.delete_pallet_title", description: string = "CONFIRM_DIALOG.delete_pallet_description") {

    const dialogRef = this.dialog.open(ConfirmComponent, { maxWidth: "540px" });

    dialogRef.componentInstance.title = title;
    dialogRef.componentInstance.description = description;

    return dialogRef;

  };

  removePackage(formArray: FormArray, index: number) {

    const packageFormGroup = formArray.at(index) as FormGroup;

    if (!packageFormGroup.touched) {

      formArray.removeAt(index);
      return;

    }

    // apertura dialog se ci sono dati nel form
    this
      .open()
      .afterClosed()
      .subscribe((result?: boolean) => {

        if (result) formArray.removeAt(index);

      });

  }

}
