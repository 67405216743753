<h1 mat-dialog-title>
  {{ "CT_PROFILE.T&C" | translate }}
</h1>

<div mat-dialog-content>

  Condizioni Contrattuali per l'Azienda Online di Deposito di Vini. Il presente contratto è tra Cantina dei Vini S.r.l.
  e
  il destinazione MOSTWine, concluso il 09/09/2023.Le parti coinvolte nel presente contratto sono:
  Cantina dei Vini S.r.l., con sede a Via Roma, 123, Catania, Italia, identificata con il codice fiscale [Codice
  Fiscale], in qualità di [Ruolo], ha il diritto di concedere in via legale e per iscritto un'immobile a [Nome del
  destinazione] per lo scopo di deposito di vini.
  Il destinazione, a nome di [Nome del destinazione], con sede a [Indirizzo del destinazione], identificato con il codice fiscale
  [Codice Fiscale], ha il diritto di acquistare un'immobile a Cantina dei Vini S.r.l. per lo scopo di deposito di vini.
  Il prezzo dell'immobile è di [Prezzo], e il deposito di vini avviene a rate costanti. Cantina dei Vini S.r.l. non ha
  alcun obbligo di fornire servizi di pulizia o manutenzione dell'immobile.Il pagamento del prezzo dell'immobile avviene
  attraverso bonifico bancario o carta di credito. Il destinazione è responsabile per eventuali spese di trasporto e di
  eventuali oneri derivanti dalla vendita dell'immobile.Cantina dei Vini S.r.l. non ha alcun obbligo di fornire
  assistenza o supporto tecnico per l'immobile. Cantina dei Vini S.r.l. si assume la responsabilità per eventuali danni
  causati all'immobile durante la consegna o durante il periodo di deposito.Il presente contratto è valido per un
  periodo di [Durata del Contratto] e viene rispettato dal destinazione. Il destinazione ha il diritto di chiedere la
  ritrasmissione dei pagamenti non emessi e di chiedere la cancellazione del contratto.Il presente contratto è stato
  firmato digitalmente da entrambi le parti e dichiarato di essere valido e di rispettare le leggi e le disposizioni
  delle leggi italiane.Il destinazione ha il diritto di chiedere la modifica o la cancellazione delle condizioni contrattuali
  precedenti.In caso di dubbi o disaccordi tra le parti, il loro legame si intende risolto attraverso il foro di
  competenza dello Stato in Italia.Il presente contratto è stato emesso in [Luogo di Emissione] e ha validità per un
  periodo di [Durata del Contratto].Il destinazione ha il diritto di chiedere la ritrasmissione dei pagamenti non emessi e di
  chiedere la cancellazione del contratto.Il presente contratto è stato firmato digitalmente da entrambi le parti e
  dichiarato di essere valido e di rispettare le leggi e le disposizioni delle leggi italiane.Il destinazione ha il diritto
  di chiedere la modifica o la cancellazione delle condizioni contrattuali precedenti.In caso di dubbi o disaccordi tra
  le parti, il loro legame si intende risolto attraverso il foro di competenza dello Stato in Italia.Il presente
  contratto è stato emesso in [Luogo di Emissione] e ha validità per un periodo di [Durata del Contratto].

</div>

<div mat-dialog-actions fxLayout="row" fxLayoutAlign="space-between center">

  <button class="general-button" mat-button mat-dialog-close> Chiudi</button>

  <button
    *ngIf="!viewModel.condizioniAccettate"
    class="general-button"
    mat-button
    (click)="accept()"> Accetta le condizioni
  </button>

</div>

