import { Component, ViewChild } from "@angular/core";
import { GenericReadModel } from "../../../../../core/classes/generic-read.model";
import { QueriesService } from "../../../../../core/lib/queries.service";
import { RegioniApolloResult } from "../../../../../core/interfaces/apollo/regioni.apollo.result";
import { MwLookupControlComponent } from "../../../../../components/mw-lookup-control/mw-lookup-control.component";
import {
  MwLookupControlConfiguration
} from "../../../../../components/mw-lookup-control/mw-lookup-control.configuration";
import { CtControlTypes } from "@ctsolution/ct-framework";

@Component({
  selector: "mw-regione-control",
  template: `
    <mw-lookup-control
      #lookupControl
      [configuration]="configuration"></mw-lookup-control>`
})
export class RegioneControlComponent {

  @ViewChild("lookupControl") lookupControl: MwLookupControlComponent<GenericReadModel, RegioniApolloResult> | null = null;

  configuration: MwLookupControlConfiguration<GenericReadModel, RegioniApolloResult> = MwLookupControlConfiguration
    .create<GenericReadModel, RegioniApolloResult>()
    .setQuery(this._queries.entities.regioni)
    .setResponseMapper(value => value.regioni)
    .setOptionDescriptionFn(value => value?.nome ?? "Unknown")
    .setOrder([{ nome: "ASC" }])
    .configureControl(control => {

      control
        ?.setLabel("CT_GENERAL.region")
        ?.setType(CtControlTypes.LOOKUP);

    });

  constructor(private _queries: QueriesService) {
  }

  setValue(value: any) {

    this.configuration
      .CTControl
      .setValue(value);

    return this;

  }

  ngAfterViewInit() {
  }

}
