<div mat-dialog-title fxLayout="row" fxLayoutAlign="space-between center">

  {{ 'CT_SHIPMENT_DEPOSITS.selectFee' | translate }}

  <button mat-icon-button color="primary" (click)="close()">

    <mat-icon>close</mat-icon>

  </button>

</div>

<div mat-dialog-content>

  <div *ngIf="loading; else readyTemplate">

    <mat-progress-bar mode="indeterminate"></mat-progress-bar>

  </div>

  <ng-template #readyTemplate>

    <mat-selection-list *ngIf="rates.length; else noRatesTemplate" #options [multiple]="false">

      <mat-list-option *ngFor="let rate of rates" [value]="rate">

        <span matListItemTitle>{{ rate.nomeCorriere }}: {{ rate.servizio }}</span>
        <span matListItemLine>Consegna in {{ rate.giorniConsegna }}
          gg - Tariffa: {{ rate.tariffa | currency:rate.valutaTariffa }}</span>

      </mat-list-option>

    </mat-selection-list>

    <ng-template #noRatesTemplate>

      <p>{{"CT_SHIPMENT_DEPOSITS.noFeeAvailable " | translate}}</p>

    </ng-template>

  </ng-template>

</div>

<div style="min-height: 52px" mat-dialog-actions align="end">

  <ct-button *ngIf="options?.selectedOptions?.hasValue()" [configuration]="submitButton"></ct-button>

</div>
