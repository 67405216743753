import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MwDatatableComponent } from "./mw-datatable.component";
import { CtCardModule, CTDatatableModule } from "@ctsolution/ct-framework";
import { FlexLayoutModule } from "@angular/flex-layout";
import { TableModule } from "primeng/table";
import { InputTextModule } from "primeng/inputtext";

@NgModule({
  declarations: [
    MwDatatableComponent
  ],
  imports: [
    CommonModule,
    CTDatatableModule,
    FlexLayoutModule,
    CtCardModule,
    TableModule,
    InputTextModule
  ],
  exports: [
    MwDatatableComponent
  ]
})
export class MwDatatableModule {
}
