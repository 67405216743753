export enum TipodestinazioneEnum {

  destinazioneBusiness = "destinazioneBusiness",
  destinazionePrivate = "destinazionePrivate"

}

export enum TipodepositanteEnum {

  depositanteBusiness = "DepositanteBusiness",
  depositantePrivate = "DepositantePrivate"

}
