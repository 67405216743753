<div class="package-composer-container">

  <div [hidden]="isPalletComposer">

    <mat-card class="radio-package-add-container m-b-40">

      <mat-card-content>

        <app-package-type-selection #packageTypeSelectionComponent></app-package-type-selection>

      </mat-card-content>

    </mat-card>

  </div>

  <form [formGroup]="form">

    <div
      formArrayName="packages"
      *ngFor="let package of packages.controls; let i = index; let first = first;">

      <form [formGroup]="package">

        <hr *ngIf="!first" />

        <div class="m-b-5" fxLayoutAlign="space-between center">

          <div [hidden]="!isPalletComposer">

            <div fxLayout="row" fxLayoutAlign="start center">

              <img height="52" class="m-r-5"
                   [src]="packageTypeIcon(package?.value?.modalitaStoccaggio)">

              <h3> {{ packageTypeName(package?.value?.modalitaStoccaggio) + (isPalletComposer ? ' ' + (i + 1) : '') }} </h3>

            </div>

          </div>

          <div>

            <button
              *ngIf="moreThanOnePackage && isPalletComposer"
              mat-icon-button
              class="mw-icon-button remove"
              (click)="removePackage(i)">
              <mat-icon>close</mat-icon>
            </button>

          </div>

        </div>

        <div>

          <app-box-composer
            #boxComposer
            [isPalletComposer]="isPalletComposer"
            [configuration]="configuration.boxComposerConfiguration"
            (onRemove)="removePackage(i)"
            [formParent]="package"></app-box-composer>

        </div>

        <div
          [hidden]="!isPalletComposer">

          <div
            class="m-b-5"
            fxLayout="row wrap">

            <div fxFlex="100" [fxFlex.gt-md]="25">

              <app-qta-control
                [configuration]="qtaControlService.getControlConfiguration(package.get('quantita'), 'CT_GENERAL.pallet-count')"></app-qta-control>

            </div>

            <div fxFlex="100" [fxFlex.gt-md]="75" align="end">

              <button
                mat-button
                class="general-button m-t-10"
                style="min-height: 48px"
                (click)="boxComposer.addWine()"> {{ 'CT_ACTIONS.add-row' | translate }}
              </button>

            </div>

          </div>

        </div>

        <!-- segnaposto per lo scroll del nuovo pallet-->
        <div #scrollReferenceElement></div>

      </form>

    </div>

  </form>

</div>
