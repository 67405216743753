import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { UnpackPalletDialogComponent } from "./unpack-pallet-dialog.component";
import { MatLegacyDialogModule } from "@angular/material/legacy-dialog";
import { UnpackPalletDialogService } from "./unpack-pallet-dialog.service";
import { CtButtonModule } from "@ctsolution/ct-framework";
import { FlexModule } from "@angular/flex-layout";
import { MatIconModule } from "@angular/material/icon";
import { MatLegacyButtonModule } from "@angular/material/legacy-button";
import { TranslateModule } from "@ngx-translate/core";
import { QtaControlModule } from "../../../../components/qta-control/qta-control.module";
import { ReactiveFormsModule } from "@angular/forms";


@NgModule({
  declarations: [UnpackPalletDialogComponent],
  imports: [
    CommonModule,
    MatLegacyDialogModule,
    CtButtonModule,
    FlexModule,
    MatIconModule,
    MatLegacyButtonModule,
    TranslateModule,
    QtaControlModule,
    ReactiveFormsModule
  ],
  providers: [
    UnpackPalletDialogService
  ]
})
export class UnpackPalletDialogModule {
}
