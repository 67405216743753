<mw-datatable
  *ngIf="listConfiguration"
  class="shipment-datatable-container"
  #table
  [configuration]="listConfiguration"
  (onSave)="addShipment()"
  (onDetail)="detail($event)">

  <ng-template #legendTemplate>

    <div class="legend-container">

      <app-legend [stati]="shipmentService.statiSpedizione"></app-legend>

    </div>

  </ng-template>

  <ng-template #body let-value="value">

    <td>

      <app-bullet-state
        *ngIf="value?.stato"
        [state]="value?.stato"></app-bullet-state>

    </td>

    <td>
      {{ value?.codice ?? '' }}
    </td>

    <td>
      
      <div style="display: flex">

        <img lazy
             height="30"
             style="margin-top: auto; margin-bottom: auto; margin-right: 5px;"
             [src]="getDepositIcon(value?.pallets[0] ? 'Pallet' : value?.scatole[0] ? 'Scatola' : value?.confezioni[0] ? 'Confezione' : '')">
             <span>{{ contenutoSpedizione(value) }}</span>

      </div>

    </td>

    <td>
      {{ value?.destinatario?.nome ?? '' }}
    </td>

    <td>
      {{ value?.dataCreazione ? (value?.dataCreazione | date) : '' }}
    </td>

    <td>
      {{ value?.destinatario?.indirizzo?.via }}, {{ value?.destinatario?.indirizzo?.comune }}
      , {{ value?.destinatario?.indirizzo?.cap }}, {{ value?.destinatario?.indirizzo?.provincia }}
    </td>

  </ng-template>

</mw-datatable>
