import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ShipmentCreateStep2Component } from "./shipment-create-step2.component";
import { FlexModule } from "@angular/flex-layout";
import { MatLegacyButtonModule } from "@angular/material/legacy-button";
import { MatLegacyCardModule } from "@angular/material/legacy-card";
import { TranslateModule } from "@ngx-translate/core";
import { MatStepperModule } from "@angular/material/stepper";
import { ShipmentAddressFormModule } from "../../../../components/shipment-address-form/shipment-address-form.module";

@NgModule({
  declarations: [
    ShipmentCreateStep2Component
  ],
  imports: [
    CommonModule,
    FlexModule,
    MatLegacyButtonModule,
    MatLegacyCardModule,
    TranslateModule,
    MatStepperModule,
    ShipmentAddressFormModule
  ],
  exports: [
    ShipmentCreateStep2Component
  ]
})
export class ShipmentCreateStep2Module {
}
