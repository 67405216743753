import { Component, Input } from "@angular/core";
import { CtButtonConfiguration } from "@ctsolution/ct-framework";
import { MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";

@Component({
  selector: "app-confirm",
  templateUrl: "./confirm.component.html"
})
export class ConfirmComponent {

  @Input() title?: string;
  @Input() description?: string;

  submitButton: CtButtonConfiguration = CtButtonConfiguration
    .create()
    .setLabel("CONFIRM_DIALOG.accept")
    .setClass("general-button accept-confirm")
    .setAction(() => this.dialogRef.close(true));

  cancelButton: CtButtonConfiguration = CtButtonConfiguration
    .create()
    .setLabel("CONFIRM_DIALOG.cancel")
    .setClass("general-button cancel-confirm")
    .setAction(() => this.dialogRef.close());

  constructor(private dialogRef: MatDialogRef<ConfirmComponent>) {
  }

  close() {

    this.dialogRef.close();

  }

}
