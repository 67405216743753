import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ShippingBoxSelectionComponent } from "./shipping-box-selection.component";
import { CtButtonModule } from "@ctsolution/ct-framework";
import { FlexModule } from "@angular/flex-layout";
import { MatIconModule } from "@angular/material/icon";
import { MatLegacyButtonModule } from "@angular/material/legacy-button";
import { MatLegacyDialogModule } from "@angular/material/legacy-dialog";
import { TranslateModule } from "@ngx-translate/core";
import { WineStockDatatableModule } from "../../../stock/wine-stock/wine-stock-datatable/wine-stock-datatable.module";


@NgModule({
  declarations: [
    ShippingBoxSelectionComponent
  ],
  imports: [
    CommonModule,
    CtButtonModule,
    FlexModule,
    MatIconModule,
    MatLegacyButtonModule,
    MatLegacyDialogModule,
    TranslateModule,
    WineStockDatatableModule
  ]
})
export class ShippingBoxSelectionModule {
}
