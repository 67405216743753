import { Component, Input } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { IndirizzoSpedizioneModel } from "../../../core/classes/spedizione/indirizzo";
import { CtFormConfiguration } from "@ctsolution/ct-framework";
import { RecipientContactDataFormConfiguration } from "./recipient-contact-data-form.configuration";

@Component({
  selector: "app-recipient-contact-data-form",
  template: `
    <ct-form [configuration]="formConfiguration"></ct-form>`
})
export class RecipientContactDataFormComponent {

  form: FormGroup;
  formConfiguration: CtFormConfiguration;
  @Input() enabledDisabled: boolean = true;

  constructor(private formBuilder: FormBuilder) {

    this.form = this.formBuilder.group({});
    this.formConfiguration = CtFormConfiguration.create(this.form, RecipientContactDataFormConfiguration);

  }

  ngAfterViewInit() {

  }

  setupValues(value: IndirizzoSpedizioneModel | null | undefined) {
    
    if(value)
    {
      this.form.patchValue(value);
      if(this.enabledDisabled)
        this.formConfiguration.controls?.forEach(cntrl => cntrl.setDisabled(true));
    }
    else
    {

      this.form.reset();
      if(this.enabledDisabled)
        this.formConfiguration.controls?.forEach(cntrl => cntrl.setDisabled(false));
    }
    
        


  }


}
