<div class="deposit-create-cntr">

  <mat-stepper [linear]="true" (selectionChange)="onSelectionChange($event)">

    <mat-step [stepControl]="step1.form">

      <app-deposit-create-step1 #step1></app-deposit-create-step1>

    </mat-step>

    <mat-step [stepControl]="step2.form">

      <app-deposit-create-step2 #step2></app-deposit-create-step2>

    </mat-step>

    <mat-step [stepControl]="step3.form">

      <app-deposit-create-step3 #step3></app-deposit-create-step3>

    </mat-step>

    <mat-step [stepControl]="step4.form">

      <app-deposit-create-step4 #step4 (onSubmit)="submit()"></app-deposit-create-step4>

    </mat-step>

  </mat-stepper>

</div>
