export enum ModalitaStoccaggio {
  Intero = "Intero",
  Bottiglia = "Bottiglia",
  Scatola = "Scatola",
  Pallet = "Pallet"
}

/*
* @dam ho lasciato solo l'enum ModalitaStoccaggio, in quanto tutti gli enum definiti da Luca usano gli stessi valori e sono tutti in stringa, anche se gli enum da Swagger
* hanno nomenclature diverse TipoImballo (Pallet, Scatola) e ModalitaStoccaggio(Intero,Pallet,Scatola,Bottiglia), ho ritenuto che quello più completo che possiamo usare sia
* ModalitaStoccaggio
**/
