import { ChangeDetectorRef, Component, Input } from "@angular/core";
import { MwLookupControlConfiguration } from "../../../../components/mw-lookup-control/mw-lookup-control.configuration";
import { AbstractControl, FormControl } from "@angular/forms";
import { ProdottoModel } from "../../../../core/classes/prodotto";
import { ViniApolloResult } from "../../../../core/interfaces/apollo/vini.apollo-result";
import { QueriesService } from "../../../../core/lib/queries.service";
import { CtControlTypes, CtControlValidator } from "@ctsolution/ct-framework";

@Component({
  selector: "app-product-lookup-control",
  template: `
    <mw-lookup-control [configuration]="configuration"></mw-lookup-control>`
})
export class ProductLookupControlComponent {

  @Input() control: FormControl | AbstractControl | any | null = null;

  configuration: MwLookupControlConfiguration<ProdottoModel, ViniApolloResult> = MwLookupControlConfiguration
    .create<ProdottoModel, ViniApolloResult>()
    .setOptionDescriptionFn(value => value?.descrizione ?? "")
    .setResponseMapper(value => value.vini.nodes)
    .setQuery(this._queries.entities.vini)
    .setOrder([
      {
        descrizione: "ASC"
      }
    ])
    .configureControl(control => {

      control
        ?.setLabel("CT_GENERAL.item-code")
        ?.setType(CtControlTypes.LOOKUP);

    });

  constructor(private cdr: ChangeDetectorRef, private _queries: QueriesService) {
  }

  ngOnInit() {
  }

  ngAfterViewInit() {

    if (this.control) {

      this.configuration
        .CTControl
        ?.setControl(this.control)
        .setValidators([CtControlValidator.create({ name: "required" } as CtControlValidator)]);

    }

      this.cdr.detectChanges();

  }

}
