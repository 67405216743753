import { Injectable } from "@angular/core";
import { MatLegacyDialog } from "@angular/material/legacy-dialog";
import { ShipmentAddressFormComponent } from "./shipment-address-form.component";
import { IndirizzoSpedizioneModel } from "../../core/classes/spedizione/indirizzo";
import { QueriesService } from "../../core/lib/queries.service";
import { BaseController } from "../../core/controllers/base.controller";
import { ShipmentAddressFormConfiguration } from "./shipment-address-form.configuration";
import { IndirizzoSpedizioneApolloResult } from "../../core/interfaces/apollo/indirizzo-spedizione.apollo-result";
import { ShipmentAddressControllerRequest } from "../../core/controllers/controllers";
import { IndirizzoModel } from "../../core/classes/indirizzo";
import { DestinazioneModel } from "../../core/classes/destinazione";
import { ApolloVariableClass } from "../../core/classes/apollo/apollo.variable";
import { BaseApolloQueryClass } from "../../core/classes/apollo/apollo.base.query";
import { IndirizziSpedizioneApolloResult } from "../../core/interfaces/apollo/indirizzi-spedizione.apollo-result";

@Injectable({
  providedIn: "root"
})
export class ShipmentAddressFormService {

  constructor(private dialog: MatLegacyDialog, private _queries: QueriesService, private baseController: BaseController) {
  }

  private openFormDialog(value: IndirizzoSpedizioneModel | null,
                         params?: {
                           cssClass?: string,
                           minWidth?: number | string,
                           width?: number | string,
                           maxWidth?: number | string
                         }) {

    const data: ShipmentAddressFormConfiguration = ShipmentAddressFormConfiguration
      .create()
      .setValues(value);

    return this.dialog
      .open(ShipmentAddressFormComponent, {
        data,
        panelClass: params?.cssClass || "entity-form-dialog",
        disableClose: true,
        width: <string | undefined>params?.width,
        minWidth: <string | undefined>params?.minWidth,
        maxWidth: <string | undefined>params?.maxWidth
      });

  }

  async setupShipmentAddress(dataSource: IndirizzoSpedizioneModel | null = null): Promise<boolean | null | undefined> {

    if (dataSource) {

      let response = await this.getAddressData(dataSource.id);

      if (!response && !dataSource.destinazione) return null;

      dataSource = IndirizzoSpedizioneModel.create(response || dataSource);

    }

    return this.createOrUpdateByEntityForm(dataSource);

  }

  async createOrUpdateByEntityForm(dataSource: IndirizzoSpedizioneModel | null): Promise<boolean | null | undefined> {

    return new Promise((resolve) => {

      this.openFormDialog(dataSource, { maxWidth: "500px" })
        .afterClosed()
        .subscribe(async (result: boolean | null | undefined) => resolve(result));

    });

  }

  createOrUpdate(value: {
    contatto: IndirizzoSpedizioneModel | null,
    indirizzo: IndirizzoModel,
    customer: DestinazioneModel
  }, id: string | null = null) {

    const updatedResult = this.processIndirizzoSpedizioneResult(value);

    const parameter = ShipmentAddressControllerRequest();

    parameter
      .setAction(value.customer?.id)
      .setId(id ?? null);

    return this.baseController
      .createOrUpdate<IndirizzoSpedizioneModel>(updatedResult, parameter);

  }

  private processIndirizzoSpedizioneResult(result: {
    contatto: IndirizzoSpedizioneModel | null,
    indirizzo: IndirizzoModel,
    customer: DestinazioneModel
  }): IndirizzoSpedizioneModel {

    const updatedValue = IndirizzoSpedizioneModel
      .create()
      .setupByForm(result);

    return updatedValue;

  }

  async getAddressData(indirizzoSpedizioneId: string | null): Promise<IndirizzoSpedizioneModel | null> {

    if (!indirizzoSpedizioneId) return null;

    const query = await this._queries.entities.spedizione();

    if (!query) return null;

    const caller = await this.baseController.get<IndirizzoSpedizioneApolloResult>(indirizzoSpedizioneId, query);

    if (!caller) return null;

    try {

      const result = await new Promise<IndirizzoSpedizioneApolloResult>((resolve, reject) => {

        caller
          .subscribe({
            next: (data) => resolve(data),
            error: (error) => reject(error)
          });

      });

      return result?.indirizzoSpedizione ?? null;

    } catch (error) {

      // Gestire eventuali errori qui
      console.error(error);
      return null;

    }

  }

  async getCustomerAddresses(destinazioneId: string | null) {

    const variables: ApolloVariableClass = ApolloVariableClass.create();

    variables
      .setWhereAndFilters([{ destinazione: { id: { eq: destinazioneId } } }]);

    const query = await this._queries.entities.spedizione();

    if (!query) return null;

    const parameter = BaseApolloQueryClass
      .create()
      .setVariables(variables)
      .setQuery(query);

    try {

      const caller = await this.baseController.list<IndirizziSpedizioneApolloResult>(parameter);

      const result = await new Promise<IndirizziSpedizioneApolloResult>((resolve, reject) => {

        caller
          ?.subscribe({
            next: (data) => resolve(data),
            error: (error) => reject(error)
          });

      });

      return result?.indirizziSpedizione.nodes ?? null;

    } catch (error) {

      // Gestisci gli errori qui
      console.error("Errore durante la richiesta dei dati:", error);

    }

  }

}
