<div
  *ngFor="let packageForm of (collection?.controls ?? []); let i = index;">

  <div fxLayout="row" fxLayoutAlign="start center m-b-20">

    <img height="52" class="package-icon m-r-5" [src]="configuration?.packageTypeIcon">

    <h3> {{ (packageForm?.value?.package?.quantita ? (packageForm?.value?.package?.quantita + ' x') : '') }} Pallet </h3>

  </div>

  <div class="recap-selected-items-panel">

    <app-section-detail-data
      *ngFor="let wineConfiguration of getPackageValues(packageForm)"
      [configuration]="depositCollectionPackagingService.getSectionDetailConfiguration(wineConfiguration)"></app-section-detail-data>

  </div>

  <div
    [hidden]="!configuration?.needsImballoRitiro">

    <app-collection-form [configuration]="configuration" [packageForm]="packageForm"></app-collection-form>

  </div>

</div>
