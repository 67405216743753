import { Component, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ShippingMethodSelectorComponent } from "./shipping-methods-selector/shipping-method-selector.component";

@Component({
  selector: "app-deposit-create-step3",
  templateUrl: "./deposit-create-step3.component.html",
  styleUrls: ["./deposit-create-step3.component.scss"]
})
export class DepositCreateStep3Component {

  @ViewChild("shippingMethodSelector") shippingMethodSelector: ShippingMethodSelectorComponent | null = null;

  form: FormGroup;

  constructor(private formBuilder: FormBuilder) {

    this.form = this.formBuilder.group({});

  }

  ngAfterViewInit() {

    this.form
      .addControl("depositType", this.shippingMethodSelector?.control);

  }

}
