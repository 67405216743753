<form [formGroup]="form">

  <p class="m-t-20 m-b-0"><strong [innerHTML]='"Cambio stato" | translate'></strong></p>
  <p class="m-t-0 m-b-20"> Qui puoi selezionare il nuovo stato del deposito: </p>

  <mat-form-field>
    <mat-label>Stato:</mat-label>
    <mat-select formControlName="state">
      <mat-option
        *ngFor="let depositState of DepositStateKeys"
        [value]="depositState"> {{ stateService.getStateLabel(depositState) }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <p class="m-t-20 m-b-0"><strong [innerHTML]='"Note di cambiamento stato" | translate'></strong></p>
  <p class="m-t-0 m-b-20"> Qui puoi inserire eventuali note o commenti riguardanti il cambio di stato del deposito, se
    necessario. </p>

  <app-note-control #stateChangeNoteComponent></app-note-control>

</form>
