import { Component, Input } from "@angular/core";
import { MwCardRadioGroupConfiguration } from "./mw-card-radio-group.configuration";

@Component({
  selector: "mw-card-radio-group",
  template: `
    <mat-radio-group
      class="mw-card-radio-group"
      [ngClass]="configuration?.class"
      [formControl]="configuration?.control"
      fxLayout="row wrap">

      <div
        *ngFor="let option of (configuration?.options ?? [])"
        [fxFlex.gt-md]="100/((configuration?.options?.length > 4 ? 4 : configuration?.options?.length) ?? 1)"
        fxFlex="100"
        class="card-container" [class.selected]="option.value === configuration?.control?.value">

        <mw-card-radio-option
          [configuration]="option"
          (onSelect)="configuration?.control?.setValue($event)"></mw-card-radio-option>

      </div>

    </mat-radio-group>
  `,
  styleUrls: ["./mw-card-radio-group.component.scss"]
})
export class MwCardRadioGroupComponent<T> {

  @Input() configuration: MwCardRadioGroupConfiguration<T> | null = null;

}
