import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DepositCreateStep2Component } from "./deposit-create-step2.component";
import { ReactiveFormsModule } from "@angular/forms";
import { MatStepperModule } from "@angular/material/stepper";
import { MatLegacyButtonModule } from "@angular/material/legacy-button";
import { PackageComposerModule } from "./package-composer/package-composer.module";
import { TranslateModule } from "@ngx-translate/core";

@NgModule({
  declarations: [
    DepositCreateStep2Component
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatStepperModule,
    MatLegacyButtonModule,
    PackageComposerModule,
    TranslateModule
  ],
  exports: [
    DepositCreateStep2Component
  ]
})
export class DepositCreateStep2Module {
}
