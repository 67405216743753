import {
  CtControlConfiguration, CtControlTypes,
  CtFormConfiguration,
  Dictionary
} from "@ctsolution/ct-framework";
import { CustomerControls } from "./customer.controls";

const GenericCustomerControls: CtControlConfiguration[] = [
  <CtControlConfiguration>{
    name: "nome",
    label: "CT_DATATABLE.CLIENTRECORDS.FullName",
    flexSpacePercentage: 100,
    flexSpaceBreakpoints: <Dictionary<number>>{
      xs: 100,
      sm: 100
    },
    validators: [
      { name: "required" },
      { name: "min", value: 0 },
      { name: "maxlength", value: 20 }
    ]
  },
];

export const GenericCustomerData = (): CtFormConfiguration => <CtFormConfiguration>{
  controls: [...GenericCustomerControls, ...CustomerControls]
};
