import { SectionDetailDataConfiguration } from "./section-detail-data/section-detail-data.configuration";

export class ModelDetailConfiguration {

  title: string | null = null;
  sections: Array<SectionDetailDataConfiguration> = [];

  public static create = (): ModelDetailConfiguration => new ModelDetailConfiguration();

  setTitle(value: string | null) {

    this.title = value;
    return this;

  }

  addSection(value: SectionDetailDataConfiguration) {

    this.sections
      .push(value);

    return this;

  }

  addSections(value: SectionDetailDataConfiguration[]) {

    this.sections
      .push(...value);

    return this;

  }

  setSections(value: Array<SectionDetailDataConfiguration>) {

    this.sections = value;
    return this;

  }

}
