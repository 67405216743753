import { Injectable } from "@angular/core";
import { MatLegacyDialog } from "@angular/material/legacy-dialog";
import { DestinazioneModel } from "../../../../core/classes/destinazione";
import { ProfileUpdateDialogComponent } from "./profile-update-dialog.component";

@Injectable()
export class ProfileUpdateDialogService {

  constructor(private dialog: MatLegacyDialog) {
  }

  open(model: DestinazioneModel | null) {

    this.dialog
      .open(ProfileUpdateDialogComponent, { data: model , disableClose: true});

  }

}
