import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AddressDataFormComponent } from "./address-data-form.component";
import { AddressControlComponent } from "./address-control/address-control.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FlexModule } from "@angular/flex-layout";
import { CtControlModule } from "@ctsolution/ct-framework";
import { ComuneControlComponent } from "./comune-control/comune-control.component";
import { CapControlComponent } from "./cap-control/cap-control.component";
import { ProvinciaControlComponent } from "./provincia-control/provincia-control.component";
import { PaeseControlModule } from "../../paese-control/paese-control.module";

@NgModule({
  declarations: [
    AddressDataFormComponent,
    AddressControlComponent,
    ComuneControlComponent,
    CapControlComponent,
    ProvinciaControlComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexModule,
    CtControlModule,
    PaeseControlModule
  ],
  exports: [AddressDataFormComponent]
})
export class AddressDataFormModule {
}
