import { Component, EventEmitter, Input, Output } from "@angular/core";
import { StatoDepositoEnum } from "../../../core/enum/stato-deposito.enum";
import { StateService } from "../deposit-state-change-dialog/state.service";

@Component({
  selector: "app-deposit-state-select-filter",
  template: `
    <p-dropdown
      [options]="statiDeposito"
      [(ngModel)]="value"
      [showClear]="true"
      (onChange)="onChange.emit($event.value)"
      placeholder="Cerca per stato deposito">

      <ng-template pTemplate="selectedItem">
        <div class="dropdown-option" *ngIf="value">
          <app-bullet-state [state]="value"></app-bullet-state>
          <div>{{ stateService.getStateLabel(value) }}</div>
        </div>
      </ng-template>
      <ng-template let-state pTemplate="item">
        <div class="dropdown-option">
          <app-bullet-state [state]="state"></app-bullet-state>
          <div>{{ stateService.getStateLabel(state) }}</div>
        </div>
      </ng-template>
    </p-dropdown>`,
  styles: [`
    .dropdown-option {
      display: flex;
      align-items: center;

      div {
        margin-left: 5px;
      }
    }
  `]
})
export class DepositStateSelectFilterComponent {

  @Input() value: StatoDepositoEnum | null = null;
  @Output() onChange: EventEmitter<StatoDepositoEnum | null> = new EventEmitter<StatoDepositoEnum | null>();

  statiDeposito = Object.keys(StatoDepositoEnum).map(key => (<any>StatoDepositoEnum)[key]);

  constructor(public stateService: StateService) {
  }

}
