<mat-form-field appearance="fill">
  <mat-label>Periodo:</mat-label>
  <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
    <input matStartDate formControlName="start" placeholder="Inizio">
    <input matEndDate formControlName="end" placeholder="Fine">
  </mat-date-range-input>
  <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-date-range-picker #picker></mat-date-range-picker>

  <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid') || range.controls.start.hasError('required')">
    Data di inizio non valida
  </mat-error>
  <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid') || range.controls.end.hasError('required')">Data
    di fine non valida
  </mat-error>
</mat-form-field>
