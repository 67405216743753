<form [formGroup]="form">

  <div fxLayout="row wrap">

    <div fxFlex.gt-md="100" fxFlex="100">

      <mw-address-control></mw-address-control>

    </div>

    <div fxFlex.gt-md="37.5" fxFlex="100">

      <mw-comune-control></mw-comune-control>

    </div>

    <div fxFlex.gt-md="37.5" fxFlex="100">

      <mw-cap-control></mw-cap-control>

    </div>

    <div fxFlex.gt-md="25" fxFlex="100">

      <mw-provincia-control></mw-provincia-control>

    </div>

    <div fxFlex.gt-md="100" fxFlex="100">

      <mw-paese-control></mw-paese-control>

    </div>

  </div>

</form>
