import { Component } from "@angular/core";
import {
  MatLegacyDialogRef as MatDialogRef
} from "@angular/material/legacy-dialog";

@Component({
  selector: "app-shipping-pallet-selection",
  templateUrl: "./shipping-pallet-selection.component.html",
  styles: [`::ng-deep .shipping-package-selection-container {
    .ct-datatable-caption-intro {
      width: 100%;

      .p-input-icon-left {
        width: 95%;
      }
    }
  }`]
})
export class ShippingPalletSelectionComponent {

  constructor(private dialogRef: MatDialogRef<any>) {
  }

  close() {

    this.dialogRef.close();

  }

}
