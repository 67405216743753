import { Component, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { DepositPackagingSelectorComponent } from "./deposit-packaging-selector/deposit-packaging-selector.component";

@Component({
  selector: "app-deposit-create-step1",
  templateUrl: "./deposit-create-step1.component.html"
})
export class DepositCreateStep1Component {

  @ViewChild("depositPackagingSelector") depositPackagingSelector: DepositPackagingSelectorComponent | null = null;

  form: FormGroup;

  constructor(private formBuilder: FormBuilder) {

    this.form = this.formBuilder.group({});

  }

  ngAfterViewInit() {

    this.form
      .addControl("depositPackagingType", this.depositPackagingSelector?.control);

  }

}
