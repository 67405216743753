import { CTDatatableFilterConfiguration, CTDatatableHeaderCellConfiguration } from "@ctsolution/ct-framework";
import { TranslateService } from "@ngx-translate/core";

export const depositoHeader = (translate: TranslateService) : CTDatatableHeaderCellConfiguration => CTDatatableHeaderCellConfiguration
  .create()
  .setField("deposito")
  .setLabel(translate.instant("CT_DATATABLE.MOVEMENT.Deposit"))
  .setInlineStyle("min-width: 200px")
  .setSortable(true)
  .setFilter(
    CTDatatableFilterConfiguration
      .create()
      .setPlaceHolder(translate.instant("CT_DATATABLE.MOVEMENT.DepositSearch"))
      .setShowMenu(true));

export const spedizioneHeader = (translate: TranslateService) : CTDatatableHeaderCellConfiguration => CTDatatableHeaderCellConfiguration
  .create()
  .setField("spedizione")
  .setLabel(translate.instant("CT_DATATABLE.MOVEMENT.Shipment"))
  .setSortable(true)
  .setFilter(
    CTDatatableFilterConfiguration
      .create()
      .setPlaceHolder(translate.instant("CT_DATATABLE.MOVEMENT.ShipmentSearch"))
      .setShowMenu(true));

export const palletHeader = (translate: TranslateService) : CTDatatableHeaderCellConfiguration => CTDatatableHeaderCellConfiguration
  .create()
  .setField("pallet")
  .setLabel(translate.instant("CT_DATATABLE.MOVEMENT.Pallet"))
  .setSortable(true)
  .setFilter(
    CTDatatableFilterConfiguration
      .create()
      .setPlaceHolder(translate.instant("CT_DATATABLE.MOVEMENT.PalletSearch"))
      .setShowMenu(true));
