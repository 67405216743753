import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ProductLookupControlComponent } from "./product-lookup-control.component";
import { MwLookupControlModule } from "../../../../components/mw-lookup-control/mw-lookup-control.module";

@NgModule({
  declarations: [
    ProductLookupControlComponent
  ],
  imports: [
    CommonModule,
    MwLookupControlModule
  ],
  exports: [ProductLookupControlComponent]
})
export class ProductLookupControlModule {
}