<div class="deposit-create-step2-container">

  <form [formGroup]="form">

    <app-package-composer
      #packageComposer
      [configuration]="packageComposerConfiguration"></app-package-composer>

  </form>

  <hr>

  <div class="buttons-stepper m-t-40">

    <div>

      <button class="general-button" mat-button matStepperPrevious>{{ 'CT_GENERAL.BACK' | translate }}</button>

    </div>

    <div>

      <button
        *ngIf="packageComposer.isPalletComposer"
        mat-button
        class="general-button m-r-15"
        (click)="packageComposer.addPackage()">
        {{ 'CT_GENERAL.add-pallet' | translate }}
      </button>

      <button
        class="general-button"
        mat-button
        matStepperNext
        *ngIf="(packageComposer.form.getRawValue()?.packages ?? []).length"
        (click)="packageComposer.form.markAllAsTouched()"
      >{{ 'CT_GENERAL.NEXT' | translate }}
      </button>

    </div>


  </div>

</div>
