import { Component, EventEmitter, Output } from "@angular/core";
import { TipoSpedizioneEnum } from "../../../../../core/enum/tipo-spedizione.enum";
import { FormArray, FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { QtaControlService } from "../../../../../components/qta-control/qta-control.service";
import { CtControlValidator, SnackbarService } from "@ctsolution/ct-framework";
import { ShipmentCollectionConfiguration } from "./shipment-collection.configuration";
import { debounceTime, distinctUntilChanged } from "rxjs";
import { ShipmentCreateWizardService } from "../../shipment-create-wizard.service";
import { GiacenzaVinoModel } from "../../../../../core/interfaces/giacenza-vino.model";
import {
  ProductStockLookupControlService
} from "../../../../../components/product-stock-lookup-control/product-stock-lookup-control.service";

@Component({
  selector: "app-shipment-collection",
  templateUrl: "./shipment-collection.component.html",
  styleUrls: ["./shipment-collection.component.scss"]
})
export class ShipmentCollectionComponent {

  form: FormGroup;
  @Output() editCustomBox: EventEmitter<ShipmentCollectionConfiguration> = new EventEmitter<ShipmentCollectionConfiguration>();

  constructor(
    private formBuilder: FormBuilder,
    private snackbar: SnackbarService,
    public qtaControlService: QtaControlService,
    private shipmentCreateWizardService: ShipmentCreateWizardService,
    private productStockLookupControlService: ProductStockLookupControlService) {

    this.form = this.formBuilder.group({
      collection: this.formBuilder.array([])
    });

  }

  get collection() {

    return this.form.get("collection") as FormArray;

  }

  addToCollection(configuration: ShipmentCollectionConfiguration | null) {

    if (!configuration) return;

    // check se esiste già una collection con la configurazione in ingresso, in quel caso devo solo aggiornare la qta

    const matchingFormGroup = this.collection
      .controls
      .filter(formGroup => {

        const currentAttributes = formGroup.value?.data?.attributes;
        const configurationAttributes = configuration.attributes;

        switch (configuration.tipoSpedizione) {

          case TipoSpedizioneEnum.GiacenzaPallet:
            return currentAttributes.giacenzaPalletId === configurationAttributes.giacenzaPalletId;

          case TipoSpedizioneEnum.GiacenzaScatola:
            return currentAttributes.prodottoId === configurationAttributes.prodottoId;

          default:
            return false;
        }

      })[0]; // Assumiamo che ci sarà al massimo un elemento corrispondente

    if (matchingFormGroup) {

      const currentQty = matchingFormGroup.value?.quantita || 0;

      const quantityControl = matchingFormGroup.get("quantita");
      quantityControl?.setValue(currentQty + 1);

      quantityControl?.markAsTouched();
      quantityControl?.updateValueAndValidity();

    } else {

      const collectionForm = this.formBuilder.group<any>({
        quantita: 1,
        data: new FormControl(configuration)
      });

      const qtaControlConfiguration = this.getControlConfiguration(collectionForm);

      collectionForm
        .addControl("qtaControlConfiguration", new FormControl(qtaControlConfiguration));

      this.collection
        .push(collectionForm);

    }

  }

  removeFromCollection(index: number) {

    this.collection.removeAt(index);

  }

  private getControlConfiguration(form: FormGroup) {

    const data = form.value.data;

    const validators: CtControlValidator[] = [
      CtControlValidator.create(<CtControlValidator>{ name: "required" }),
      CtControlValidator.create(<CtControlValidator>{ name: "min", value: 1 })
    ];

    // quantitaTotaleBottiglie dovrebbe essere valorizzata quando sto spedendo una scatola con un tipo solo di vino
    // maxQuantity dovrebbe essere valorizzato quando sto spedendo pallets
    // in tutti gli altri casi devo calcolare le giacenze in realtime
    let { maxQuantity, quantitaTotaleBottiglie } = data?.attributes || {};

    if (maxQuantity) {

      validators.push(CtControlValidator.create(<CtControlValidator>{ name: "max", value: maxQuantity }));

    }

    const controlLabel = data.tipoSpedizione === TipoSpedizioneEnum.GiacenzaPallet ? "CT_GENERAL.pallet-count" : "CT_GENERAL.packs-count";
    const configuration = this.qtaControlService.getControlConfiguration(form.get("quantita"), controlLabel, validators);

    if (!maxQuantity) {

      configuration
        .control
        .valueChanges
        .pipe(debounceTime(300), distinctUntilChanged())
        .subscribe((currValue: any) => {

          let isAlreadyExceeded = false;

          const sections = ((<ShipmentCollectionConfiguration | null>form.value.data)?.sections ?? []).filter(dd => !!dd.attributes?.prodottoId);

          sections
            .forEach(section => {

              if (isAlreadyExceeded) return; // Se già superato, esce dal loop

              let { prodottoId } = section?.attributes || {};
              const productCountInsideCollection = this.shipmentCreateWizardService.productCountInsideCollection(<GiacenzaVinoModel>{ vino: { id: prodottoId } }, this.collection?.value) ?? 0;

              const stockedProducts = this.productStockLookupControlService.getValues();
              const giacenza = stockedProducts.find(giacenza => giacenza.vino.id === prodottoId);

              if (giacenza) quantitaTotaleBottiglie = giacenza.quantita;

              if (productCountInsideCollection > (quantitaTotaleBottiglie ?? 0)) {

                isAlreadyExceeded = true;

                configuration
                  .control
                  .setValue(currValue - 1);

                this.snackbar.open("Attenzione, il prodotto selezionato ha terminato le giacenze disponibili per questa spedizione!");

                return;

              }

            });

        });


    }

    return configuration;

  }

}
