import { v4 as uuidv4 } from "uuid";
import { GenericReadModel } from "../generic-read.model";

export class ScatolaSpedizione {

  prodotto: GenericReadModel | null = null;

  private constructor(
    public id: string,
    public prodottoId: string,
    public quantita: number
  ) {
  }

  public static create = (prodottoId: string, quantita: number): ScatolaSpedizione => new ScatolaSpedizione(uuidv4(), prodottoId, quantita);

}
