import { DetailDataConfiguration } from "./detail-data.configuration";

export class SectionDetailDataConfiguration {

  dataList: Array<DetailDataConfiguration> = [];
  endSectionDividerEnabled: boolean = false;
  attributes: { [key: string]: any } = {}; // Nuovo campo attributes

  private constructor(public title: string | null = null) {
  }

  public static create = (title: string | null = null): SectionDetailDataConfiguration => new SectionDetailDataConfiguration(title);

  addDetailData(value: DetailDataConfiguration) {

    this.dataList
      .push(value);

    return this;

  }

  setDataList(value: Array<DetailDataConfiguration>) {

    this.dataList = value;
    return this;

  }

  enableEndSectionDivider(value: boolean) {

    this.endSectionDividerEnabled = value;
    return this;

  }

  addAttribute(key: string, value: any) {
    this.attributes[key] = value;
    return this;
  }

}
