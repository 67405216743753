import { CTDatatableFilterConfiguration, CTDatatableHeaderCellConfiguration } from "@ctsolution/ct-framework";
import { TranslateService } from "@ngx-translate/core";

export const codiceHeader = (translate: TranslateService) : CTDatatableHeaderCellConfiguration => CTDatatableHeaderCellConfiguration
  .create()
  .setField("codice")
  .setLabel(translate.instant("CT_DATATABLE.MOSTDEPOSIT.Code"))
  .setInlineStyle("width: 240px;")
  .setSortable(true)
  .setFilter(
    CTDatatableFilterConfiguration
      .create()
      .setPlaceHolder(translate.instant("CT_DATATABLE.SHIPMENT.CodeSearch"))
      .setShowMenu(true));

export const descrizioneHeader = (translate: TranslateService) : CTDatatableHeaderCellConfiguration => CTDatatableHeaderCellConfiguration
  .create()
  .setField("descrizione")
  .setLabel(translate.instant("CT_GENERAL.content"))
  .setInlineStyle("min-width: 350px")
  .setSortable(true)
  .setFilter(
    CTDatatableFilterConfiguration
      .create()
      .setPlaceHolder(translate.instant("CT_GENERAL.contentSearch"))
      .setShowMenu(true));

export const dataPrevistaHeader = (translate: TranslateService) : CTDatatableHeaderCellConfiguration => CTDatatableHeaderCellConfiguration
  .create()
  .setLabel(translate.instant("CT_DATATABLE.MOSTDEPOSIT.DeliveryDetails"));

export const statoHeader = (translate: TranslateService) : CTDatatableHeaderCellConfiguration => CTDatatableHeaderCellConfiguration
  .create()
  .setField("stato")
  .setInlineStyle("width: 100px;")
  .setLabel(translate.instant("CT_DATATABLE.MOSTDEPOSIT.State"));
