import { Injectable } from "@angular/core";
import { StatoDepositoEnum } from "../../../core/enum/stato-deposito.enum";
import { StatoSpedizioneEnum } from "../../../core/enum/stato-spedizione.enum";

@Injectable({
  providedIn: "root"
})
export class StateService {

  constructor() {
  }

  getStateLabel(state: StatoDepositoEnum | StatoSpedizioneEnum | string): string {
    switch (state) {
      case StatoDepositoEnum.Accettazione:
        return "Accettazione";
      case StatoDepositoEnum.Transito:
        return "Transito";
      case StatoDepositoEnum.PresaInCarico:
        return "Presa in carico";
      case StatoDepositoEnum.Depositato:
        return "Depositato";
      case StatoDepositoEnum.Annullato:
        return "Annullato";
      case StatoSpedizioneEnum.Consegnata:
        return "Consegnata";
      case StatoSpedizioneEnum.Annullata:
        return "Annullata";
      default:
        return "";
    }
  }

}
