import { ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChildren } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { BoxComposerConfiguration } from "./box-composer.configuration";
import { ModalitaStoccaggio } from "../../../../../core/enum/modalita-stoccaggio.enum";
import {
  ProductLookupControlComponent
} from "../../../_components/product-lookup-control/product-lookup-control.component";
import { QtaControlService } from "../../../../../components/qta-control/qta-control.service";
import { delay, distinctUntilChanged } from "rxjs";
import { PackageComposerService } from "../package-composer/package-composer.service";

export const defaultBoxBottleCount = 6;

@Component({
  selector: "app-box-composer",
  templateUrl: "./box-composer.component.html",
  styles: [`
    .box-container {
      hr {
        width: 75%;
      }
      .center-items {
        display: flex;
        justify-content: center;
        padding-top: 5px;
      }
    }
  `]
})
export class BoxComposerComponent {

  @Input() formParent: FormGroup | null = null;
  @Input() configuration: BoxComposerConfiguration | null = null;
  @Input() isPalletComposer: boolean = false;

  @Output() onRemove: EventEmitter<number> = new EventEmitter<number>();
  @ViewChildren("productLookupControls") productLookupControls: ProductLookupControlComponent[] | null = null;

  form: FormGroup;
  wines: FormArray<FormGroup>;

  get isBottiglieSfuseComposer() {

    return this.composerChecker(ModalitaStoccaggio.Bottiglia);

  }

  get isScatolaComposer() {

    return this.composerChecker(ModalitaStoccaggio.Scatola);

  }

  packageTypeName(): string {

    const formParentValueModalitaStoccaggio = this.formParent?.value?.modalitaStoccaggio;

    return this.packageComposerService.getPackageTypeTitleByDepositValue(formParentValueModalitaStoccaggio ?? this.configuration?.depositPackagingType ?? null);

  }

  packageTypeIcon(): string {

    const formParentValueModalitaStoccaggio = this.formParent?.value?.modalitaStoccaggio;

    return this.packageComposerService.getPackageTypeIconByDepositValue(formParentValueModalitaStoccaggio ?? this.configuration?.depositPackagingType ?? null);

  }

  composerChecker(modalitaStoccaggio: ModalitaStoccaggio) {

    const formParentValueModalitaStoccaggio = this.formParent?.value?.modalitaStoccaggio;

    return (formParentValueModalitaStoccaggio ?? this.configuration?.depositPackagingType) === modalitaStoccaggio;

  }

  get moreThanOneWine() {

    return this.wines.length > 1;

  }

  constructor(private formBuilder: FormBuilder, private cdr: ChangeDetectorRef, public qtaControlService: QtaControlService, private packageComposerService: PackageComposerService) {

    this.form = this.formBuilder.group({
      wines: this.formBuilder.array([])
    });

    this.wines = this.form.get("wines") as FormArray<FormGroup>;

  }

  ngOnInit() {

    this.addWine();

  }

  ngAfterViewInit() {

    if (this.formParent) {

      this.formParent
        .addControl("box", this.form);

    }

    this.cdr.detectChanges();

  }

  addWine() {

    this.form?.markAllAsTouched();

    if (!this.wines.valid) return;

    const formGroup: FormGroup = this.formBuilder
      .group({
        prodotto: new FormControl(null),
        quantitaBottiglie: new FormControl({
          value: this.isBottiglieSfuseComposer ? 1 : defaultBoxBottleCount,
          disabled: !this.isBottiglieSfuseComposer
        }),
        quantitaScatole: new FormControl(this.isBottiglieSfuseComposer ? null : 1),
        quantitaTotale: new FormControl({ value: null, disabled: true })
      });

    formGroup
      ?.valueChanges
      .pipe(delay(100), distinctUntilChanged())
      .subscribe(() => {

        const value = formGroup.getRawValue();

        const totalQuantity = (value.quantitaBottiglie ?? 1) * (value.quantitaScatole ?? 1);

        const quantitaTotaleControl = formGroup.get("quantitaTotale");

        quantitaTotaleControl?.setValue(totalQuantity, { emitEvent: false });

      });

    this.wines
      .push(formGroup);

    this.cdr.detectChanges();

  }

  removeWine(index: number) {

    if (index >= 0) {

      this.wines.removeAt(index);
      return;

    }

    const firstWineFormGroup = this.wines.controls[0] as FormGroup;

    firstWineFormGroup
      .patchValue({
        prodotto: null,
        quantitaBottiglie: 1,
        quantitaScatole: 1
      });

  }

  removePackage(index: number) {

    this.onRemove.emit();

  }

}


