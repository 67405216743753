<div mat-dialog-title fxLayout="row" fxLayoutAlign="space-between center">

  {{ title | translate }}

  <button mat-icon-button color="primary" (click)="close()">

    <mat-icon>close</mat-icon>

  </button>

</div>

<div mat-dialog-content>

  {{ description | translate }}

</div>

<div mat-dialog-actions fxLayout="row" fxLayoutAlign="space-between center">

  <ct-button [configuration]="cancelButton"></ct-button>

  <ct-button [configuration]="submitButton"></ct-button>

</div>
