import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ShippingMethodSelectorComponent } from "./shipping-method-selector.component";
import { MwCardRadioGroupModule } from "../../../../../components/mw-card-radio-group/mw-card-radio-group.module";

@NgModule({
  declarations: [ShippingMethodSelectorComponent],
  exports: [ShippingMethodSelectorComponent],
  imports: [
    CommonModule,
    MwCardRadioGroupModule
  ]
})
export class ShippingMethodSelectorModule {
}
