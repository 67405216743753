import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CustomersCounterWidgetComponent } from "./customers-counter-widget.component";
import { MatCardModule } from "@angular/material/card";
import { NgApexchartsModule } from "ng-apexcharts";
import { TranslateModule } from "@ngx-translate/core";


@NgModule({
  declarations: [
    CustomersCounterWidgetComponent
  ],
  imports: [
    CommonModule,
    MatCardModule,
    NgApexchartsModule,
    TranslateModule
  ],
  exports: [
    CustomersCounterWidgetComponent
  ]
})
export class CustomersCounterWidgetModule {
}
