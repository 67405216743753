import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";
import { PagesRoutes } from "./pages.routing";
import { DashboardModule } from "./dashboard/dashboard.module";
import { Table, TableService } from "primeng/table";
import { ConfirmationService, MessageService } from "primeng/api";
import { ModelDetailModule } from "../components/model-detail/model-detail.module";

@NgModule({
  imports: [
    CommonModule,
    ModelDetailModule,
    DashboardModule,
    RouterModule.forChild(PagesRoutes)
  ],
  declarations: [],
  providers: [Table, TableService, ConfirmationService, MessageService]
})
export class PagesModule {
}
