import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MeteoWidgetComponent } from "./meteo-widget.component";
import { MatLegacyCardModule } from "@angular/material/legacy-card";
import { MeteoWidgetService } from "./meteo-widget.service";
import {MatIconModule} from "@angular/material/icon";
import {FlexLayoutModule, FlexModule} from "@angular/flex-layout";


@NgModule({
  declarations: [
    MeteoWidgetComponent
  ],
  exports: [
    MeteoWidgetComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
      FlexLayoutModule,
    MatLegacyCardModule,
    FlexModule
  ],
  providers: [MeteoWidgetService]
})
export class MeteoWidgetModule {
}
