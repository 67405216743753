import { Component } from "@angular/core";
import {
  MwLookupControlConfiguration
} from "../../../../../components/mw-lookup-control/mw-lookup-control.configuration";
import { ConfezioneModel } from "../../../../../core/interfaces/confezione.model";
import { ConfezioniApolloResult } from "../../../../../core/interfaces/apollo/confezioni.apollo-result";
import { QueriesService } from "../../../../../core/lib/queries.service";
import { CtControlTypes, CtControlValidator, CtSelectControlOptions } from "@ctsolution/ct-framework";

@Component({
  selector: "app-package-select-control",
  template: `
    <mw-lookup-control #lookupControl [configuration]="configuration"></mw-lookup-control>`

})
export class PackageSelectControlComponent {

  configuration: MwLookupControlConfiguration<ConfezioneModel, ConfezioniApolloResult> = MwLookupControlConfiguration
    .create<ConfezioneModel, ConfezioniApolloResult>()
    .setResponseMapper(value => value.confezioni)
    .setQuery(this._queries.entities.confezioni)
    .setOptionDescriptionFn(value => value.nome ?? "")
    .setOrder([{ nome: "ASC" }])
    .configureControl(control => {

      control
        ?.setName("confezione")
        ?.setLabel("CT_DATATABLE.SHIPMENT.Package")
        ?.setType(CtControlTypes.LOOKUP)
        ?.configureOptions<CtSelectControlOptions>(options => {

          if (!options) options = CtSelectControlOptions.create();

          options
            .setNullValueEnabled(false);

        })
        .setValidators([CtControlValidator.create().setName("required")]);

    });

  constructor(private _queries: QueriesService) {
  }

}
