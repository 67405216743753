import { Component, ViewChild } from "@angular/core";
import { GenericReadModel } from "../../../../../core/classes/generic-read.model";
import { TipologiaModel, TipologieApolloResult } from "../../../../../core/interfaces/apollo/tipologie.apollo-result";
import { CtControlValidator } from "@ctsolution/ct-framework";
import { MwLookupControlComponent } from "../../../../../components/mw-lookup-control/mw-lookup-control.component";
import {
  MwLookupControlConfiguration
} from "../../../../../components/mw-lookup-control/mw-lookup-control.configuration";

@Component({
  selector: "mw-spumante-metodo",
  template: `
    <mw-lookup-control
      #lookupControl
      [configuration]="configuration"></mw-lookup-control>`
})
export class SpumanteMetodoComponent {

  @ViewChild("lookupControl") lookupControl: MwLookupControlComponent<GenericReadModel, TipologieApolloResult> | null = null;

  configuration: MwLookupControlConfiguration<GenericReadModel, TipologieApolloResult> = MwLookupControlConfiguration
    .create<GenericReadModel, TipologieApolloResult>()
    .setAutoInitializeValueOptions(false)
    .setOptionDescriptionFn(value => value?.nome ?? "Unknown")
    .configureControl(control => {

      control
        ?.setLabel("Metodo");

    });

  constructor() {
  }

  setup(model: TipologiaModel, validators: CtControlValidator[] | null = null) {

    this.lookupControl
      ?.setupFetcher({ values: model.metodiSpumante ?? [], validators });

  }

}
