import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AddressUpdateDialogComponent } from "./address-update-dialog.component";
import { AddressUpdateDialogService } from "./address-update-dialog.service";
import { ShipmentAddressFormModule } from "../../../../components/shipment-address-form/shipment-address-form.module";


@NgModule({
  declarations: [
    AddressUpdateDialogComponent
  ],
  imports: [
    CommonModule,
    ShipmentAddressFormModule
  ],
  providers: [
    AddressUpdateDialogService
  ]
})
export class AddressUpdateDialogModule {
}
