import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AddressesDatatableComponent } from "./addresses-datatable.component";
import { MwDatatableModule } from "../../../components/mw-datatable/mw-datatable.module";
import { ShipmentAddressFormModule } from "../../../components/shipment-address-form/shipment-address-form.module";

@NgModule({
  declarations: [
    AddressesDatatableComponent
  ],
  imports: [
    CommonModule,
    MwDatatableModule,
    ShipmentAddressFormModule
  ],
  exports: [
    AddressesDatatableComponent
  ]
})
export class AddressesDatatableModule {
}
