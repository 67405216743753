import { Component } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { DepositType } from "../../../../../core/enum/deposit-type";
import {
  MwCardRadioOptionConfiguration
} from "../../../../../components/mw-card-radio-group/mw-card-radio-option/mw-card-radio-option.configuration";
import {
  MwCardRadioGroupConfiguration
} from "../../../../../components/mw-card-radio-group/mw-card-radio-group.configuration";
import { iconsBasePath } from "../../../../../core/constants";

const ShippingMethodSelectorCardRadioOptionConfigurations: MwCardRadioOptionConfiguration<DepositType>[] = [
  MwCardRadioOptionConfiguration
    .create<DepositType>()
    .setValue(DepositType.most)
    .setImage(`${iconsBasePath}shipping-most.svg`)
    .setLabel(`CT_SHIPMENT_DEPOSITS.shipping_by_most`),
  MwCardRadioOptionConfiguration
    .create<DepositType>()
    .setValue(DepositType.autonoma)
    .setImage(`${iconsBasePath}personal-shipping.svg`)
    .setLabel(`CT_SHIPMENT_DEPOSITS.shipping_by_self`)
];

@Component({
  selector: "app-shipping-method-selector",
  template: `
    <div class="shipping-method-selector-container m-t-40">
      <mw-card-radio-group [configuration]="mwCardRadioGroupConfiguration"></mw-card-radio-group>
    </div>
  `
})
export class ShippingMethodSelectorComponent {

  control: FormControl = new FormControl(DepositType.most, Validators.required);

  mwCardRadioGroupConfiguration: MwCardRadioGroupConfiguration<DepositType> = MwCardRadioGroupConfiguration
    .create<DepositType>()
    .setControl(this.control)
    .setOptions(ShippingMethodSelectorCardRadioOptionConfigurations)
    .enableHorizontalView(true);

  constructor() {
  }

  ngAfterViewInit() {
  }
}
