import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfileUpdateDialogComponent } from './profile-update-dialog.component';
import { CtButtonModule, CtFormModule } from '@ctsolution/ct-framework';
import { ProfileUpdateDialogService } from './profile-update-dialog.service';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AddressDataFormModule } from 'src/app/components/shipment-address-form/address-data-form/address-data-form.module';
import { MatRadioModule } from '@angular/material/radio';

@NgModule({
  imports: [
    CommonModule,
    CtFormModule,
    CtButtonModule,
    FlexLayoutModule,
    AddressDataFormModule,
    MatRadioModule
  ],
  declarations: [ProfileUpdateDialogComponent],
  exports : [ProfileUpdateDialogComponent],
  providers : [ProfileUpdateDialogService]
})
export class ProfileUpdateDialogModule { }
