import { Component, ViewChild } from "@angular/core";
import { GenericReadModel } from "../../core/classes/generic-read.model";
import { QueriesService } from "../../core/lib/queries.service";
import { StatiApolloResult } from "../../core/interfaces/apollo/stati.apollo.result";
import { MwLookupControlComponent } from "../mw-lookup-control/mw-lookup-control.component";
import { MwLookupControlConfiguration } from "../mw-lookup-control/mw-lookup-control.configuration";
import { CtControlTypes, CtSelectControlOptions } from "@ctsolution/ct-framework";

@Component({
  selector: "mw-paese-control",
  template: `
    <mw-lookup-control
      *ngIf="configuration"
      #lookupControl
      [configuration]="configuration"></mw-lookup-control>`
})
export class PaeseControlComponent {

  @ViewChild("lookupControl") lookupControl: MwLookupControlComponent<GenericReadModel, StatiApolloResult> | null = null;

  configuration: MwLookupControlConfiguration<GenericReadModel, StatiApolloResult> | null = MwLookupControlConfiguration
    .create<GenericReadModel, StatiApolloResult>()
    .setQuery(this._queries.entities.stati)
    .setResponseMapper(value => value.stati)
    .setOptionDescriptionFn(value => value?.nome ?? "Unknown")
    .setOrder([{ nome: "ASC" }])
    .configureControl(control => {

      control
        ?.setLabel("CT_DATATABLE.CLIENTRECORDS.State")
        ?.setType(CtControlTypes.LOOKUP)
        ?.configureOptions<CtSelectControlOptions>(options => {

          if (!options) options = CtSelectControlOptions.create();

          options
            .setNullValueEnabled(false);

        });

    });

  constructor(private _queries: QueriesService) {
  }

}
