import { Component } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { ModalitaStoccaggio } from "../../../../../core/enum/modalita-stoccaggio.enum";
import {
  MwCardRadioGroupConfiguration
} from "../../../../../components/mw-card-radio-group/mw-card-radio-group.configuration";
import {
  MwCardRadioOptionConfiguration
} from "../../../../../components/mw-card-radio-group/mw-card-radio-option/mw-card-radio-option.configuration";
import { iconsBasePath } from "../../../../../core/constants";

const DepositPackagingTypeCardRadioOptionConfigurations: MwCardRadioOptionConfiguration<ModalitaStoccaggio>[] = [
  MwCardRadioOptionConfiguration
    .create<ModalitaStoccaggio>()
    .setImage(`${iconsBasePath}pallet.svg`)
    .setValue(ModalitaStoccaggio.Pallet)
    .setLabel(`CT_SHIPMENT_DEPOSITS.pallet`)
    .setDescription("CT_SHIPMENT_DEPOSITS.palletStorage"),
  MwCardRadioOptionConfiguration
    .create<ModalitaStoccaggio>()
    .setValue(ModalitaStoccaggio.Scatola)
    .setImage(`${iconsBasePath}box.svg`)
    .setLabel("CT_SHIPMENT_DEPOSITS.boxes_bottles")
    .setDescription("CT_SHIPMENT_DEPOSITS.boxStorage")
  // MwCardRadioOptionConfiguration
  //   .create<ModalitaStoccaggio>()
  //   .setValue(ModalitaStoccaggio.Bottiglia)
  //   .setImage(`${iconsBasePath}bottles.svg`)
  //   .setLabel(`CT_SHIPMENT_DEPOSITS.no_packaging`)
];

@Component({
  selector: "app-deposit-packaging-selector",
  template: `
    <div class="radio-packaging-selector m-t-40">
      <mw-card-radio-group [configuration]="mwCardRadioGroupConfiguration"></mw-card-radio-group>
    </div>
  `
})
export class DepositPackagingSelectorComponent {

  control: FormControl = new FormControl(ModalitaStoccaggio.Pallet, Validators.required);

  mwCardRadioGroupConfiguration: MwCardRadioGroupConfiguration<ModalitaStoccaggio> = MwCardRadioGroupConfiguration
    .create<ModalitaStoccaggio>()
    .setControl(this.control)
    .setOptions(DepositPackagingTypeCardRadioOptionConfigurations);

  constructor() {
  }

  ngAfterViewInit() {
  }

}
