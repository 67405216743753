import { Injectable } from "@angular/core";
import { KeycloakService } from "keycloak-angular";

@Injectable({
  providedIn: "root"
})
export class RoleService {

  constructor(private _kc: KeycloakService) {
  }

  isAdmin = (): boolean => this._kc.isUserInRole("admin");

}
