import { ChangeDetectorRef, Component, Input } from "@angular/core";
import { AbstractControl, FormControl } from "@angular/forms";
import {
  MwLookupControlConfiguration
} from "../../../../../../components/mw-lookup-control/mw-lookup-control.configuration";
import { CtControlValidator, CtSelectControlOptions } from "@ctsolution/ct-framework";
import { QueriesService } from "../../../../../../core/lib/queries.service";
import { PresetImballoModel } from "../../../../../../core/interfaces/preset-imballo.model";
import { PresetImballiApolloResult } from "../../../../../../core/interfaces/apollo/preset-imballi.apollo-result";

@Component({
  selector: "app-tipologia-imballo-select",
  template: `
    <mw-lookup-control [configuration]="configuration"></mw-lookup-control>`
})
export class TipologiaImballoSelectComponent {

  @Input() control: FormControl | AbstractControl | any | null = null;

  protected configuration: MwLookupControlConfiguration<PresetImballoModel, PresetImballiApolloResult> = MwLookupControlConfiguration
    .create<PresetImballoModel, PresetImballiApolloResult>()
    .setOptionDescriptionFn(value => value?.nome ?? "")
    .setResponseMapper(value => value.presetImballi)
    .setQuery(this._queries.entities.presetImballi)
    .setOrder([{ nome: "ASC" }])
    .configureControl(control => {

      control
        ?.setLabel("CT_SHIPMENT_DEPOSITS.type")
        .configureOptions<CtSelectControlOptions>(options => {

          if (!options) options = CtSelectControlOptions.create();

          options
            .setNullValueEnabled(false);

        });

    });

  constructor(private cdr: ChangeDetectorRef, private _queries: QueriesService) {
  }

  ngOnInit() {
  }

  ngAfterViewInit() {

    if (this.control) {

      this.configuration
        .CTControl
        .setControl(this.control)
        .setValidators([CtControlValidator.create({ name: "required" } as CtControlValidator)]);

    }

    this.cdr.detectChanges();

  }

}
