import { Injectable } from "@angular/core";
import { AbstractControl, FormControl } from "@angular/forms";
import { QtaControlService } from "../../../../../components/qta-control/qta-control.service";
import { DepositCollectionPackagingConfiguration } from "./deposit-collection-packaging.configuration";
import { ProdottoModel } from "../../../../../core/classes/prodotto";
import { ModelDetailService } from "../../../../../components/model-detail/model-detail.service";

@Injectable({
  providedIn: "root"
})
export class DepositCollectionPackagingService {

  constructor(private qtaControlService: QtaControlService, private modelDetailService: ModelDetailService) {
  }

  getControlConfiguration(configuration: DepositCollectionPackagingConfiguration | null, value: FormControl | AbstractControl | any, label: string | null = null) {

    return this.qtaControlService.getControlConfiguration(value, label, configuration?.needsImballoRitiro ? null : []);

  }

  getSectionDetailConfiguration(value: {
    prodotto: ProdottoModel | { value: ProdottoModel },
    quantitaBottiglie: number,
    quantitaScatole: number,
    quantitaTotale: number | null
  } | any) {

    if ((<any>value.prodotto).value) {

      value.prodotto = (<any>value.prodotto).value;

    }

    if (value.quantitaTotale) {

      value.quantitaBottiglie = value.quantitaTotale;

    }

    const model = value as any;

    return this.modelDetailService
      .getProdottoSection(model)
      .enableEndSectionDivider(true);

  }

}
