import { DestinatarioSpedizione } from "./destinatario-spedizione";
import { SpedizioneReadModel } from "./spedizione.read-model";
import { ConfezioneSpedizione } from "./confezione-spedizione";
import { ScatolaSpedizione } from "./scatola-spedizione";
import { PalletSpedizione } from "./pallet-spedizione";
import { ShipmentCollection } from "../../../pages/shipment/shipment-create-wizard/shipment-create-wizard.interfaces";
import { TipoSpedizioneEnum } from "../../enum/tipo-spedizione.enum";
import { ContenutoConfezioneSpedizione } from "./contenuto-confezione-spedizione";
import { ModelDetailService } from "../../../components/model-detail/model-detail.service";
import { RitiroModel } from "../deposito/ritiro";

export class SpedizioneModel implements SpedizioneReadModel {

  tariffaId: string | null = null;
  corriereId: string | null = null;
  confezioni: Array<ConfezioneSpedizione> = new Array<ConfezioneSpedizione>();
  scatole: Array<ScatolaSpedizione> = new Array<ScatolaSpedizione>();
  pallets: Array<PalletSpedizione> = new Array<PalletSpedizione>();

  private constructor(public destinazioneId: string, public destinatario: DestinatarioSpedizione) {
  }

  public static create = (destinazioneId: string, destinatario: DestinatarioSpedizione): SpedizioneModel => new SpedizioneModel(destinazioneId, destinatario);

  setCorriereId(value: string | null): SpedizioneModel {

    this.corriereId = value;
    return this;

  }

  setTariffaId(value: string | null): SpedizioneModel {

    this.tariffaId = value;
    return this;

  }

  setupCollection(value: ShipmentCollection[], modelDetailService: ModelDetailService) {

    value
      .forEach((element, index) => {

        switch (element.data.tipoSpedizione) {

          case TipoSpedizioneEnum.GiacenzaScatola:

            const scatolaSpedizione = ScatolaSpedizione
              .create(element.data.attributes.prodottoId, element.quantita);

            this.scatole
              .push(scatolaSpedizione);

            break;

          case TipoSpedizioneEnum.GiacenzaPallet:

            const palletSpedizione = PalletSpedizione
              .create(element.data.attributes.giacenzaPalletId, element.quantita);

            this.pallets
              .push(palletSpedizione);

            break;

          case TipoSpedizioneEnum.ComposizioneBox:

            const contenutiConfezione: Array<ContenutoConfezioneSpedizione> = new Array<ContenutoConfezioneSpedizione>();

            element
              .data
              .sections
              .filter(section => !!section.attributes.prodottoId)
              .forEach(section => {

                const detailData = modelDetailService.findDetailDataByTitle(section, "bottle");
                const qty = +(detailData?.cardConfiguration?.description ?? "0");

                const contenuto: ContenutoConfezioneSpedizione = ContenutoConfezioneSpedizione
                  .create(section.attributes.prodottoId, qty);

                contenutiConfezione
                  .push(contenuto);

              });

            const confezioneSpedizione = ConfezioneSpedizione
              .create(element.data.attributes.confezioneId, element.quantita, contenutiConfezione);

            this.confezioni
              .push(confezioneSpedizione);

            break;
          default:
            console.error(`Package type not found.`);
            break;

        }

      });

    return this;

  }

}
