import { CTBase } from "@ctsolution/ct-base";
import { v4 as uuidv4 } from "uuid";
import { ProdottiModel } from "../prodotti";
import { Package } from "../../../pages/deposit/deposit-create-wizard/deposit-create-wizard.interfaces";
import { ModalitaStoccaggio } from "../../enum/modalita-stoccaggio.enum";

export class ColloStoccaggio extends CTBase<ColloStoccaggio> {

  id: string | null = null;
  modalitaStoccaggio: ModalitaStoccaggio | null = null;
  quantita: number | undefined;
  prodotti: Array<ProdottiModel> = new Array<ProdottiModel>();

  public static create = (): ColloStoccaggio => new ColloStoccaggio();

  setId(value: string | null): ColloStoccaggio {

    this.id = value;
    return this;

  }

  setupPackageProducts(value: Package, modalitaStoccaggio: ModalitaStoccaggio) {

    const wines = value
      .box
      .wines;

    /*
    * Solo per il deposito di tipo scatola e bottiglia valorizzare il campo quantità a livello di colloStoccaggio,
    * e lasciare null  il relativo campo all'interno del prodotto
    * */

    switch (modalitaStoccaggio) {

      case ModalitaStoccaggio.Scatola:
        this.quantita = wines.reduce((acc, oggetto) => acc + (oggetto.quantitaScatole ?? 0), 0);
        break;

      case ModalitaStoccaggio.Bottiglia:
        this.quantita = wines.reduce((acc, oggetto) => acc + (oggetto.quantitaBottiglie ?? 0), 0);
        break;

      default:
        this.quantita = value.quantita;
        break;

    }

    wines
      .forEach(wine => {

        if ((<any>wine).quantitaTotale) {

          wine.quantitaBottiglie = (<any>wine).quantitaTotale; // qta totale viene usato per calcolare il numero totale di bottiglie per le scatole e i pallet

        }

        const product = new ProdottiModel(wine);

        switch (modalitaStoccaggio) {

          case ModalitaStoccaggio.Scatola:
            product.quantitaScatole = null;
            break;

          case ModalitaStoccaggio.Bottiglia:
            product.quantitaBottiglie = null;
            break;

        }

        this.prodotti.push(product);

      });


    return this;

  }

  setModalitaStoccaggio(value: ModalitaStoccaggio) {

    this.modalitaStoccaggio = value;
    return this;

  }

  setProductsIds(): ColloStoccaggio {

    this.prodotti
      .forEach(product => product.setId(uuidv4()));

    return this;

  }

}
