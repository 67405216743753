import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ShipmentCreateStep1Component } from "./shipment-create-step1.component";
import { MatLegacyButtonModule } from "@angular/material/legacy-button";
import { MatStepperModule } from "@angular/material/stepper";
import { TranslateModule } from "@ngx-translate/core";
import { ReactiveFormsModule } from "@angular/forms";
import { ShipmentTypeSelectionComponent } from "./shipment-type-selection/shipment-type-selection.component";
import { MwCardRadioGroupModule } from "../../../../components/mw-card-radio-group/mw-card-radio-group.module";
import { MatCardModule } from "@angular/material/card";
import {
  ShippingPackageCreationModule
} from "../../_components/shipping-package-creation/shipping-package-creation.module";
import {
  ShippingPalletSelectionModule
} from "../../_components/shipping-pallet-selection/shipping-pallet-selection.module";
import {
  SectionDetailDataModule
} from "../../../../components/model-detail/section-detail-data/section-detail-data.module";
import { ShipmentCollectionComponent } from "./shipment-collection/shipment-collection.component";
import { QtaControlModule } from "../../../../components/qta-control/qta-control.module";
import { ShippingBoxSelectionModule } from "../../_components/shipping-box-selection/shipping-box-selection.module";

@NgModule({
  declarations: [
    ShipmentCreateStep1Component,
    ShipmentTypeSelectionComponent,
    ShipmentCollectionComponent
  ],
  imports: [
    CommonModule,
    MatLegacyButtonModule,
    MatStepperModule,
    TranslateModule,
    ReactiveFormsModule,
    MwCardRadioGroupModule,
    MatCardModule,
    ShippingPackageCreationModule,
    ShippingPalletSelectionModule,
    ShippingBoxSelectionModule,
    SectionDetailDataModule,
    QtaControlModule
  ],
  exports: [
    ShipmentCreateStep1Component
  ]
})
export class ShipmentCreateStep1Module {
}
