import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ShipmentAddressFormComponent } from "./shipment-address-form.component";
import {
  CustomerLookupControlModule
} from "./customer-lookup-control/customer-lookup-control.module";
import { CtButtonModule, CtFormModule, CtFrameworkModule } from "@ctsolution/ct-framework";
import { ReactiveFormsModule } from "@angular/forms";
import { FlexModule } from "@angular/flex-layout";
import { MatIconModule } from "@angular/material/icon";
import { MatLegacyButtonModule } from "@angular/material/legacy-button";
import { MatLegacyDialogModule } from "@angular/material/legacy-dialog";
import {
  CustomerAddressSelectControlModule
} from "./customer-address-select-control/customer-address-select-control.module";
import { RecipientContactDataFormComponent } from "./recipient-contact-data-form/recipient-contact-data-form.component";
import { AddressDataFormModule } from "./address-data-form/address-data-form.module";

@NgModule({
  declarations: [
    ShipmentAddressFormComponent,
    RecipientContactDataFormComponent
  ],
  imports: [
    CommonModule,
    CustomerLookupControlModule,
    CtFormModule,
    ReactiveFormsModule,
    CtButtonModule,
    FlexModule,
    MatIconModule,
    MatLegacyButtonModule,
    MatLegacyDialogModule,
    CustomerAddressSelectControlModule,
    AddressDataFormModule,
    CtFrameworkModule
  ],
  exports: [ShipmentAddressFormComponent]
})
export class ShipmentAddressFormModule {
}
