import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { WineStockDatatableComponent } from "./wine-stock-datatable/wine-stock-datatable.component";
import { RouterModule } from "@angular/router";
import { WineStockDatatableModule } from "./wine-stock-datatable/wine-stock-datatable.module";
import { MwDatatableModule } from "../../../components/mw-datatable/mw-datatable.module";
import { ButtonModule } from "primeng/button";

@NgModule({
  imports: [
    CommonModule,
    WineStockDatatableModule,
    RouterModule.forChild([
      {
        path: "",
        component: WineStockDatatableComponent,
        data: {
          title: "CT_MENU.pendingShipmentsWines",
          urls: []
        }
      }
    ]),
    MwDatatableModule,
    ButtonModule
  ]
})
export class WineStockModule {
}
