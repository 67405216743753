import { Component, EventEmitter, Input, Output } from "@angular/core";
import { MwCardRadioOptionConfiguration } from "./mw-card-radio-option.configuration";

@Component({
  selector: "mw-card-radio-option",
  template: `
    <mat-card
      class="mw-card-radio-option forms-box-shadow m-b-5"
      (click)="onSelect.emit(configuration?.value)">

      <mat-card-content>

        <img *ngIf="configuration?.image" [src]="configuration?.image">

        <h3 [innerHTML]="configuration?.label | translate"></h3>

        <p style="text-align: center" *ngIf="configuration?.description"
           [innerHTML]="configuration?.description | translate"></p>

      </mat-card-content>

      <mat-radio-button #select hidden [value]="configuration?.value"></mat-radio-button>

    </mat-card>
  `,
  styles: [`
    .mw-card-radio-option {
      margin: auto;
      min-height: 220px;
      border-radius: 10px;
      cursor: pointer;

      mat-card-content {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-grow: 1;
        padding-bottom: 16px;

        img {
          height: 40px;
          width: auto;
        }

        h3 {
          text-align: center;
          margin: 0px;
        }

      }
    }`]
})
export class MwCardRadioOptionComponent<T> {

  @Output() onSelect: EventEmitter<T> = new EventEmitter<T>();
  @Input() configuration: MwCardRadioOptionConfiguration<T> | null = null;

}
