import { ChangeDetectorRef, Component, TemplateRef, ViewChild } from "@angular/core";
import { MwDatatableComponent } from "../../../components/mw-datatable/mw-datatable.component";
import { MwDateRangePickerComponent } from "../../../components/mw-date-range-picker/mw-date-range-picker.component";
import { ActivatedRoute } from "@angular/router";
import { QueriesService } from "../../../core/lib/queries.service";
import { MwDatatableConfiguration } from "../../../components/mw-datatable/mw-datatable.configuration";
import { vinoDescrizioneHeader } from "../../stock/deposit-stock-datatable.headers";
import {
  MovimentazioniGiacenzaApolloResult
} from "../../../core/interfaces/apollo/movimentazioni-giacenza.apollo-result";
import { MovementsService } from "../movements.service";
import { depositoHeader, palletHeader, spedizioneHeader } from "./movement-history.headers";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-movement-history",
  templateUrl: "./movement-history.component.html"
})
export class MovementHistoryComponent {

  @ViewChild("headerActions", { static: false }) headerActions: TemplateRef<any> | null = null;
  @ViewChild("datatable") table: MwDatatableComponent<any> | null = null;
  @ViewChild("dateRangePickerComponent", { static: false }) dateRangePickerComponent: MwDateRangePickerComponent | null = null;

  listConfiguration: MwDatatableConfiguration<MovimentazioniGiacenzaApolloResult> | null = null;

  constructor(
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private movementsService: MovementsService,
    private _queries: QueriesService,
    private translate: TranslateService  
  ) {
  }

  ngAfterViewInit() {

    this.route
      .data
      .subscribe(data => {

        data["headerActions"] = this.headerActions;

        setTimeout(() => {

          this.listConfiguration =
            this.movementsService.getMovementTableConfiguration<MovimentazioniGiacenzaApolloResult>(
              this._queries.entities.movimentazioniGiacenza,
              this.dateRangePickerComponent!,
              (value: MovimentazioniGiacenzaApolloResult) => value.movimentazioniGiacenza,
              [depositoHeader(this.translate), spedizioneHeader(this.translate), vinoDescrizioneHeader(this.translate), palletHeader(this.translate)]
            );

          setTimeout(() => this.movementsService.setupDateRangePickerDefaults(this.dateRangePickerComponent, this.table));

        });

      });

    this.cdr.detectChanges();

  }

}
