import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SectionDetailDataComponent } from "./section-detail-data.component";
import { CtCardModule, CtFrameworkModule } from "@ctsolution/ct-framework";
import { FlexLayoutModule } from "@angular/flex-layout";

@NgModule({
  imports: [
    CommonModule,
    CtFrameworkModule,
    FlexLayoutModule,
    CtCardModule
  ],
  declarations: [SectionDetailDataComponent],
  exports: [SectionDetailDataComponent]
})
export class SectionDetailDataModule {
}
