import { ChangeDetectorRef, Component, Input } from "@angular/core";
import {
  CtControlConfiguration,
  CtControlTypes, CtControlValidator, CtRangeControlOptions,
  CtThemeTypes
} from "@ctsolution/ct-framework";
import { QtaControlConfiguration } from "./qta-control.configuration";

@Component({
  selector: "app-qta-control",
  template: `
    <ct-control *ngIf="control" [configuration]="control"></ct-control>`
})
export class QtaControlComponent {

  @Input() configuration: QtaControlConfiguration | null = null;
  control: CtControlConfiguration | null = null;

  constructor(private cdr: ChangeDetectorRef) {
  }

  ngAfterViewInit() {
    
    this.setup();

  }

  setup() {
    this.control = CtControlConfiguration
      .create()
      .setControl(this.configuration?.control)
      .setLabel(this.configuration?.label ?? "Quantity")
      .setTheme(CtThemeTypes.MATERIAL)
      .setType(CtControlTypes.COUNTER)
      .setOptions(
        CtRangeControlOptions
          .create()
          .setStep(1)
      )
      .setValidators(
        this.configuration?.validators ??
        [
          CtControlValidator.create({ name: "required" } as CtControlValidator),
          CtControlValidator.create({ name: "min", value: 0 } as CtControlValidator)
        ]
      )
      .setDisabled(this.configuration?.control?.disabled);

    this.cdr.detectChanges();

  }


}

