import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MwDateRangePickerComponent } from "./mw-date-range-picker.component";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatInputModule } from "@angular/material/input";
import { ReactiveFormsModule } from "@angular/forms";

@NgModule({
  declarations: [
    MwDateRangePickerComponent
  ],
  exports: [
    MwDateRangePickerComponent
  ],
  imports: [
    CommonModule,
    MatDatepickerModule,
    MatInputModule,
    ReactiveFormsModule
  ]
})
export class MwDateRangePickerModule {
}
