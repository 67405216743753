<form [formGroup]="form">

  <div class="w-100 m-t-5">

    <ct-control *ngIf="dataRitiroControl" [configuration]="dataRitiroControl"></ct-control>

  </div>

  <div
    [hidden]="!form.get('shipmentAddressData')"
    class="m-t-5">

    <app-shipment-address-form
      #shipmentAddressFormComponent
      [configuration]="shipmentAddressFormConfiguration"></app-shipment-address-form>

  </div>

  <p class="m-t-20 m-b-0"><strong [innerHTML]='"CT_SHIPMENT_DEPOSITS.note_title" | translate'></strong></p>
  <p class="m-t-0 m-b-20" [innerHTML]='"CT_SHIPMENT_DEPOSITS.note_subtitle" | translate'></p>

  <app-note-control #depositNoteComponent></app-note-control>

</form>
