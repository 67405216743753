import { ElementRef, Injectable } from "@angular/core";

@Injectable({
  providedIn: "root"
})
export class HtmlService {

  constructor() {
  }

  scrollToElement(element?: ElementRef | null) {

    const reference = element?.nativeElement;

    if (reference) {

      reference.scrollIntoView({ behavior: "smooth" });

    }

  }

}
