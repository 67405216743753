import { ChangeDetectorRef, Component } from "@angular/core";
import { CtControlConfiguration } from "@ctsolution/ct-framework";
import { NOME_COMMERCIALE_CONTROL } from "./nome-commerciale.control";

@Component({
  selector: "mw-nome-commerciale-control",
  template: `
    <ct-control *ngIf="control" [configuration]="control"></ct-control>`
})
export class NomeCommercialeControlComponent {

  control: CtControlConfiguration = CtControlConfiguration.create(NOME_COMMERCIALE_CONTROL);

  constructor(private cdr: ChangeDetectorRef) {
  }

  ngAfterViewInit() {

    this.cdr.detectChanges();

  }

}
