<div class="deposit-collection-packaging-container">

  <form [formGroup]="form">

    <div
      *ngIf="configuration?.depositFormValue?.step1?.depositPackagingType"
      formArrayName="collection">

      <ng-container [ngSwitch]="configuration?.depositFormValue?.step1?.depositPackagingType">

        <ng-container *ngSwitchCase="ModalitaStoccaggio.Pallet">

          <app-collection-pallet-packaging
            [collection]="collection"
            [configuration]="configuration"></app-collection-pallet-packaging>

        </ng-container>

        <ng-container *ngSwitchDefault>

          <app-collection-default-packaging
            (onAdd)="addImballoRitiro()"
            (onRemove)="removeImballoRitiro($event)"
            [collection]="collection"
            [configuration]="configuration"></app-collection-default-packaging>

        </ng-container>

      </ng-container>

    </div>

  </form>

</div>
