import { ChangeDetectorRef, Component } from "@angular/core";
import { MwDatatableConfiguration } from "../../components/mw-datatable/mw-datatable.configuration";
import { GeneralService } from "../../core/lib/general.service";
import { BaseController } from "../../core/controllers/base.controller";
import {
  codiceSpedizioneHeader,
  nomeDestinatarioSpedizioneHeader,
  dataCreazioneSpedizioneHeader, dataConsegnaSpedizioneHeader, indirizzoSpedizioneHeader, contenutoSpedizioneHeader
} from "./shipment-datatable.headers";
import { ShipmentService } from "./shipment.service";
import {
  CTDatatableButtonConfiguration,
  CTDatatableConfiguration,
  CTDatatablePermissions
} from "@ctsolution/ct-framework";
import { QueriesService } from "../../core/lib/queries.service";
import { SpedizioniApolloResult } from "../../core/interfaces/apollo/spedizioni.apollo-result";
import { ApolloVariableClass } from "../../core/classes/apollo/apollo.variable";
import { BaseApolloQueryClass } from "../../core/classes/apollo/apollo.base.query";
import { SpedizioneReadModel } from "../../core/classes/spedizione/spedizione.read-model";
import { statoHeader } from "../deposit/deposit-datatable.headers";
import { TranslateService } from "@ngx-translate/core";
import { Title } from "@angular/platform-browser";
import { AfterViewInit } from "@angular/core";
import { ModalitaStoccaggio } from "../../core/enum/modalita-stoccaggio.enum";
import { iconsBasePath } from "../../core/constants";

@Component({
  selector: "app-shipment",
  templateUrl: "./shipment.component.html"
})
export class ShipmentComponent implements AfterViewInit{

  listConfiguration: MwDatatableConfiguration<SpedizioniApolloResult> | null = null;

  constructor(
    private generalService: GeneralService,
    private baseController: BaseController,
    public shipmentService: ShipmentService,
    private _queries: QueriesService,
    private cdr: ChangeDetectorRef,
    private translate: TranslateService,
    private title: Title
  ) {
  }

  ngOnInit(){


  }
  

  ngAfterViewInit() {

    this.setupListConfiguration();
    this.cdr.detectChanges();

    this.translate.get('CT_DATATABLE.SHIPMENT.CreateShipment').subscribe((translation: string) => {
      this.title.setTitle(translation);
    });
  }

  setupListConfiguration() {

    this.listConfiguration = MwDatatableConfiguration
      .create<SpedizioniApolloResult>()
      .setFetchDataCaller(async (variables: ApolloVariableClass) => {
        const query = await this._queries.entities.spedizioni();

        if (!variables.order.length) {

          variables
            .setOrder([{ dataCreazione: "DESC" }]);

        }

        if (!query) return null;

        const parameter = BaseApolloQueryClass
          .create()
          .setVariables(variables)
          .setQuery(query);

          return this.baseController.list<SpedizioniApolloResult>(parameter).then(result => {
              this.translate.get('CT_DATATABLE.SHIPMENT.CreateShipment').subscribe((translation: string) => {
              this.title.setTitle(translation);
            });
            return result; 
          }); 
        

      })
      
      .setFetchResponseDataMapper((value: SpedizioniApolloResult) => value.spedizioni)
      .setCTDatatableConfiguration(
        CTDatatableConfiguration
          .create<SpedizioniApolloResult>()
          .setEmptyMessage(this.translate.instant("CT_DATATABLE.SHIPMENT.EmptyMessage"))
          .setLoadingBody(this.translate.instant("CT_DATATABLE.SHIPMENT.LoadingMessage"))
          .setSearchInputPlaceholder(this.translate.instant("CT_GENERAL.Search"))
          .setPermissions([CTDatatablePermissions.insert, CTDatatablePermissions.read])
          .setCreateButtonConfiguration(
            CTDatatableButtonConfiguration
              .create()
              .setIcon("pi pi-truck")
              .setLabel(this.translate.instant("CT_DATATABLE.SHIPMENT.CreateShipment"))
          )
          .setHeaders([
            statoHeader(this.translate),
            codiceSpedizioneHeader(this.translate),
            contenutoSpedizioneHeader(this.translate),
            nomeDestinatarioSpedizioneHeader(this.translate),
            dataCreazioneSpedizioneHeader(this.translate),
            indirizzoSpedizioneHeader(this.translate)
          ])
          .setVirtualScrollItemSize(59)
      );

  }

  addShipment() {

    this.generalService
      .navigateTo("/spedizioni/creazione");

  }

  detail(shipment: SpedizioneReadModel) {
    if (!shipment.id) return;
    this.shipmentService.showDetail(shipment.id);

  }

  contenutoSpedizione(value: SpedizioneReadModel) {
    const content = [];
    if ((value?.pallets ?? []).length) {

      const countValue = value?.pallets?.reduce((acc, pallet) => acc + pallet.quantita, 0);
      content.push(`${countValue} Pallet${countValue > 1 ? "s" : ""}`);

    }

    if ((value?.scatole ?? []).length) {

      const countValue = value?.scatole?.reduce((acc, scatola) => acc + scatola.quantita, 0);

      content.push(`${countValue} Scatol${countValue > 1 ? "e" : "a"}`);

    }

    if ((value?.confezioni ?? []).length) {

      const countValue = value?.confezioni?.reduce((acc, confezione) => acc + confezione.quantita, 0);
      const suffix = countValue > 1 ? "e" : "a";

      content.push(`${countValue} Scatol${suffix} personalizzat${suffix}`);

    }

    return content.join(", ");

  }

  getDepositIcon(value: string) {
    
    switch (value) {
      case ModalitaStoccaggio.Bottiglia:
        return `${iconsBasePath}bottles.svg`;
      case ModalitaStoccaggio.Scatola:
        return `${iconsBasePath}box.svg`;
      case ModalitaStoccaggio.Pallet:
        return `${iconsBasePath}box.svg`;
      default:
        return `${iconsBasePath}box.svg`;

    }

  }

}


 


