import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ProductStockLookupControlComponent } from "./product-stock-lookup-control.component";
import { MwLookupControlModule } from "../mw-lookup-control/mw-lookup-control.module";

@NgModule({
  declarations: [
    ProductStockLookupControlComponent
  ],
  imports: [
    CommonModule,
    MwLookupControlModule
  ],
  exports: [ProductStockLookupControlComponent]
})
export class ProductStockLookupControlModule {
}
