import { ChangeDetectorRef, Component, TemplateRef, ViewChild } from "@angular/core";
import { MwDatatableConfiguration } from "../../../components/mw-datatable/mw-datatable.configuration";
import { QueriesService } from "../../../core/lib/queries.service";
import { SnapshotGiacenzeApolloResult } from "../../../core/interfaces/apollo/snapshot-giacenze.apollo-result";
import { ActivatedRoute } from "@angular/router";
import { MwDateRangePickerComponent } from "../../../components/mw-date-range-picker/mw-date-range-picker.component";
import { MwDatatableComponent } from "../../../components/mw-datatable/mw-datatable.component";
import { vinoDescrizioneHeader } from "../../stock/deposit-stock-datatable.headers";
import { quantitaAttualeHeader, quantitaInizialeHeader, tipoQuantitaHeader } from "./wine-movements.headers";
import { MovementsService } from "../movements.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-wine-movements",
  templateUrl: "./wine-movements.component.html"
})
export class WineMovementsComponent {

  @ViewChild("headerActions", { static: false }) headerActions: TemplateRef<any> | null = null;
  @ViewChild("datatable") table: MwDatatableComponent<any> | null = null;
  @ViewChild("dateRangePickerComponent", { static: false }) dateRangePickerComponent: MwDateRangePickerComponent | null = null;

  listConfiguration: MwDatatableConfiguration<SnapshotGiacenzeApolloResult> | null = null;

  constructor(
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private movementsService: MovementsService,
    private _queries: QueriesService,
    private translate: TranslateService) {
  }

  ngAfterViewInit() {

    this.route
      .data
      .subscribe(data => {

        data["headerActions"] = this.headerActions;

        setTimeout(() => {

          this.listConfiguration =
            this.movementsService.getMovementTableConfiguration<SnapshotGiacenzeApolloResult>(
              this._queries.entities.snapshotGiacenze,
              this.dateRangePickerComponent!,
              (value: SnapshotGiacenzeApolloResult) => value.snapshotGiacenze,
              [
                quantitaInizialeHeader,
                quantitaAttualeHeader,
                tipoQuantitaHeader,
                vinoDescrizioneHeader(this.translate)
              ]
            );

          setTimeout(() => this.movementsService.setupDateRangePickerDefaults(this.dateRangePickerComponent, this.table));

        });

      });

    this.cdr.detectChanges();

  }

}
