import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { WineStockCounterComponent } from "./wine-stock-counter.component";
import { FlexModule } from "@angular/flex-layout";
import { TranslateModule } from "@ngx-translate/core";


@NgModule({
    declarations: [
      WineStockCounterComponent
    ],
    exports: [
      WineStockCounterComponent
    ],
    imports: [
      CommonModule,
      FlexModule,
      TranslateModule
    ]
  })
  export class WineStockCounterModule {
  }
  