import { v4 as uuidv4 } from "uuid";
import { GenericReadModel } from "../generic-read.model";

export class ContenutoConfezioneSpedizione {

  prodotto: GenericReadModel | null = null;

  public constructor(
    public id: string,
    public prodottoId: string,
    public quantitaProdotto: number
  ) {
  }

  public static create = (prodottoId: string, quantitaProdotto: number): ContenutoConfezioneSpedizione => new ContenutoConfezioneSpedizione(uuidv4(), prodottoId, quantitaProdotto);

}
