import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NotificationsWidgetComponent } from "./notifications-widget.component";
import { MatCardModule } from "@angular/material/card";
import { MatButtonModule } from "@angular/material/button";
import { TranslateModule } from "@ngx-translate/core";

@NgModule({
  declarations: [
    NotificationsWidgetComponent
  ],
  imports: [
    CommonModule,
    MatCardModule,
    MatButtonModule,
    TranslateModule
  ],
  exports: [
    NotificationsWidgetComponent
  ]
})
export class NotificationsWidgetModule {
}
