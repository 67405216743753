import { Component, EventEmitter, Input, Output } from "@angular/core";
import { DepositCollectionPackagingService } from "../deposit-collection-packaging.service";
import { FormControl, FormGroup } from "@angular/forms";
import { DepositCollectionPackagingConfiguration } from "../deposit-collection-packaging.configuration";
import { QtaControlConfiguration } from "../../../../../../components/qta-control/qta-control.configuration";

@Component({
  selector: "app-collection-form",
  templateUrl: "./collection-form.component.html",
  styleUrls: [`collection-form.component.scss`]
})
export class CollectionFormComponent {

  @Input() configuration: DepositCollectionPackagingConfiguration | null = null;
  @Input() packageForm: FormGroup | null = null;
  @Input() deleteEnabled: boolean = false;
  @Output() onRemove: EventEmitter<number> = new EventEmitter<number>();

  viewModel: { [key: string]: QtaControlConfiguration | null } = {};

  constructor(private depositCollectionPackagingService: DepositCollectionPackagingService) {
  }

  ngAfterViewInit() {

    if (this.configuration?.needsTipologiaImballo) {

      this.packageForm
        ?.addControl("tipologiaImballo", new FormControl(null));

      const tipologiaImballoControl = this.packageForm?.get("tipologiaImballo");

      tipologiaImballoControl
        ?.valueChanges
        .subscribe(result => {

          if (result) {

            this.packageForm
              ?.patchValue(result);

          }

        });

    }

    if (this.configuration && this.packageForm) {

      this.viewModel = {
        quantitaControlConfiguration: this.controlConfiguration("quantita", "CT_GENERAL.quantity"),
        pesoKgControlConfiguration: this.controlConfiguration("pesoKg", "CT_GENERAL.weight_kg"),
        lunghezzaControlConfiguration: this.controlConfiguration("lunghezza", "CT_GENERAL.lunghezza"),
        larghezzaControlConfiguration: this.controlConfiguration("larghezza", "CT_GENERAL.larghezza"),
        altezzaControlConfiguration: this.controlConfiguration("altezza", "CT_GENERAL.height")
      };

    }

  }

  private controlConfiguration = (key: string, label: string) => this.depositCollectionPackagingService.getControlConfiguration(this.configuration, this.packageForm!.get(key), label);

}
