import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MwLookupControlComponent } from "./mw-lookup-control.component";
import { MatLegacyFormFieldModule as MatFormFieldModule } from "@angular/material/legacy-form-field";
import { TranslateModule } from "@ngx-translate/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatLegacyOptionModule as MatOptionModule } from "@angular/material/legacy-core";
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from "@angular/material/legacy-autocomplete";
import { MatLegacyInputModule as MatInputModule } from "@angular/material/legacy-input";
import { MatLegacyButtonModule } from "@angular/material/legacy-button";
import { MatIconModule } from "@angular/material/icon";
import { CtControlModule } from "@ctsolution/ct-framework";
import { MwLookupControlService } from "./mw-lookup-control.service";

@NgModule({
  declarations: [
    MwLookupControlComponent
  ],
  imports: [
    CommonModule,
    MatFormFieldModule,
    TranslateModule,
    MatOptionModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatInputModule,
    MatLegacyButtonModule,
    MatIconModule,
    CtControlModule
  ],
  exports: [
    MwLookupControlComponent
  ],
  providers: [MwLookupControlService]
})
export class MwLookupControlModule {
}
