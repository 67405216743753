<mw-datatable
  *ngIf="listConfiguration"
  class="shipment-datatable-container"
  #table
  [configuration]="listConfiguration"
  (onSave)="addShipment()"
  (onDetail)="detail($event)">

  <ng-template #legendTemplate>

    <div class="legend-container">

      <app-legend [stati]="shipmentService.statiSpedizione"></app-legend>

    </div>

  </ng-template>

  <ng-template #body let-value="value">

    <td>

      <app-bullet-state
        *ngIf="value?.stato"
        [state]="value?.stato"></app-bullet-state>

    </td>

    <td>
      {{ value?.codice ?? '' }}
    </td>

    <td>
      {{ value?.destinatario?.nome ?? '' }}
    </td>

    <td>
      {{ value?.destinatario?.email ?? '' }}
    </td>

    <td>
      {{ value?.dataCreazione ? (value?.dataCreazione | date) : '' }}
    </td>

    <td>
      {{ value?.dataConsegna ? (value?.dataConsegna | date) : '' }}
    </td>

    <td>
      {{ value?.destinatario?.indirizzo?.via }}, {{ value?.destinatario?.indirizzo?.comune }}
      , {{ value?.destinatario?.indirizzo?.cap }}, {{ value?.destinatario?.indirizzo?.provincia }}
    </td>

    <td>
      {{ contenutoSpedizione(value) }}
    </td>

  </ng-template>

</mw-datatable>
