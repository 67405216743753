import { DestinazioneModel } from "../../../core/classes/destinazione";
import { TipodestinazioneEnum } from "../../../core/enum/tipo-destinazione.enum";

export class CustomerFormConfiguration {

  values: DestinazioneModel | null = null;
  tipodestinazione: TipodestinazioneEnum | null = null;

  private constructor() {
  }

  public static create = (): CustomerFormConfiguration => new CustomerFormConfiguration();

  setValues(value: DestinazioneModel | null) {

    this.values = value;
    return this;

  }

  setTipodestinazione(value: TipodestinazioneEnum | null) {

    this.tipodestinazione = value;
    return this;

  }

}
