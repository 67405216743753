import { ChangeDetectorRef, Component, ViewChild } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { StatoDepositoEnum } from "../../../../core/enum/stato-deposito.enum";
import { StateService } from "../state.service";
import { NoteControlComponent } from "../../../../components/note-control/note-control.component";

@Component({
  selector: "app-deposit-state-change-form",
  templateUrl: "./deposit-state-change-form.component.html",
  styleUrls: ["./deposit-state-change-form.component.scss"]
})
export class DepositStateChangeFormComponent {

  @ViewChild("stateChangeNoteComponent") stateChangeNoteComponent: NoteControlComponent | null = null;
  form: FormGroup;

  get DepositStateKeys(): string[] {
    return Object.keys(StatoDepositoEnum) as string[];
  }

  constructor(private formBuilder: FormBuilder, public stateService: StateService, private cdr: ChangeDetectorRef) {

    this.form = this.formBuilder.group({
      state: new FormControl(null, Validators.required)
    });

  }

  ngAfterViewInit() {

    this.form
      .addControl("note", this.stateChangeNoteComponent?.control);

    this.cdr.detectChanges();

  }

}
