<mw-datatable
  #table
  class="pallet-datatable-container"
  *ngIf="listConfiguration"
  [configuration]="listConfiguration">

  <ng-template #body let-value="value">

    <td *ngIf="_role.isAdmin()">
      {{ value?.depositante?.nomeRiferimento ?? '' }}
    </td>

    <td>
      {{ value?.deposito?.codice ?? '' }}
    </td>

    <td>

      <div *ngFor="let contenuto of (value.contenuti ?? [])"> {{ contenuto?.vino?.descrizioneBreve ?? '' }}</div>

    </td>

    <td>
      {{ value?.quantita ?? 0 }}
    </td>

    <td>
      {{ value?.quantitaInUnpacking ?? 0 }}
    </td>

  </ng-template>

  <ng-template #rowExpansion let-value="value">
    <tr class="expansion-row">
      <td colspan="8">

        <app-section-detail-data
          *ngFor="let contenuto of value.contenuti"
          [configuration]="modelDetailService.getProdottoSectionFromGiacenzaVinoModel(contenuto)"></app-section-detail-data>

      </td>
    </tr>
  </ng-template>

</mw-datatable>

<ng-template #tracking let-value="value">

  <div class="pallet-stock-actions">

    <div>

      <button
        pButton
        pRipple
        class="p-button-rounded"
        (click)="onTracking(value)">Spedisci
      </button>

    </div>

    <div *ngIf="!dialogRef">

      <button
        pButton
        pRipple
        class="p-button-rounded"
        (click)="unpack(value)">Apri pallet
      </button>

    </div>

  </div>

</ng-template>
