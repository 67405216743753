import { NgModule } from "@angular/core";
import { MwDatatableModule } from "../../../components/mw-datatable/mw-datatable.module";
import { RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";
import { PalletStockComponent } from "./pallet-stock.component";
import { PalletStockDatatableModule } from "./pallet-stock-datatable/pallet-stock-datatable.module";
import { PalletStockCounterModule } from "./pallet-stock-counter/pallet.stock-counter.module";

@NgModule({
  imports: [
    CommonModule,
    PalletStockCounterModule,
    PalletStockDatatableModule,
    RouterModule.forChild([
      {
        path: "",
        component: PalletStockComponent,
        data: {
          title: "CT_MENU.pendingShipmentsPallet",
          urls: []
        }
      }
    ]),
    MwDatatableModule
  ],
  declarations:[PalletStockComponent]
})
export class PalletStockModule {
}
