import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { JwtService } from '../lib/jwt.service';

@Injectable({
    providedIn: 'root',
})
export class ProfileGuard implements CanActivate {

    private profileCompleted = false;  // Questo è il valore che controlleremo

    constructor(private router: Router, private _jwt: JwtService) {

    }

    async canActivate(): Promise<boolean> {
        const res = await this._jwt.getDepositanti();
    
        if (res != null && res.length > 0) {
            return true;
        } else {
            this.router.navigate(['/profilo']);
            return false;
        }
    }

    // Funzione per valorizzare il profilo come completato
    completeProfile(): void {
        this.profileCompleted = true;
    }

}
