import { DestinazioneModel } from "../../../core/classes/destinazione";

export class AddressesDatatableConfiguration {

  destinazione: DestinazioneModel | null = null;

  private constructor() {
  }

  public static create = () => new AddressesDatatableConfiguration();

  setdestinazione(value: DestinazioneModel | null) {

    this.destinazione = value;

    if (!this.destinazione?.nomeRiferimento) {

      if (!this.destinazione) this.destinazione = {} as DestinazioneModel;

      this.destinazione.nomeRiferimento = [this.destinazione.nome, this.destinazione.cognome].filter(elm => !!elm).join(" ");

    }

    return this;

  }

}
