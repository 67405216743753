<div class="m-t-20 shipment-collection" [hidden]="!collection.length">

  <form [formGroup]="form">

    <ng-container formArrayName="collection">

      <ng-container *ngFor="let collectionForm of collection.controls; let i = index">

        <mat-card class="collection-item" [formGroup]="collectionForm">

          <mat-card-content>

            <div class="collection-data">

              <app-section-detail-data
                *ngFor="let configuration of collectionForm.get('data').value?.sections"
                [configuration]="configuration"></app-section-detail-data>

            </div>

            <mat-card-actions class="collection-actions">

              <div>

                <div>

                  <app-qta-control
                    *ngIf="collectionForm.get('qtaControlConfiguration')?.value"
                    [configuration]="collectionForm.get('qtaControlConfiguration')?.value"></app-qta-control>

                </div>

              </div>

              <div align="end">

                <button
                  class="general-button"
                  (click)="removeFromCollection(i)"
                  mat-button>{{ 'CT_GENERAL.collo_delete_title' | translate }}
                </button>

              </div>

            </mat-card-actions>

          </mat-card-content>

        </mat-card>

      </ng-container>

    </ng-container>

  </form>

</div>
