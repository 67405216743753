import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { WineMovementsModule } from "./wine-movements/wine-movements.module";
import { MovementHistoryModule } from "./movement-history/movement-history.module";


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forChild([
      {
        path: "vini",
        loadChildren: () => WineMovementsModule
      },
      {
        path: "storico",
        loadChildren: () => MovementHistoryModule
      }
    ])
  ]
})
export class MovementsModule {
}
