import { Component, EventEmitter, Input, Output } from "@angular/core";
import { StatoDepositoEnum } from "../../core/enum/stato-deposito.enum";

@Component({
  selector: "app-bullet-state",
  template: `
    <div (click)="clicked()" class="bullet-state-component-container" [class]="state"></div>`,
  styles: [
    `.bullet-state-component-container {

      border-radius: 100%;
      height: 30px;
      width: 30px;
      min-height: 30px;

      &.Accettazione {
        background-color: #016cff;
      }


      &.Transito {
        background-color: #ee6f13;
      }


      &.PresaInCarico {
        background-color: #e8c605;
      }


      &.Depositato, &.Consegnata {
        background-color: #20b759;
      }

      &.Annullato, &.Annullata {
        background-color: #fe2d2d;
      }

    }
    `
  ]
})
export class BulletStateComponent {

  @Input() state: StatoDepositoEnum | null = null;
  @Output() onClick: EventEmitter<null> = new EventEmitter<null>();

  clicked() {
    this.onClick.emit();
  }

}
