import { v4 as uuidv4 } from "uuid";
import { GiacenzaPalletModel } from "../deposito/giacenza";

export class PalletSpedizione {

  giacenzaPallet: GiacenzaPalletModel | null = null;

  private constructor(
    public id: string,
    public giacenzaPalletId: string,
    public quantita: number
  ) {
  }

  public static create = (giacenzaPalletId: string, quantita: number): PalletSpedizione => new PalletSpedizione(uuidv4(), giacenzaPalletId, quantita);

}
