import { IndirizzoModel } from "../indirizzo";

export class DestinatarioSpedizione {

  email?: string;

  private constructor(
    public nome: string,
    public telefono: string,
    public indirizzo: IndirizzoModel
  ) {
  }

  public static create = (nome: string, telefono: string, indirizzo: IndirizzoModel): DestinatarioSpedizione => new DestinatarioSpedizione(nome, telefono, indirizzo);

  setEmail(value: string) {

    this.email = value;
    return this;

  }

}
