import { Injectable } from "@angular/core";
import { AbstractControl, FormControl } from "@angular/forms";
import { QtaControlConfiguration } from "./qta-control.configuration";
import { CtControlValidator } from "@ctsolution/ct-framework";

@Injectable({
  providedIn: "root"
})
export class QtaControlService {

  constructor() {
  }

  getControlConfiguration(value: FormControl | AbstractControl | any, label: string | null = null, validators: CtControlValidator[] | null = null) {

    return QtaControlConfiguration
      .create()
      .setControl(value)
      .setLabel(label)
      .setValidators(validators);

  }

}
