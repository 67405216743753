import { ChangeDetectorRef, Component, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { PackageComposerComponent } from "./package-composer/package-composer.component";
import { PackageComposerConfiguration } from "./package-composer/package-composer.configuration";
import { ModalitaStoccaggio } from "../../../../core/enum/modalita-stoccaggio.enum";

@Component({
  selector: "app-deposit-create-step2",
  templateUrl: "./deposit-create-step2.component.html",
  styles: [`
    ::ng-deep app-package-composer {
      hr {
        margin-bottom: 25px;
        background: var(--bordeaux) !important;
      }
    }
  `]
})
export class DepositCreateStep2Component {

  @ViewChild("packageComposer") packageComposer: PackageComposerComponent | null = null;
  packageComposerConfiguration: PackageComposerConfiguration = PackageComposerConfiguration.create();

  form: FormGroup;

  constructor(private formBuilder: FormBuilder, private cdr: ChangeDetectorRef) {

    this.form = this.formBuilder.group({});

  }

  ngAfterViewInit() {

    this.form
      .addControl("colliStoccaggio", this.packageComposer?.form);

  }

  setup(depositPackagingType: ModalitaStoccaggio) {

    this
      .packageComposerConfiguration
      .setDepositPackagingType(depositPackagingType);

    // se il tipo di deposito cambia, resetto il secondo form
    this
      .packageComposer
      ?.resetPackages();

    this.cdr.detectChanges();

  }


}
