import { NgModule } from "@angular/core";
import { NoteControlComponent } from "./note-control.component";
import { MatLegacyFormFieldModule } from "@angular/material/legacy-form-field";
import { TextFieldModule } from "@angular/cdk/text-field";
import { ReactiveFormsModule } from "@angular/forms";
import { MatLegacyInputModule } from "@angular/material/legacy-input";

@NgModule({
  declarations: [
    NoteControlComponent
  ],
  imports: [
    MatLegacyFormFieldModule,
    MatLegacyInputModule,
    TextFieldModule,
    ReactiveFormsModule
  ],
  exports: [NoteControlComponent]
})
export class NoteControlModule {
}
