import { ApolloFilters } from "../../../../core/interfaces/apollo/apollo.filters";

export class WineStockDatatableConfiguration {

  get whereAndFilters(): Array<ApolloFilters> | null {
    return this._whereAndFilters;
  }

  set whereAndFilters(value: Array<ApolloFilters> | null) {
    this._whereAndFilters = value;
  }

  private _whereAndFilters: Array<ApolloFilters> | null = [];

  private constructor() {
  }

  public static create = (): WineStockDatatableConfiguration => new WineStockDatatableConfiguration();

  setWhereAndFilters(value: Array<ApolloFilters> | null) {

    this.whereAndFilters = value;
    return this;

  }

}
