export class MwCardRadioOptionConfiguration<T> {

  value: T | null = null;
  image: string | null = null;
  label: string | null = null;
  description: string | null = null;

  public static create = <T>(): MwCardRadioOptionConfiguration<T> => new MwCardRadioOptionConfiguration<T>();

  setValue(value: T | null) {

    this.value = value;
    return this;

  }

  setImage(value: string | null) {

    this.image = value;
    return this;

  }

  setLabel(value: string | null) {

    this.label = value;
    return this;

  }

  setDescription(value: string | null) {

    this.description = value;
    return this;

  }

}
