import { Component, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { APP_CONTACTS } from 'src/app/app.module';

@Component({
  selector: 'app-most-wine-contacts',
  templateUrl: './most-wine-contacts.component.html',
  styleUrls: ['./most-wine-contacts.component.scss']
})
export class MostWineContactsComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<MostWineContactsComponent>) { }

  viewModel = {

    ProjectConfigurations: APP_CONTACTS

  }

  ngOnInit() {
  }

  close() {

    this.dialogRef.close();

  }

  call = () => window.open(`tel:${this.viewModel.ProjectConfigurations.tel}`, '_blank');
  mail = () => window.open(`mailto:${this.viewModel.ProjectConfigurations.email}`, '_blank');
  googleMaps = () => window.open('https://maps.google.com/?q=' + this.viewModel.ProjectConfigurations.address, '_blank');

}
