import { Injectable } from "@angular/core";
import { MatLegacyDialog } from "@angular/material/legacy-dialog";
import { UnpackPalletDialogComponent } from "./unpack-pallet-dialog.component";
import { GiacenzaPalletModel } from "../../../../core/classes/deposito/giacenza";

@Injectable()
export class UnpackPalletDialogService {

  constructor(private dialog: MatLegacyDialog) {
  }

  open(event: GiacenzaPalletModel) {

    return this.dialog
      .open(UnpackPalletDialogComponent, { data: event });

  }

}
