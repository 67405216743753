import { Injectable } from "@angular/core";
import { StatoDepositoEnum } from "../../../core/enum/stato-deposito.enum";
import { StatoSpedizioneEnum } from "../../../core/enum/stato-spedizione.enum";
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: "root"
})
export class StateService {

  constructor(private translateService: TranslateService) {
  }

  getStateLabel(state: StatoDepositoEnum | StatoSpedizioneEnum | string): string {
    switch (state) {
      case StatoDepositoEnum.Accettazione:
        return this.translateService.instant('CT_ENUM.'+state);
      case StatoDepositoEnum.Transito:
        return this.translateService.instant('CT_ENUM.'+state);
      case StatoDepositoEnum.PresaInCarico:
        return this.translateService.instant('CT_ENUM.'+state);
      case StatoDepositoEnum.Depositato:
        return this.translateService.instant('CT_ENUM.'+state);
      case StatoDepositoEnum.Annullato:
        return this.translateService.instant('CT_ENUM.'+state);
      case StatoSpedizioneEnum.Consegnata:
        return this.translateService.instant('CT_ENUM.'+state);
      case StatoSpedizioneEnum.Annullata:
        return this.translateService.instant('CT_ENUM.'+state);
      default:
        return "";
    }
  }

}
