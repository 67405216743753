import { Component } from "@angular/core";
import { MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import {
  WineStockDatatableConfiguration
} from "../../../stock/wine-stock/wine-stock-datatable/wine-stock-datatable.configuration";

@Component({
  selector: "app-shipping-box-selection",
  templateUrl: "./shipping-box-selection.component.html"
})
export class ShippingBoxSelectionComponent {

  datatableConfiguration: WineStockDatatableConfiguration = WineStockDatatableConfiguration
    .create()
    .setWhereAndFilters([{ "quantitaScatole": { "gt": 0 } }]);

  constructor(private dialogRef: MatDialogRef<ShippingBoxSelectionComponent>) {
  }

  close() {

    this.dialogRef.close();

  }

}
