import { ChangeDetectorRef, Component, ViewChild } from "@angular/core";
import { QueriesService } from "../../../../../core/lib/queries.service";
import { GenericReadModel } from "../../../../../core/classes/generic-read.model";
import { TipiVinoApolloResult } from "../../../../../core/interfaces/apollo/tipi-vino.apollo.result";
import { ProdottoModel } from "../../../../../core/classes/prodotto";
import { MwLookupControlComponent } from "../../../../../components/mw-lookup-control/mw-lookup-control.component";
import {
  MwLookupControlConfiguration
} from "../../../../../components/mw-lookup-control/mw-lookup-control.configuration";
import { CtControlTypes } from "@ctsolution/ct-framework";

@Component({
  selector: "mw-tipo-vino-control",
  template: `
    <mw-lookup-control
      #lookupControl
      [configuration]="configuration"></mw-lookup-control>`
})
export class TipoVinoControlComponent {

  @ViewChild("lookupControl") lookupControl: MwLookupControlComponent<GenericReadModel, TipiVinoApolloResult> | null = null;

  configuration: MwLookupControlConfiguration<GenericReadModel, TipiVinoApolloResult> = MwLookupControlConfiguration
    .create<GenericReadModel, TipiVinoApolloResult>()
    .setQuery(this._queries.entities.tipiVino)
    .setResponseMapper(value => value.tipiVino)
    .setOptionDescriptionFn(value => value?.nome ?? "Unknown")
    .setOrder([{ nome: "ASC" }])
    .configureControl(control => {

      control
        ?.setLabel("CT_DATATABLE.WINERECORDS.WineZone")
        ?.setType(CtControlTypes.LOOKUP);

    });

  constructor(private _queries: QueriesService, private cdr: ChangeDetectorRef) {
  }

  setValue(value: any) {

    this.configuration
      .CTControl
      .setValue(value);

    return this;

  }

  async setup(value: ProdottoModel | any | null = null) {

    const filterValues = {
      regioneId: value?.regione?.id ?? value?.regione?.value?.id ?? null,
      denominazioneId: value?.denominazione?.id ?? value?.denominazione?.value?.id ?? null
    };

    const filters = [
      { regioni: { some: { id: { eq: filterValues.regioneId } } } },
      { denominazione: { id: { eq: filterValues.denominazioneId } } }];

    const queryDisabled = !filterValues.denominazioneId || !filterValues.regioneId;

    this.lookupControl
      ?.setupFetcher({ filters, queryDisabled });

    this.cdr.detectChanges();

  }

}
