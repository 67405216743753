import { ChangeDetectorRef, Component, Inject, ViewChild } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup } from "@angular/forms";
import {
  CtButtonConfiguration,
  CtCardIconConfiguration,
  CtCardIconPosition, CtControlValidator, CtSelectControlValue,
  SnackbarService
} from "@ctsolution/ct-framework";
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef
} from "@angular/material/legacy-dialog";
import { ConfezioneModel } from "../../../../core/interfaces/confezione.model";
import { PackageSelectControlComponent } from "./package-select-control/package-select-control.component";
import { WineBoxInfographicComponent } from "./wine-box-infographic/wine-box-infographic.component";
import {
  SectionDetailDataConfiguration
} from "../../../../components/model-detail/section-detail-data/section-detail-data.configuration";
import { ModelDetailService } from "../../../../components/model-detail/model-detail.service";
import { ProdottiModel } from "../../../../core/classes/prodotti";
import { ProdottoModel } from "../../../../core/classes/prodotto";
import {
  ShipmentCollectionConfiguration
} from "../../shipment-create-wizard/shipment-create-step1/shipment-collection/shipment-collection.configuration";
import { ShippingCustomPackage } from "../../shipment-create-wizard/shipment-create-wizard.interfaces";
import {
  ProductStockLookupControlComponent
} from "../../../../components/product-stock-lookup-control/product-stock-lookup-control.component";
import { QtaControlConfiguration } from "../../../../components/qta-control/qta-control.configuration";
import { QtaControlComponent } from "../../../../components/qta-control/qta-control.component";
import { ShipmentCreateWizardService } from "../../shipment-create-wizard/shipment-create-wizard.service";
import { TranslateService } from "@ngx-translate/core";
import { Title } from "@angular/platform-browser";

@Component({
  selector: "app-shipping-package-creation",
  templateUrl: "./shipping-package-creation.component.html",
  styleUrls: [`./shipping-package-creation.component.scss`]
})
export class ShippingPackageCreationComponent {

  @ViewChild("packageSelectControlComponent") packageSelectControlComponent: PackageSelectControlComponent | null = null;
  @ViewChild("productStockLookupControl") productStockLookupControl: ProductStockLookupControlComponent | null = null;
  @ViewChild("infographic") wineBoxInfoGraphic: WineBoxInfographicComponent | null = null;
  @ViewChild("qtaControlComponent") qtaControlComponent: QtaControlComponent | null = null;

  form: FormGroup;
  selectedItemsSections: SectionDetailDataConfiguration[] = [];

  submitButton: CtButtonConfiguration = CtButtonConfiguration
    .create()
    .setLabel("CT_DATATABLE.SHIPMENT.CreateBox")
    .setClass("general-button accept-confirm")
    .setAction(() => this.submit());

  cancelButton: CtButtonConfiguration = CtButtonConfiguration
    .create()
    .setLabel("CONFIRM_DIALOG.cancel")
    .setClass("general-button cancel-confirm")
    .setAction(() => this.close());

  qtaControl: FormControl = new FormControl(1);
  qtaControlConfiguration: QtaControlConfiguration = QtaControlConfiguration
    .create()
    .setLabel("Stock")
    .setControl(this.qtaControl)
    .setDisabled(true);

  constructor(
    private formBuilder: FormBuilder,
    private modelDetailService: ModelDetailService,
    private shipmentCreateWizardService: ShipmentCreateWizardService,
    private cdr: ChangeDetectorRef,
    private snackbar: SnackbarService,
    @Inject(MAT_DIALOG_DATA) public data: {
      configuration: ShipmentCollectionConfiguration | null,
      collection: FormArray | null
    },
    private dialogRef: MatDialogRef<any>,
    private translate: TranslateService,
    private title: Title
  ) {

    this.form = this.formBuilder.group({});

    if (this.data.configuration) {

      this.selectedItemsSections = this.data.configuration.sections;

    }

  }

  get validProductLookupControl() {

    return this.productStockLookupControl?.configuration?.CTControl?.control?.valid;

  }

  get currentItemsCount() {

    return this.selectedItemsSections
      .reduce((acc, section) => {

        const nBottiglieData = this.modelDetailService.findDetailDataByTitle(section, "bottle");

        if (nBottiglieData) acc += +(nBottiglieData.cardConfiguration?.description ?? 0);

        return acc;

      }, 0);

  }

  ngOnInit(){
    this.translate.get('CT_DATATABLE.SHIPMENT.CreateShipment').subscribe((translation: string) => {
      this.title.setTitle(translation);
    })
  }

  ngAfterViewInit() {

    this.setup();

  }

  setup() {

    this.packageSelectControlComponent
      ?.configuration
      .CTControl
      .setFormParent(this.form);

    this.qtaControlComponent
      ?.control
      ?.setFormParent(this.form);

    this.setupProductStockLookup();

    const confezioneControl = this.form.get("confezione");

    confezioneControl
      ?.valueChanges
      .subscribe(response => this.updateSelectedConfezione(response?.value));

  }

  private setupProductStockLookup() {

    const CTControl = this.productStockLookupControl
      ?.configuration
      ?.CTControl;

    if (!CTControl) return;

    CTControl
      .setName("giacenzaVino")
      .setFormParent(this.form)
      .setValidators([CtControlValidator.create({ name: "required" } as CtControlValidator)]);

    if (this.data.configuration?.attributes.giacenzaVino) {

      CTControl
        .setValue(
          CtSelectControlValue
            .create()
            .setValue(this.data.configuration?.attributes.giacenzaVino)
            .setLabel(this.data.configuration?.attributes.giacenzaVino?.vino?.descrizione));

      setTimeout(() => this.addWine(), 500);



      this.cdr.detectChanges();
      this.qtaControl
          ?.setValue(this.data.configuration?.attributes.giacenzaVino.quantita ?? 0);
    }

    CTControl
      .control
      ?.valueChanges
      .subscribe(response => {

        const giacenzaVino = response?.value;
        const productCountInsideCollection = this.shipmentCreateWizardService.productCountInsideCollection(giacenzaVino, this.data.collection?.value) ?? 0;
        const giacenzaQuantita = giacenzaVino?.quantita - productCountInsideCollection;

        this.qtaControl
          ?.setValue(giacenzaQuantita ?? 0);

      });

  }

  async updateSelectedConfezione(value: ConfezioneModel | null) {

    if (!value) return;

    const maxBottiglie = value.maxBottiglie ?? 0;

    // Modifica l'array solo se il numero di elementi è diverso
    if (this.currentItemsCount === maxBottiglie) return;

    // Rimuovi elementi in eccesso se necessario
    while (this.currentItemsCount > maxBottiglie) {

      const ultimoProdottoSection = this.selectedItemsSections[this.selectedItemsSections.length - 1]; // POP
      const dettaglioVinoData = this.modelDetailService.findDetailDataByTitle(ultimoProdottoSection, "wine");

      if (dettaglioVinoData) {

        await this.removeWine({ descrizione: dettaglioVinoData?.cardConfiguration?.description ?? "" } as ProdottoModel);

      }

    }

  }

  close() {

    this.dialogRef.close();

  }

  addWine() {

    const formValue = this.form.value;
    const confezione = formValue.confezione?.value;

    if (this.currentItemsCount === (confezione?.maxBottiglie ?? 0)) {

      this.snackbar.open(this.translate.instant("CT_DATATABLE.BoxFullSnackbar"));  // scatola piena!
      return;

    }

    this.form.markAllAsTouched();

    if (this.form.valid) {

      const giacenzaVino = formValue.giacenzaVino.value;
      const description = giacenzaVino?.vino?.descrizione?.toLowerCase();

      const productCountInsideCollection = this.shipmentCreateWizardService.productCountInsideCollection(giacenzaVino, this.data.collection?.value) ?? 0;
      const giacenzaQuantita = giacenzaVino?.quantita - productCountInsideCollection;

      if (giacenzaQuantita <= 0) {

        this.snackbar.open(this.translate.instant("CT_DATATABLE.NoShipment"));
        return;

      }

      const section = this.modelDetailService.findSectionByDescription(this.selectedItemsSections, description);

      if (section) {

        const detailData = this.modelDetailService.findDetailDataByTitle(section, "bottle");

        if (detailData) {

          const currentCount = +(detailData.cardConfiguration?.description ?? "0");

          if (currentCount < giacenzaQuantita) {

            detailData.cardConfiguration?.setDescription((currentCount + 1).toString());

            this.wineBoxInfoGraphic?.add();

          }

          return;

        }

      }

      const prodottoSection: SectionDetailDataConfiguration = this.modelDetailService.getProdottoSection(<ProdottiModel>{
        prodotto: giacenzaVino?.vino,
        quantitaBottiglie: 1,
        quantitaScatole: 0
      });

      const dettaglioVinoData = this.modelDetailService.findDetailDataByTitle(prodottoSection, "wine");

      if (dettaglioVinoData) {

        dettaglioVinoData
          .cardConfiguration
          ?.setIconSetting(
            CtCardIconConfiguration
              .create()
              .setIcon("delete")
              .setPosition(CtCardIconPosition.SUFFIX)
              .setAction(() => this.removeWine(giacenzaVino?.vino))
          );

      }

      this.selectedItemsSections
        .unshift(prodottoSection);

      this.wineBoxInfoGraphic
        ?.add();

    }

  }

  removeWine(value: ProdottoModel) {

    if (!this.currentItemsCount) return; // scatola vuota!

    const description = (value.descrizione ?? "").toLowerCase();

    const section = this.modelDetailService.findSectionByDescription(this.selectedItemsSections, description);

    if (section) {

      const detailData = this.modelDetailService.findDetailDataByTitle(section, "bottle");

      if (detailData) {

        const currentCount = +(detailData.cardConfiguration?.description ?? "0");

        if (currentCount > 1) {

          detailData
            .cardConfiguration
            ?.setDescription((currentCount - 1).toString());

        } else {

          this.selectedItemsSections.splice(this.selectedItemsSections.indexOf(section), 1);

        }

        return this.wineBoxInfoGraphic
          ?.remove();

      }

    }

  }

  submit() {

    this.form.markAllAsTouched();

    if (this.form.valid) {

      const value: ShippingCustomPackage = this.form.value;
      const confezione = (<any>value.confezione)?.value;

      const section: SectionDetailDataConfiguration = SectionDetailDataConfiguration
        .create(confezione?.nome)
        .addAttribute("confezioneId", confezione?.id);

      const sections: SectionDetailDataConfiguration[] = [section];

      this.selectedItemsSections
        .forEach(item => item
          .dataList
          .find(data => data.cardConfiguration?.iconSetting?.icon === "delete")
          ?.cardConfiguration
          ?.setIconSetting(null)
        );

      sections.push(...this.selectedItemsSections);

      this.dialogRef
        .close(sections);

    }

  }

}
