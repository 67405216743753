import { Component, Input, ViewChild } from "@angular/core";
import { AbstractControl, FormControl } from "@angular/forms";
import { IndirizziSpedizioneApolloResult } from "../../../core/interfaces/apollo/indirizzi-spedizione.apollo-result";
import { QueriesService } from "../../../core/lib/queries.service";
import { DestinazioneModel } from "../../../core/classes/destinazione";
import { IndirizzoSpedizioneModel } from "../../../core/classes/spedizione/indirizzo";
import { MwLookupControlComponent } from "../../mw-lookup-control/mw-lookup-control.component";
import { MwLookupControlConfiguration } from "../../mw-lookup-control/mw-lookup-control.configuration";
import { CtSelectControlOptions } from "@ctsolution/ct-framework";

@Component({
  selector: "app-customer-address-select-control",
  template: `
    <mw-lookup-control
      #lookupControl
      [configuration]="configuration"></mw-lookup-control>`
})
export class CustomerAddressSelectControlComponent {

  @ViewChild("lookupControl") lookupControl: MwLookupControlComponent<IndirizzoSpedizioneModel, IndirizziSpedizioneApolloResult> | null = null;

  @Input() control: FormControl | AbstractControl | any | null = null;

  protected configuration: MwLookupControlConfiguration<IndirizzoSpedizioneModel, IndirizziSpedizioneApolloResult> = MwLookupControlConfiguration
    .create<IndirizzoSpedizioneModel, IndirizziSpedizioneApolloResult>()
    .setQuery(this._queries.entities.destinazione)
    .setResponseMapper(value => value.indirizziSpedizione.nodes)
    .setOptionDescriptionFn(value => `${value?.nome} - ${value?.indirizzo?.via}, ${value?.indirizzo?.comune}, ${value?.indirizzo?.cap}, ${value?.indirizzo?.provincia}`)
    .setAutoInitializeValueOptions(false)
    .configureControl(control => {

      control
        ?.setLabel("CT_GENERAL.select_address")
        ?.configureOptions<CtSelectControlOptions>(options => {

          if (!options) options = CtSelectControlOptions.create();

          options
            .setNullValueEnabled(true);

        });

    });


  constructor(private _queries: QueriesService) {
  }

  ngAfterViewInit() {

    if (this.control) {

      this.configuration
        .CTControl
        .setControl(this.control);

    }

    this.setup()
      .then();

  }

  async setup(value: DestinazioneModel | null = null) {

    if (!value?.id) return;

    this.lookupControl
      ?.setupFetcher({ filters: [{ destinazione: { id: { eq: value.id } } }] });

  }

}
