import { Component } from "@angular/core";
import { DepositorService } from "../../../core/lib/depositor.service";
import { QueriesService } from "../../../core/lib/queries.service";
import { BaseController } from "../../../core/controllers/base.controller";
import { ModalitaStoccaggio } from "../../../core/enum/modalita-stoccaggio.enum";
import { ApolloVariableClass } from "../../../core/classes/apollo/apollo.variable";
import { BaseApolloQueryClass } from "../../../core/classes/apollo/apollo.base.query";
import { GiacenzaPalletsApolloResult } from "../../../core/interfaces/apollo/giacenza-pallets.apollo.result";
import { GiacenzaViniApolloResult } from "../../../core/interfaces/apollo/giacenza-vini.apollo-result";

@Component({
  selector: "app-stocks-counter-widget",
  templateUrl: "./stocks-counter-widget.component.html",
  styles: [
    `.stocks-counter-container {
      b {
        font-size: 1.8rem;
      }
    }`
  ]
})
export class StocksCounterWidgetComponent {

  viewModel = {

    [ModalitaStoccaggio.Pallet]: 0,
    [ModalitaStoccaggio.Bottiglia]: 0

  };

  constructor(
    private depositorService: DepositorService,
    private baseController: BaseController,
    private _queries: QueriesService) {
  }

  ngAfterViewInit() {

    this.setup()
      .then();

  }

  async setup() {

    this.getPalletTotalCount()
      .then();

    this.getViniTotalCount()
      .then();

  }

  async getViniTotalCount() {

    const query = await this._queries.entities.giacenzaVini();

    if (query) {

      const parameter = await this.getParameter();

      parameter
        .setQuery(query);

      const caller = await this.baseController.list<GiacenzaViniApolloResult>(parameter);

      if (caller) {

        caller
          .subscribe(response => this.viewModel[ModalitaStoccaggio.Bottiglia] = response.giacenzaVini.totalCount ?? 0);

      }

    }

  }


  async getPalletTotalCount() {

    const query = await this._queries.entities.giacenzaPallets();

    if (query) {

      const parameter = await this.getParameter();

      parameter
        .setQuery(query);

      const caller = await this.baseController.list<GiacenzaPalletsApolloResult>(parameter);

      if (caller) {

        caller
          .subscribe(response => this.viewModel[ModalitaStoccaggio.Pallet] = response.giacenzaPallets.totalCount ?? 0);

      }

    }

  }

  private async getParameter() {

    const depositanteId = await this.depositorService.getDepositor();

    const variables =
      ApolloVariableClass
        .create()
        .setDepositanteId(depositanteId);

    const apolloParameter = BaseApolloQueryClass
      .create()
      .setVariables(variables);

    return apolloParameter;

  }


  protected readonly ModalitaStoccaggio = ModalitaStoccaggio;

}
