import { Component } from "@angular/core";
import { FormControl } from "@angular/forms";

@Component({
  selector: "app-note-control",
  template: `
    <mat-form-field>

      <mat-label>Note</mat-label>
      <textarea
        matInput
        cdkTextareaAutosize
        #autosize="cdkTextareaAutosize"
        cdkAutosizeMinRows="5"
        cdkAutosizeMaxRows="5"
        [formControl]="control"></textarea>

    </mat-form-field>`
})
export class NoteControlComponent {

  control: FormControl = new FormControl<string | null>(null);

}
