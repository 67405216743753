import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { PackageComposerComponent } from "./package-composer.component";
import { ReactiveFormsModule } from "@angular/forms";
import { BoxComposerModule } from "../box-composer/box-composer.module";
import { FlexLayoutModule } from "@angular/flex-layout";
import { TranslateModule } from "@ngx-translate/core";
import { MatLegacyButtonModule } from "@angular/material/legacy-button";
import { MatIconModule } from "@angular/material/icon";
import {
  QtaControlModule
} from "../../../../../components/qta-control/qta-control.module";
import { PackageTypeSelectionComponent } from "./package-type-selection/package-type-selection.component";
import { MwCardRadioGroupModule } from "../../../../../components/mw-card-radio-group/mw-card-radio-group.module";
import { MatCardModule } from "@angular/material/card";

@NgModule({
  declarations: [
    PackageComposerComponent,
    PackageTypeSelectionComponent
  ],
  exports: [
    PackageComposerComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    BoxComposerModule,
    FlexLayoutModule,
    TranslateModule,
    QtaControlModule,
    MatLegacyButtonModule,
    MatIconModule,
    MwCardRadioGroupModule,
    MatCardModule
  ]
})
export class PackageComposerModule {
}
