import { Component, Inject } from "@angular/core";
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from "@angular/material/legacy-dialog";
import { GiacenzaPalletModel } from "../../../../core/classes/deposito/giacenza";
import { CtButtonConfiguration, CtControlValidator } from "@ctsolution/ct-framework";
import { QtaControlConfiguration } from "../../../../components/qta-control/qta-control.configuration";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { BaseRequestClass } from "../../../../core/classes/apollo/apollo.base.request";
import { BaseController } from "../../../../core/controllers/base.controller";
import { SnackBarService } from "../../../../core/lib/snakbar.service";

@Component({
  selector: "app-unpack-pallet-dialog",
  template: `
    <div mat-dialog-title fxLayout="row" fxLayoutAlign="space-between center">

      {{ 'Apertura Pallet' | translate }}

      <button mat-icon-button color="primary" (click)="close()">

        <mat-icon>close</mat-icon>

      </button>

    </div>

    <div mat-dialog-content>

      <p> Alla conferma dell'apertura del Pallet, le scatole al suo interno saranno disponibili per spedizione e
        movimentazioni. </p>
      <p> Inserisci il numero di Pallet che desideri aprire.</p>

      <form [formGroup]="form" style="min-height: 90px">

        <app-qta-control
          [configuration]="qtaControlConfiguration"></app-qta-control>
      </form>

    </div>

    <div mat-dialog-actions fxLayout="row" fxLayoutAlign="space-between center">

      <ct-button [configuration]="cancelButton"></ct-button>

      <ct-button [configuration]="submitButton"></ct-button>

    </div>`
})
export class UnpackPalletDialogComponent {

  submitButton: CtButtonConfiguration = CtButtonConfiguration
    .create()
    .setLabel("CONFIRM_DIALOG.accept")
    .setClass("general-button accept-confirm")
    .setAction(() => this.unpack());

  cancelButton: CtButtonConfiguration = CtButtonConfiguration
    .create()
    .setLabel("CONFIRM_DIALOG.cancel")
    .setClass("general-button cancel-confirm")
    .setAction(() => this.dialogRef.close());

  qtaControlConfiguration: QtaControlConfiguration;

  form: FormGroup;
  qtaControl: FormControl = new FormControl(1);

  constructor(
    private dialogRef: MatLegacyDialogRef<UnpackPalletDialogComponent>,
    private formBuilder: FormBuilder,
    private baseController: BaseController,
    private snackBarService: SnackBarService,
    @Inject(MAT_LEGACY_DIALOG_DATA) public giacenzaPallet: GiacenzaPalletModel) {

    this.form = this.formBuilder.group({
      qta: this.qtaControl
    });

    this.qtaControlConfiguration = QtaControlConfiguration
      .create()
      .setLabel("Quantità pallet")
      .setControl(this.qtaControl)
      .setValidators([
        CtControlValidator.create({ name: "required" } as CtControlValidator),
        CtControlValidator.create({ name: "max", value: giacenzaPallet.quantita ?? 0 } as CtControlValidator)
      ]);

  }

  close(result?: boolean) {

    this.dialogRef.close(result);

  }

  async unpack() {

    if (this.form.valid) {

      const { qta } = this.form.value;

      const parameter: BaseRequestClass = BaseRequestClass
        .create()
        .setController(["giacenze"])
        .setId(`${this.giacenzaPallet.id}/unpack`)
        .setDataSource({ quantita: qta });

      const request = await this.baseController
        .executeDepositorEntityRequest(parameter);

      request
        ?.subscribe(() => {

          this.snackBarService
            .generalMessage(`La richiesta di aprire ${qta} pallet è stata presa in carico!`);

          this.close(true);

        });

    }

  }

}
