import { Component } from "@angular/core";
import { JwtService } from "../../../../core/lib/jwt.service";
import { BaseController } from "../../../../core/controllers/base.controller";
import { BaseRequestClass } from "../../../../core/classes/apollo/apollo.base.request";
import { KeycloakService } from "keycloak-angular";
import { DialogRef } from "@angular/cdk/dialog";
import { MethodEnum } from "@ctsolution/ct-webapi";

@Component({
  selector: "app-condizioni-contrattuali",
  templateUrl: "./condizioni-contrattuali.component.html",
  styleUrls: ["./condizioni-contrattuali.component.scss"]
})
export class CondizioniContrattualiComponent {

  viewModel = {

    condizioniAccettate: false

  };

  constructor(private jwt: JwtService, private baseController: BaseController, private kc: KeycloakService, private dialogRef: DialogRef) {
  }

  ngOnInit() {
  }

  ngAfterViewInit() {

    this.setup();

  }

  async setup() {

    const commercialConditionsAccepted = await this.jwt.getCommercialConditionsAccepted();

    this.viewModel.condizioniAccettate = !!commercialConditionsAccepted;

  }

  close() {

    this.dialogRef
      .close();

  }

  accept() {

    const parameter: BaseRequestClass = BaseRequestClass
      .create()
      .setController(["users"])
      .setAction("accept-commercial-conditions");

    this.baseController
      .executeStandardRequest(parameter, MethodEnum.POST)
      ?.subscribe(() => this.kc.updateToken().then(() => this.close()));

  }

}
