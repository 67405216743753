import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DepositStateSelectFilterComponent } from "./deposit-state-select-filter.component";
import { MultiSelectModule } from "primeng/multiselect";
import { SharedModule } from "primeng/api";
import { DropdownModule } from "primeng/dropdown";
import { FormsModule } from "@angular/forms";
import { BulletStateModule } from "../../../components/bullet-state/bullet-state.module";

@NgModule({
  declarations: [
    DepositStateSelectFilterComponent
  ],
  imports: [
    CommonModule,
    MultiSelectModule,
    SharedModule,
    DropdownModule,
    FormsModule,
    BulletStateModule
  ],
  exports: [
    DepositStateSelectFilterComponent
  ]
})
export class DepositStateSelectFilterModule {
}
