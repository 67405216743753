import { ProdottoModel } from "../../../core/classes/prodotto";

export class WineCreationFormConfiguration {

  values: ProdottoModel | null = null;

  private constructor() {
  }

  public static create = (): WineCreationFormConfiguration => new WineCreationFormConfiguration();

  setValues(value: ProdottoModel | null) {

    this.values = value;
    return this;

  }

}
