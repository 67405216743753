import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { CtControlModule, CtFormModule } from "@ctsolution/ct-framework";
import { MatLegacyFormFieldModule } from "@angular/material/legacy-form-field";
import { MatLegacyInputModule } from "@angular/material/legacy-input";
import { TextFieldModule } from "@angular/cdk/text-field";
import { TranslateModule } from "@ngx-translate/core";
import { DepositCollectionFormComponent } from "./deposit-collection-form.component";
import { ReactiveFormsModule } from "@angular/forms";
import {
  ShipmentAddressFormModule
} from "../../../../../components/shipment-address-form/shipment-address-form.module";
import { NoteControlModule } from "../../../../../components/note-control/note-control.module";

@NgModule({
  declarations: [
    DepositCollectionFormComponent
  ],
  imports: [
    CommonModule,
    CtFormModule,
    MatLegacyFormFieldModule,
    MatLegacyInputModule,
    TextFieldModule,
    TranslateModule,
    CtControlModule,
    ReactiveFormsModule,
    ShipmentAddressFormModule,
    NoteControlModule
  ],
  exports: [DepositCollectionFormComponent]
})
export class DepositCollectionFormModule {
}
