import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from "@angular/material/legacy-dialog";
import { BaseController } from "../../../core/controllers/base.controller";
import { DepositiControllerRequest } from "../../../core/controllers/controllers";
import { SnackBarService } from "../../../core/lib/snakbar.service";
import { DepositoModel } from "../../../core/classes/deposito/deposito";
import { BaseRequestClass } from "../../../core/classes/apollo/apollo.base.request";
import { SetStatoDeposito } from "../../../core/classes/deposito/set-stato-deposito";
import { DepositStateChangeFormComponent } from "./deposit-state-change-form/deposit-state-change-form.component";
import { DepositService } from "../deposit.service";
import { ModelDetailConfiguration } from "../../../components/model-detail/model-detail.configuration";

@Component({
  selector: "app-deposit-state-change-dialog",
  templateUrl: "./deposit-state-change-dialog.component.html"
})
export class DepositStateChangeDialogComponent implements OnInit {

  @ViewChild("form") formContainer: DepositStateChangeFormComponent | null = null;
  depositModelDetail: ModelDetailConfiguration | null = null;

  constructor(
    @Inject(MAT_LEGACY_DIALOG_DATA) public data: DepositoModel | null,
    private baseController: BaseController,
    private snackBarService: SnackBarService,
    private depositService: DepositService,
    private dialogRef: MatLegacyDialogRef<DepositStateChangeDialogComponent>) {

  }

  async ngOnInit() {

    if (!this.data) return;

    this.depositModelDetail = await this.depositService.getDepositModelDetail(this.data.id);

    this.depositModelDetail
      ?.setTitle("Cambio stato deposito");

  }

  ngAfterViewInit() {

    if (this.data?.stato) {

      this.formContainer?.form.get("state")?.setValue(this.data?.stato);

    }

  }

  async confirm() {

    const form = this.formContainer?.form;

    if (form?.valid) {

      const parameter: BaseRequestClass = DepositiControllerRequest()
        .setDepositanteId(this.data?.depositante?.id ?? null)
        .setAction(`${this.data?.id}/set-stato`)
        .setId(this.formContainer?.form?.value.state);

      const dataSource = SetStatoDeposito
        .create();

      if (form?.value.note) {

        dataSource
          .setNote(form?.value.note);

      }

      parameter
        .setDataSource(dataSource);

      const caller = await this.baseController.executeDepositorEntityRequest(parameter);

      if (!caller) return;

      caller
        .subscribe(response => {

          this.dialogRef
            .afterClosed()
            .subscribe(() => this.snackBarService.generalMessage("CT_MESSAGES.SUCCESS_OPERATION"));

          this.dialogRef.close(true);

        });

    }

  }

}
