import { DepositType } from "../../../../../core/enum/deposit-type";
import { ModalitaStoccaggio } from "../../../../../core/enum/modalita-stoccaggio.enum";

export class DepositCollectionFormComponentConfiguration {

  depositType: DepositType | null = null;
  packagingType: ModalitaStoccaggio | null = null;

  private constructor() {
  }

  public static create = (): DepositCollectionFormComponentConfiguration => new DepositCollectionFormComponentConfiguration();

  setDepositType(value: DepositType | null): DepositCollectionFormComponentConfiguration {

    this.depositType = value;

    return this;
  }

  setPackagingType(value: ModalitaStoccaggio | null): DepositCollectionFormComponentConfiguration {

    this.packagingType = value;
    return this;
  }

}
