import { TypedDocumentNode } from "@apollo/client";
import { ApolloVariableClass } from "./apollo.variable";

export class BaseApolloQueryClass {

  query: TypedDocumentNode | any | null = null;
  variables: ApolloVariableClass = ApolloVariableClass.create();

  private constructor() {
  }

  public static create = (): BaseApolloQueryClass => new BaseApolloQueryClass();

  getOptions() {

    return {
      query: this.query,
      variables: this.variables
    };

  }

  setVariables(value: ApolloVariableClass): BaseApolloQueryClass {

    this.variables = value;
    return this;

  }

  setQuery(value: TypedDocumentNode | null): BaseApolloQueryClass {

    this.query = value;
    return this;

  }

}
