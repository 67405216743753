import { ChangeDetectorRef, Component } from "@angular/core";
import { CtControlConfiguration } from "@ctsolution/ct-framework";
import { COMUNE_CONTROL } from "./comune.control";

@Component({
  selector: "mw-comune-control",
  template: `
    <ct-control *ngIf="control" [configuration]="control"></ct-control>`
})
export class ComuneControlComponent {

  control: CtControlConfiguration = CtControlConfiguration.create(COMUNE_CONTROL);

  constructor(private cdr: ChangeDetectorRef) {
  }

  ngAfterViewInit() {

    this.cdr.detectChanges();

  }

}
