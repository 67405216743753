import { Component } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { PrimeNGConfig } from "primeng/api";
import { JwtService } from "./core/lib/jwt.service";
import {
  CondizioniContrattualiService
} from "./pages/personal-area/profile/condizioni-contrattuali/condizioni-contrattuali.service";
import { LocalStorageService } from "./core/lib/local-storage.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"]
})
export class AppComponent {

  constructor(config: PrimeNGConfig,private translate: TranslateService, private jwt: JwtService, private condizioniContrattualiService: CondizioniContrattualiService,private localStorageService: LocalStorageService,private router : Router) {

    //translate.setDefaultLang("en");  spostato nell'app.module.ts
    translate.get("primeng").subscribe(res => config.setTranslation(res));

  }

  ngOnInit(): void {

    
  }

  ngAfterViewInit() {

    this.checkIfUserAcceptedCommercialConditions();

  }

  async checkIfUserAcceptedCommercialConditions() {

    const commercialConditionsAccepted = await this.jwt.getCommercialConditionsAccepted();
    const depositanti = await this.jwt.getDepositanti();

    if (!commercialConditionsAccepted && depositanti) {

      this.condizioniContrattualiService.openCondizioniContrattuali();

    }

  }

}
