import { Injectable } from "@angular/core";
import { MatLegacyDialog } from "@angular/material/legacy-dialog";
import { CourierRateTableComponent } from "./courier-rate-table.component";
import { RitiroDeposito } from "../../core/classes/deposito/ritiro-deposito";
import { Rate } from "../../core/interfaces/rate";
import { SpedizioneModel } from "../../core/classes/spedizione/spedizione.model";

@Injectable()
export class CourierRateTableService {

  constructor(private dialog: MatLegacyDialog) {
  }

  open(data: RitiroDeposito | SpedizioneModel): Promise<Rate | null> {

    return new Promise((resolve) => {

      this.dialog.open(CourierRateTableComponent, { data })
        .afterClosed()
        .subscribe((response?: Rate | null) => resolve(response ?? null));

    });

  }

}
