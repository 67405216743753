import { CTDatatableFilterConfiguration, CTDatatableHeaderCellConfiguration } from "@ctsolution/ct-framework";

export const quantitaInizialeHeader: CTDatatableHeaderCellConfiguration = CTDatatableHeaderCellConfiguration
  .create()
  .setField("quantitaIniziale")
  .setLabel("Quantità iniziale")
  .setInlineStyle("min-width: 200px")
  .setSortable(true)
  .setFilter(
    CTDatatableFilterConfiguration
      .create()
      .setType("numeric")
      .setPlaceHolder("Cerca per quantità iniziale")
      .setShowMenu(true));

export const quantitaAttualeHeader: CTDatatableHeaderCellConfiguration = CTDatatableHeaderCellConfiguration
  .create()
  .setField("quantitaAttuale")
  .setLabel("Quantità attuale")
  .setInlineStyle("min-width: 200px")
  .setSortable(true)
  .setFilter(
    CTDatatableFilterConfiguration
      .create()
      .setType("numeric")
      .setPlaceHolder("Cerca per quantità attuale")
      .setShowMenu(true));

export const tipoQuantitaHeader: CTDatatableHeaderCellConfiguration = CTDatatableHeaderCellConfiguration
  .create()
  .setField("tipoQuantita")
  .setLabel("Tipo quantità")
  .setInlineStyle("min-width: 200px")
  .setSortable(true)
  .setFilter(
    CTDatatableFilterConfiguration
      .create()
      .setPlaceHolder("Cerca per tipo quantità") // dovrà essere una tendina??
      .setShowMenu(true));
