import { ContenutoConfezioneSpedizione } from "./contenuto-confezione-spedizione";
import { v4 as uuidv4 } from "uuid";
import { GenericReadModel } from "../generic-read.model";

export class ConfezioneSpedizione {

  confezione: GenericReadModel | null = null;

  private constructor(
    public id: string,
    public confezioneId: string,
    public quantita: number,
    public contenuti: Array<ContenutoConfezioneSpedizione>
  ) {
  }

  public static create = (confezioneId: string, quantita: number, contenuti: Array<ContenutoConfezioneSpedizione> = new Array<ContenutoConfezioneSpedizione>()): ConfezioneSpedizione => new ConfezioneSpedizione(uuidv4(), confezioneId, quantita, contenuti);

}
