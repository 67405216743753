<div class="shipment-create-step1-container m-t-15">

  <mat-card class="radio-package-add-container">

    <mat-card-content>

      <app-shipment-type-selection #shipmentTypeSelectionComponent></app-shipment-type-selection>

    </mat-card-content>

  </mat-card>

  <app-shipment-collection #shipmentCollection (editCustomBox)="editComposerBox($event)"></app-shipment-collection>

</div>

