import { Component, TemplateRef, ViewChild } from '@angular/core';
import { BaseApolloQueryClass } from "../../../../core/classes/apollo/apollo.base.query";
import { BaseController } from 'src/app/core/controllers/base.controller';
import { QueriesService } from 'src/app/core/lib/queries.service';
import { GiacenzaPalletApolloResult } from 'src/app/core/interfaces/apollo/giacenza-pallet.apollo.result';
import { GiacenzaPalletModel } from 'src/app/core/classes/deposito/giacenza';

@Component({
    selector: 'app-pallet-stock-counter',
    templateUrl: './pallet-stock-counter.component.html',
    styleUrls: ['./pallet-stock-counter.component.scss']
})
export class PalletStockCounterComponent{

    totaliGiacenza: GiacenzaPalletApolloResult | null = null;

    constructor(
        private _queries: QueriesService,
        private baseController: BaseController
    ){}

    ngOnInit(){
        this.getTotaliPallet();
    }
    ngAfterViewInit(){
        
    }


    async getTotaliPallet(){
        try
        {
            const totaliPallets_query = await this._queries.entities.giacenzaPallets();

            if (!totaliPallets_query) return;

            const parameter = BaseApolloQueryClass.create().setQuery(totaliPallets_query);
            const caller = await this.baseController.list<GiacenzaPalletApolloResult | null>(parameter);
            const result = await new Promise<GiacenzaPalletApolloResult | null>((resolve, reject) => {
                caller?.subscribe({ next: resolve, error: reject });
            });
            if (result?.giacenzaPallet?.quantita) {
                this.totaliGiacenza = result;
            } else {
                this.totaliGiacenza = null;
            }
        }
        catch(e)
        {
            console.error("Errore durante la richiesta dei dati:", e);
        }
    }
}