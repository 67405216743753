import { AbstractControl, FormControl } from "@angular/forms";
import { CtControlValidator } from "@ctsolution/ct-framework";

export class QtaControlConfiguration {

  get validators(): CtControlValidator[] | null {
    return this._validators;
  }

  private set validators(value: CtControlValidator[] | null) {
    this._validators = value;
  }

  get label(): string | null {
    return this._label;
  }

  private set label(value: string | null) {
    this._label = value;
  }

  get control(): any {
    return this._control;
  }

  private set control(value: any) {
    this._control = value;
  }

  private _label: string | null = null;
  private _control: FormControl | AbstractControl | any | null = null;
  private _validators: CtControlValidator[] | null = null;

  public static create = (): QtaControlConfiguration => new QtaControlConfiguration();

  setLabel(value: string | null) {

    this.label = value;
    return this;

  }

  setControl(value: FormControl | AbstractControl | any | null) {

    this.control = value;
    return this;

  }

  setValidators(value: CtControlValidator[] | null) {

    this.validators = value;
    return this;

  }

  setDisabled(value: boolean) {

    if (value) {

      this.control.disable();

    } else {

      this.control.enable();

    }

    return this;

  }

}
