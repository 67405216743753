import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DepositCreateStep4Component } from "./deposit-create-step4.component";
import { ReactiveFormsModule } from "@angular/forms";
import { MatStepperModule } from "@angular/material/stepper";
import { MatLegacyButtonModule } from "@angular/material/legacy-button";
import { TranslateModule } from "@ngx-translate/core";
import {
  ShippingMethodSelectorModule
} from "../deposit-create-step3/shipping-methods-selector/shipping-method-selector.module";
import { CtFormModule } from "@ctsolution/ct-framework";
import { MatLegacyFormFieldModule } from "@angular/material/legacy-form-field";
import { MatLegacyInputModule } from "@angular/material/legacy-input";
import { TextFieldModule } from "@angular/cdk/text-field";
import { DepositCollectionPackagingModule } from "./deposit-collection-packaging/deposit-collection-packaging.module";
import { NoteControlModule } from "../../../../components/note-control/note-control.module";
import { DepositCollectionFormModule } from "./deposit-collection-form/deposit-collection-form.module";
import { CourierRateTableModule } from "../../../../components/courier-rate-table/courier-rate-table.module";

@NgModule({
  declarations: [
    DepositCreateStep4Component
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatStepperModule,
    MatLegacyButtonModule,
    TranslateModule,
    ShippingMethodSelectorModule,
    CtFormModule,
    MatLegacyFormFieldModule,
    MatLegacyInputModule,
    TextFieldModule,
    DepositCollectionFormModule,
    NoteControlModule,
    DepositCollectionPackagingModule,
    CourierRateTableModule
  ],
  exports: [DepositCreateStep4Component]
})
export class DepositCreateStep4Module {
}
