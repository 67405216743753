<div mat-dialog-title fxLayout="row" fxLayoutAlign="space-between center">

  {{ 'CT_DATATABLE.SHIPMENT.AddPalletToShipment' | translate }}

  <button mat-icon-button color="primary" (click)="close()">

    <mat-icon>close</mat-icon>

  </button>

</div>

<div mat-dialog-content>

  <pallet-stock-datatable></pallet-stock-datatable>

</div>
