import { Component } from "@angular/core";
import { MeteoWidgetService } from "./meteo-widget.service";

const defaultTemp = 18;

@Component({
  selector: "app-meteo-widget",
  templateUrl: "./meteo-widget.component.html",
  styleUrls: ["./meteo-widget.component.scss"]
})
export class MeteoWidgetComponent {


  viewModel: any = {

    internal: defaultTemp,
    external: defaultTemp,
    icon: null

  };

  constructor(private meteoWidgetService: MeteoWidgetService) {
  }

  ngOnInit() {

    this.meteoWidgetService
      .getWeatherData()
      .subscribe(response => {

        this.viewModel.icon = response.weather[0]?.icon ?? null;
        this.viewModel.external = Math.round(response.main.temp ?? defaultTemp);

      });

  }

}
