import { Component, Input } from "@angular/core";
import { FormArray, FormGroup } from "@angular/forms";
import { DepositCollectionPackagingConfiguration } from "../deposit-collection-packaging.configuration";
import { DepositCollectionPackagingService } from "../deposit-collection-packaging.service";

@Component({
  selector: "app-collection-pallet-packaging",
  templateUrl: "./collection-pallet-packaging.component.html"
})
export class CollectionPalletPackagingComponent {

  @Input() collection: FormArray<FormGroup> | null = null;
  @Input() configuration: DepositCollectionPackagingConfiguration | null = null;

  constructor(public depositCollectionPackagingService: DepositCollectionPackagingService) {
  }

  getPackageValues(form: FormGroup): Array<any> {

    return form.value?.package?.box?.wines ?? [];

  }

}
