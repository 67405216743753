import { CTDatatableConfiguration } from "@ctsolution/ct-framework";
import { Observable } from "rxjs";
import { ApolloPageInfo } from "src/app/core/interfaces/apollo/apollo.page-info";
import { ApolloVariableClass } from "../../core/classes/apollo/apollo.variable";
import { ApolloResultDataList } from "../../core/interfaces/apollo/apollo.result.data-list";

export class MwDatatableConfiguration<T> {

  get pageInfo(): ApolloPageInfo | null {
    return this._pageInfo;
  }

  private set pageInfo(value: ApolloPageInfo | null) {
    this._pageInfo = value;
  }

  get CTDatatableConfiguration(): CTDatatableConfiguration<T> | null {
    return this._CTDatatableConfiguration;
  }

  private set CTDatatableConfiguration(value: CTDatatableConfiguration<T> | null) {
    this._CTDatatableConfiguration = value;
  }

  get fetchResponseDataMapper(): ((response: T) => ApolloResultDataList<any>) | null {
    return this._fetchResponseDataMapper;
  }

  private set fetchResponseDataMapper(value: ((response: T) => ApolloResultDataList<any>) | null) {
    this._fetchResponseDataMapper = value;
  }

  get fetchDataCaller(): ((variables: ApolloVariableClass) => Promise<Observable<T> | null>) | null {
    return this._fetchDataCaller;
  }

  private set fetchDataCaller(value: ((variables: ApolloVariableClass) => Promise<Observable<T> | null>) | null) {
    this._fetchDataCaller = value;
  }

  get enableFetch(): boolean {
    return this._enableFetch;
  }

  private set enableFetch(value: boolean) {
    this._enableFetch = value;
  }

  private _fetchDataCaller: ((variables: ApolloVariableClass) => Promise<Observable<T> | null>) | null = null;
  private _fetchResponseDataMapper: ((response: T) => ApolloResultDataList<any>) | null = null;
  private _CTDatatableConfiguration: CTDatatableConfiguration<T> | null = null;
  private _pageInfo: ApolloPageInfo | null = null;
  private _enableFetch : boolean = true;

  private constructor() {
  }

  public static create = <T>(): MwDatatableConfiguration<T> => new MwDatatableConfiguration<T>();

  setFetchDataCaller(value: ((variables: ApolloVariableClass) => Promise<Observable<T> | null>) | null = null): MwDatatableConfiguration<T> {

    this.fetchDataCaller = value;
    return this;

  }

  setFetchResponseDataMapper(value: ((response: T) => ApolloResultDataList<any>) | null): MwDatatableConfiguration<T> {

    this.fetchResponseDataMapper = value;
    return this;

  }

  setCTDatatableConfiguration(value: CTDatatableConfiguration<T> | null): MwDatatableConfiguration<T> {

    this.CTDatatableConfiguration = value;
    return this;

  }

  setPageInfo(value: ApolloPageInfo | null = null): MwDatatableConfiguration<T> {

    this.pageInfo = value;
    return this;

  }

  setEnableFetch (value: boolean): MwDatatableConfiguration<T> {

    this.enableFetch = value;
    return this;

  }

}
