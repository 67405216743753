import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CustomerLookupControlComponent } from "./customer-lookup-control.component";
import { MwLookupControlModule } from "../../mw-lookup-control/mw-lookup-control.module";

@NgModule({
  declarations: [CustomerLookupControlComponent],
  imports: [
    CommonModule,
    MwLookupControlModule
  ],
  exports: [CustomerLookupControlComponent]
})
export class CustomerLookupControlModule {
}
