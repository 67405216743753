import { CTBase } from "@ctsolution/ct-base";
import { MWDateFormatter } from "../../lib/date-format.service";
import { IndirizzoModel } from "../indirizzo";
import { RitiroDeposito } from "./ritiro-deposito";

export class RitiroModel extends CTBase<RitiroModel> {

  corriereId: string | null = null;
  tariffaId: string | null = null;
  totalePesoTrasporto: number | null = null;
  dataPickup: string | null = null;
  mittente: {
    indirizzo: IndirizzoModel;
    telefono: string;
    email: string;
    nome: string;
  } | null = null;

  private constructor();
  private constructor(model?: RitiroModel);
  private constructor(model?: RitiroModel) {

    super();

    if (model) {

      this.getClass(model);

    }

  };

  public static create = (model?: RitiroModel): RitiroModel => new RitiroModel(model);

  setCorriereId(value: string | null): RitiroModel {

    this.corriereId = value;
    return this;

  }

  setTariffaId(value: string | null): RitiroModel {

    this.tariffaId = value;
    return this;

  }

  setDataPickUp(value: string | Date | null) {

    if (value instanceof Date) {

      value = MWDateFormatter
        .create()
        .getDateISOString(value);

    }

    this.dataPickup = value;
    return this;

  }

  setMittenteFromRitiroDepositoModel(value: RitiroDeposito) {

    this.mittente = {
      indirizzo: value.indirizzo!,
      telefono: value.telefonoMittente,
      email: value.emailMittente,
      nome: value.nomeMittente
    };

  }

}
