import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatIconModule } from "@angular/material/icon";
import { TranslateModule } from "@ngx-translate/core";
import { CtButtonModule, CtCardModule, CtFormModule, CtFrameworkModule } from "@ctsolution/ct-framework";
import { FlexLayoutModule } from "@angular/flex-layout";
import { MatLegacyDialogModule as MatDialogModule } from "@angular/material/legacy-dialog";
import { MatLegacyButtonModule as MatButtonModule } from "@angular/material/legacy-button";
import { MatLegacyCardModule as MatCardModule } from "@angular/material/legacy-card";
import { MatStepperModule } from "@angular/material/stepper";
import { ShippingPackageCreationComponent } from "./shipping-package-creation.component";
import {
  BoxComposerModule
} from "../../../deposit/deposit-create-wizard/deposit-create-step2/box-composer/box-composer.module";
import { PackageSelectControlModule } from "./package-select-control/package-select-control.module";
import { ReactiveFormsModule } from "@angular/forms";
import {
  ProductStockLookupControlModule
} from "../../../../components/product-stock-lookup-control/product-stock-lookup-control.module";
import { WineBoxInfographicModule } from "./wine-box-infographic/wine-box-infographic.module";
import {
  SectionDetailDataModule
} from "../../../../components/model-detail/section-detail-data/section-detail-data.module";
import { QtaControlModule } from "../../../../components/qta-control/qta-control.module";

@NgModule({
  declarations: [
    ShippingPackageCreationComponent
  ],
  imports: [
    CommonModule,
    MatDialogModule,
    MatIconModule,
    TranslateModule,
    MatStepperModule,
    CtFormModule,
    CtButtonModule,
    CtFrameworkModule,
    FlexLayoutModule,
    MatButtonModule,
    MatCardModule,
    BoxComposerModule,
    PackageSelectControlModule,
    ReactiveFormsModule,
    ProductStockLookupControlModule,
    WineBoxInfographicModule,
    SectionDetailDataModule,
    QtaControlModule
  ],
  exports: [ShippingPackageCreationComponent]
})
export class ShippingPackageCreationModule {
}
