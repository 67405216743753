<mat-card class="meteo-widget-component-container">

  <mat-card-content>

    <div class="external-weather">

      <img *ngIf="viewModel.icon"
           [src]="'https://openweathermap.org/img/w/' + viewModel.icon + '.png'">
      <strong class="external">{{ viewModel.external }}°</strong>

    </div>

    <h2>Pocapaglia, Cuneo</h2>

    <div class="deposit-container">

      <div class="deposit">

        <strong class="internal">{{ viewModel.internal }}°</strong>

      </div>

    </div>

  </mat-card-content>

</mat-card>
